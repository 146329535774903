import React, { useState } from 'react';
import FBritoLogo from '../../assets/imgs/logo.svg';
import FBritoLogoHover from '../../assets/imgs/logo-hover.svg';
import Menu from '../Menu';

const AdminMenu = (props) => {
  const { pageKey, adminIsLoggedIn } = props;
  const [logoImg, setLogoImg] = useState(FBritoLogo);
  
  return (
    pageKey ? (
      adminIsLoggedIn ? (
        <React.Fragment>
          {/* Menu bar mobile version closed --> */}
          <div className={"fbrito-menu-mob " + pageKey}>
            <div className="fbrito-menu-mob-00">
              <a href="/" className="fbrito-menu-mob-01">
                <span>
                  <img
                    onMouseOver={() => setLogoImg(FBritoLogoHover)} 
                    onMouseOut={() => setLogoImg(FBritoLogo)} 
                    src={logoImg} 
                    alt="Logo" 
                    className="fbrito-logo" 
                    height="30px"/>
                </span>
                <span className="fbrito-name">FRANCISCO BRITO</span>
              </a>
              <div id="menu-open">MENU</div>
            </div>
          </div>
          <div id="myMenu">
            <div className="fbrito-menu-mob">
              <div className="fbrito-menu-mob-00">
                <a href="/" className="fbrito-menu-mob-01">
                  <span>
                    <img
                      onMouseOver={() => setLogoImg(FBritoLogoHover)} 
                      onMouseOut={() => setLogoImg(FBritoLogo)} 
                      src={logoImg} 
                      alt="Logo" 
                      className="fbrito-logo" 
                      height="30px"/>
                  </span>
                  <span className="fbrito-name">FRANCISCO BRITO</span>
                </a>
                <div id="menu-close">voltar</div>
              </div>
            </div>
            <div className="fbrito-menu-mob-options">
              <div className={pageKey === "admin_schedule" ? "first fbrito-menu-active" : "first"}><a href="admin/horario">Horário</a></div>
              <div className={pageKey === "admin_quotes" ? "fbrito-menu-active" : ""}><a href="/admin/citacoes">Citações</a></div>
              <div className={pageKey === "admin_services" ? "fbrito-menu-active" : ""}><a href="/admin/servicos">Serviços</a></div>
              {/* <div className={pageKey === "admin_blogs" ? "fbrito-menu-active" : ""}><a href="/admin/blog">Blog</a></div> */}
              <div className={pageKey === "admin_about" ? "fbrito-menu-active" : ""}><a href="/admin/blog">Sobre</a></div>
              <div className={pageKey === "admin_subscriptions" ? "fbrito-menu-active" : ""}><a href="/admin/subscricoes">Subscrições</a></div>
              <div className={pageKey === "admin_images" ? "fbrito-menu-active" : ""}><a href="/admin/subscricoes">Galeria</a></div>
              <div className={pageKey === "admin_site" ? "fbrito-menu-active" : ""}><a href="/admin"><span>Início</span>&nbsp;&nbsp;<i className="fas fa-home"></i></a></div>
            </div>
          </div>
          {/* End - Menu bar mobile version closed */}

          {/* Menu bar desktop version */}
          <div className={"fbrito-menu " + pageKey}>
            <nav className="navbar navbar-expand-lg">
              <a className="navbar-brand" href="/">
                <span>
                  <img
                    onMouseOver={() => setLogoImg(FBritoLogoHover)} 
                    onMouseOut={() => setLogoImg(FBritoLogo)} 
                    src={logoImg} 
                    alt="Logo" 
                    className="fbrito-logo" />
                </span>
                <span className="fbrito-name">Francisco Brito</span>
              </a>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                <span className="fbrito-open-menu">Menu</span>
              </button>
              <div className="collapse navbar-collapse" id="collapsibleNavbar">
                <ul className="navbar-nav">
                  <li className={pageKey === "admin_schedule" ? "fbrito-menu-active" : ""}><a href="/admin/horario">Horário</a></li>
                  <li className={pageKey === "admin_quotes" ? "fbrito-menu-active" : ""}><a href="/admin/citacoes">Citações</a></li>
                  <li className={pageKey === "admin_services" ? "fbrito-menu-active" : ""}><a href="/admin/servicos">Serviços</a></li>
                  {/* <li className={pageKey === "admin_blogs" ? "fbrito-menu-active" : ""}><a href="/admin/blog">Blog</a></li> */}
                  <li className={pageKey === "admin_about" ? "fbrito-menu-active" : ""}><a href="/admin/sobre">Sobre</a></li>
                  <li className={pageKey === "admin_subscriptions" ? "fbrito-menu-active" : ""}><a href="/admin/subscricoes">Subscrições</a></li>
                  <li className={pageKey === "admin_images" ? "fbrito-menu-active" : ""}><a href="/admin/imagens">Galeria</a></li>
                  <li className={pageKey === "admin_site" ? "fbrito-menu-active" : ""}><a href="/admin"><i className="fas fa-home"></i></a></li>
                </ul>
              </div>
            </nav>
          </div>
          {/* End - Menu bar desktop version */}
        </React.Fragment>) : (<Menu pageKey={pageKey} />)
    ) : null
  );
}

export default AdminMenu;
