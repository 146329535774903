import React from 'react';
import { Route } from 'react-router-dom';
import Footer from '../components/Footer';
import AdminMenu from '../components/admin/AdminMenu';

const AdminRoute = ({ component: Component, pageKey, adminIsLoggedIn, ...rest }) => {
  const components = ['admin_home', 'admin_change_password']
  return (
    <Route
    {...rest}
    render={props => (
      <React.Fragment>
        <AdminMenu pageKey={pageKey} adminIsLoggedIn={adminIsLoggedIn} />
        <Component {...props} adminIsLoggedIn={adminIsLoggedIn} />
        {!components.includes(pageKey) ? <Footer /> : null}
      </React.Fragment>)}
  />
  );
}

export default AdminRoute;
