import React from 'react';
import Space from '../../components/Space';
import { getTranslations } from '../../utils/LanguageUtils';
import ScheduleCard from './../../components/ScheduleCard';

const Schedule = () => {
  return (
    <div className="fbrito-page fbrito-schedule-page fbrito-bg-grey">
      <div className="fbrito-page-title">{getTranslations().screens.public.schedule.title}</div>
      <div className="fbrito-block-padding">
        <div className="fbrito-center">
          <ScheduleCard />
        </div>
        <div className="fbrito-center">
          <a href="contactos" className="fbrito-btn">{getTranslations().screens.public.schedule.button}</a>
        </div>
        <Space number={3} />
      </div>
    </div>
  );
}

export default Schedule;
