/* eslint-disable import/no-anonymous-default-export */
import { post } from './client';

export default {
  /**
   * Get all subscribers from api
   */
  getSubscribersAll() {
    return post('/subscribers/readAll.php');
  },

  /**
   * Create a new subscriber with given data (contains email).
   * @param {*} data - Object containing the email as param
   */
  createSubscriber(data) {
    return post('/subscribers/create.php', JSON.stringify(data));
  },

  /**
   * Delete a subscriber with given data (contains id).
   * @param {*} data - Object containing the id as param
   */
  deleteSubscriber(data) {
    return post('/subscribers/delete.php', JSON.stringify(data));
  },

  /**
   * Get highlighted services from api
   * @param {*} data - Object containing the language as param
   */
  getHighlights(data) {
    return post('/services/read_highlights.php', JSON.stringify(data));
  },

  /**
   * Get quotes list by related code given in data
   * @param {*} data - Object containing the related_code param
   */
  getQuotesByCode(data) {
    return post('/quotes/readByCode.php', JSON.stringify(data));
  },
  
  /**
   * Get specific quote by id given in data
   * @param {*} data - Object containing the id param
   */
  getQuoteById(data) {
    return post('/quotes/readById.php', JSON.stringify(data));
  },

  /**
   * Update quote by given id, text, author and related code
   * @param {*} data - Object containing the id, text, author and related code params
   */
  updateQuote(data) {
    return post('/quotes/update.php', JSON.stringify(data));
  },

  /**
   * Delete quote by given quote id
   * @param {*} data - Object containing the id param
   */
  deleteQuote(data) {
    return post('/quotes/delete.php', JSON.stringify(data));
  },

  /**
   * Create quote with given text, author and related_code of quote
   * @param {*} data - Object containing the text, author and related code params
   */
  createQuote(data) {
    return post('/quotes/create.php', JSON.stringify(data));
  },

  /**
   * Get schedule from api
   * @param {*} data - Object containing the language as param
   */
  getScheduleAll(data) {
    return post('/schedule/read.php', JSON.stringify(data));
  },

  /**
   * Get specific schedule by id given in data
   * @param {*} data - Object containing the id param
   */
  getScheduleById(data) {
    return post('/schedule/readById.php', JSON.stringify(data));
  },
  
  /**
   * Update schedule by given id, title, date_range, where, category_id and order_number
   * @param {*} data - Object containing the id, title, date_range, where, category_id and order_number
   */
    updateSchedule(data) {
    return post('/schedule/update.php', JSON.stringify(data));
  },

  /**
   * Delete schedule by given schedule id
   * @param {*} data - Object containing the id param
   */
  deleteSchedule(data) {
    return post('/schedule/delete.php', JSON.stringify(data));
  },

  /**
   * Create schedule with given title, date_range, where, category_id and order_number
   * @param {*} data - Object containing the title, date_range, where, category_id and order_number params
   */
  createSchedule(data) {
    return post('/schedule/create.php', JSON.stringify(data));
  },
  
  /**
   * Get all services
   * @param {*} data - Object containing the type ("service" or "blog")
   */
  getServicesAll(data) {
    return post('/services/read.php', JSON.stringify(data));
  },

  /**
   * Get the service with given service id
   * @param {*} data - Object containing the service id as param
   */
  getServiceById(data) {
    return post('/services/read_one.php', JSON.stringify(data));
  },

  /**
   * Get the service with given service code
   * @param {*} data - Object containing the code param (service code)
   */
  getServiceByCode(data) {
    return post('/services/readByCode.php', JSON.stringify(data));
  },

  /**
   * Get list of services with given category id
   * @param {*} data - Object containing the category_id param
   */
  getServicesByCategoryId(data) {
    return post('/services/readByCategoryId.php', JSON.stringify(data));
  },

  /**
   * Get specific schedule by id given in data
   * @param {*} data - Object containing the service data param
   */
  updateService(data) {
    return post('/services/updateById.php', JSON.stringify(data));
  },

  /**
   * Delete specific service by given id given in data
   * @param {*} data - Object containing the id param
   */
  deleteService(data) {
    return post('/services/delete.php', JSON.stringify(data));
  },

  /**
   * Create service by given data
   * @param {*} data - Object containing
   */
  createService(data) {
    return post('/services/create.php', JSON.stringify(data));
  },

  /**
   * Get all free texts of service with given service id
   * @param {*} data - Object containing the serviceId and language as params
   */
  getFreetextByServiceId(data) {
    return post('/freetext/readByServiceId.php', JSON.stringify(data));
  },
  
  /**
   * Get free text of with given freetext id
   * @param {*} data - Object containing the freetext id and language as params
   */
  getFreetextById(data) {
    return post('/freetext/readById.php', JSON.stringify(data));
  },
  
  /**
   * Update specific freetext given freetext data
   * @param {*} data - Object containing the freetext data as params
   */
  updateFreetext(data) {
    return post('/freetext/update.php', JSON.stringify(data));
  },

  /**
   * Create a new freetext given freetext data
   * @param {*} data - Object containing the freetext data as params
   */
  createFreetext(data) {
    return post('/freetext/create.php', JSON.stringify(data));
  },

  /**
   * Delete specific freetext with given freetext id
   * @param {*} data - Object containing the freetext id as params
   */
  deleteFreetext(data) {
    return post('/freetext/delete.php', JSON.stringify(data));
  },

  /**
   * Check if admin user is logged in
   */
  adminIsLoggedIn() {
    return post('/user/isLoggedIn.php');
  },

  /**
   * Login an admin user with given username and password
   * @param {*} data - Object containing the username and password
   */
  adminLogin(data) {
    return post('/user/login.php', JSON.stringify(data));
  },

  /**
   * Admin user logout with given username
   * @param {*} data - Object containing the username
   */
  adminLogout(data) {
    return post('/user/logout.php', JSON.stringify(data));
  },

  /**
   * Change user password with given username and new password
   * @param {*} data - Object containing the username and new password
   */
   adminChangePassword(data) {
    return post('/user/changePassword.php', JSON.stringify(data));
  },

  /**
   * Get about page info
   * @param {*} data - Object containing the language (optional)
   */
  getAboutPage(data) {
    return post('/about/read.php', JSON.stringify(data));
  },

  /**
   * Update about page info
   * @param {*} data - Object containing the about page info to be updated
   */
  editAboutPage(data) {
    return post('/about/updateById.php', JSON.stringify(data));
  },

  /**
   * Create image data
   * @param {*} data - Object containing the image data properties
   */
  createImageData(data) {
    return post('/image_data/create.php', JSON.stringify(data));
  },

  /**
   * Delete image data by id
   * @param {*} data - Object containing the image data id to be deleted
   */
  deleteImageData(data) {
    return post('/image_data/delete.php', JSON.stringify(data));
  },

  /**
   * Get image data by given image data id
   * @param {*} data - Object containing the image data id
   */
  getImageDataById(data) {
    return post('/image_data/getImageById.php', JSON.stringify(data));
  },

  /**
   * Get image data by given service id
   * @param {*} data - Object containing the image data service id
   */
  getImageDataByServiceId(data) {
    return post('/image_data/getImageByServiceId.php', JSON.stringify(data));
  },

  /**
   * Get image data by page
   * @param {*} data - Object containing the image data page
   */
  getImageDataByPage(data) {
    return post('/image_data/getImageByPage.php', JSON.stringify(data));
  },

  /**
   * Get all images from a section of a service
   * @param {*} data - Object containing the section id, content id and service id as params
   */
  getServiceSectionImgs(data) {
    return post('/image_data/getServiceSectionImgs.php', JSON.stringify(data));
  },

  /**
   * Get the sections with given service id
   * @param {*} data - Object containing the service id as param
   */
  getSectionsByServiceId(data) {
    return post('/section/readByServiceId.php', JSON.stringify(data));
  },

  /**
   * Get the section with given section id
   * @param {*} data - Object containing the section id as param
   */
  getSectionById(data) {
    return post('/section/readById.php', JSON.stringify(data));
  },

  /**
   *Create a service section
   * @param {*} data - Object containing the section info as params
   */
  createSection(data) {
    return post('/section/create.php', JSON.stringify(data));
  },

  /**
   * Delete section by its given id
   * @param {*} data - Object containing the section id as param
   */
  deleteSectionById(data) {
    return post('/section/delete.php', JSON.stringify(data));
  },

  /**
   * Update section by its given id
   * @param {*} data - Object containing the section data as param
   */
  updateSection(data) {
    return post('/section/updateById.php', JSON.stringify(data));
  },

};
