import React, { Component } from 'react';
import api from '../../api';
import Loader from '../../components/Loader';
import Space from '../../components/Space';
import { infoCategories } from '../../utils/ConstantsUtils';
import { getInputClass } from '../../utils/StringUtils';

class AdminScheduleEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { adminIsLoggedIn } = this.props;

    window.scrollTo(0, 0);

    // Redirect to admin login if admin is not logged in
    if (!adminIsLoggedIn) {
      window.location.href = "/4HY=e5T}_9}UkN";
    } else {

      const urlArray = document.location.href.split('/');
      let id = null;

      try {
        id = parseInt(urlArray[6]);
        if (id) { this.getScheduleData(id) }

      } catch(e) {
        window.location.href = "/4HY=e5T}_9}UkN";
      }
    }
  }

  /**
   * Get all schedule data with given schedule id
   * @param {*} id - Schedule id
   */
  getScheduleData = (id) => {
    this.setState({ loading: true });
    const data = { "id": id };
    api.getScheduleById(data).then(res => {
      if(res && res.data && res.data.length) {
        this.setState({
          id: parseInt(res.data[0].id),
          title: res.data[0].title,
          title_en: res.data[0].title_en,
          date_range: res.data[0].date_range,
          date_range_en: res.data[0].date_range_en,
          where: res.data[0].where,
          where_en: res.data[0].where_en,
          category_id: res.data[0].category_id ? parseInt(res.data[0].category_id) : 1,
          order_number: res.data[0].order_number ? parseInt(res.data[0].order_number) : 1,
          loading: false
        });
      } else {
        this.setState({ loading: false });
      }
    }).catch(() => {
      this.setState({ loading: false });
    });
  }

  /**
   * Handle dynamic inputs in state
   * @param {*} event - Current element clicked
   * @param {*} name - Corresponding name of the current element clicked
   */
  handleChange(event, name) {
    const stateAux = { ...this.state };
    stateAux[name] = event.target.value;
    this.setState({ ...stateAux });
  }

  /**
   * Delete schedule by calling api
   */
  delete = () => {
    const { id } = this.state;
    let deleteScheduleMessage = false;

    if(id) {
      const data = { "id": id };
      this.setState({ loading: true });

      api.deleteSchedule(data).then(res => {
        if(res && res.data) {
          window.location.href = "/admin/horario"
          this.setState({ loading: false });
        } else {
          deleteScheduleMessage = "Não foi possível eliminar.";
          this.setState({ deleteScheduleMessage, loading: false });
        }
      }).catch(() => {
        deleteScheduleMessage = "Não foi possível eliminar.";
        this.setState({ deleteScheduleMessage, loading: false });
      });      
    } else {
      deleteScheduleMessage = "Não foi possível eliminar.";
      this.setState({ deleteScheduleMessage });
    }
  }

  /**
   * Update schedule by calling api
   */
  save = () => {
    const { id, title, date_range, where, title_en, date_range_en, where_en, category_id, order_number } = this.state;

    let updateScheduleMessage = false;
    let titleErr = false;
    let dateErr = false;
    let whereErr = false;
    let titleEnErr = false;
    let dateEnErr = false;
    let whereEnErr = false;

    const category_id_aux = category_id ? (category_id + "") : null;
    const order_number_aux = order_number ? (order_number + "") : null;

    if(id && title && date_range && where && title_en && date_range_en && where_en) {
      const data = { "id": id, "title": title, "date_range": date_range, "where": where, "category_id": category_id_aux,
        "order_number": order_number_aux, "title_en": title_en, "date_range_en": date_range_en, "where_en": where_en };

      this.setState({ loading: true });
      api.updateSchedule(data).then(res => {
        if(res && res.data) {
          updateScheduleMessage = "Atualizado com sucesso.";
          this.setState({ updateScheduleMessage, loading: false });
        } else {
          updateScheduleMessage = "Não foi possível atualizar.";
          this.setState({ updateScheduleMessage, loading: false });
        }
      }).catch(() => {
        updateScheduleMessage = "Não foi possível atualizar.";
        this.setState({ updateScheduleMessage, loading: false });
      });      
    } else {
      if (!title) { titleErr = "Por favor indique o título." }
      if (!date_range) { dateErr = "Por favor indique o horário." }
      if (!where) { whereErr = "Por favor indique o sítio." }
      if (!title_en) { titleEnErr = "Por favor indique o título em inglês." }
      if (!date_range_en) { dateEnErr = "Por favor indique o horário em inglês." }
      if (!where_en) { whereEnErr = "Por favor indique o sítio em inglês." }
      if (!title  && !date_range && !where && !title_en && !date_range_en && !where_en) { updateScheduleMessage = "Não foi possível atualizar." }
      this.setState({ titleErr, dateErr, whereErr, titleEnErr, dateEnErr, whereEnErr, updateScheduleMessage });
    }
  }

  render() {
    const { title, date_range, where, category_id, order_number, loading, titleErr, dateErr,
      whereErr, updateScheduleMessage, deleteScheduleMessage, title_en, titleEnErr, where_en,
      whereEnErr, date_range_en, dateEnErr } = this.state;

    return (
      <div className="fbrito-page fbrito-admin-schedule fbrito-bg-grey">
        <Loader loading={loading} />
        <div className="fbrito-page-title">Editar Horário Semanal</div>
        <form>
          <div className="row fbrito-block-padding">
            <div className="col-md p-2">
              <label htmlFor="title" className="form-label">Título</label>
              <input type="text" placeholder="Insira um título" name="title" id="title" className={getInputClass(titleErr)} value={title} onChange={(event) => this.handleChange(event, "title")} />
              {titleErr ? <span className="fbrito-input-error">{titleErr}</span> : null}
            </div>
            <div className="col-md p-2">
              <label htmlFor="title-en" className="form-label">Título - Inglês</label>
              <input type="text" placeholder="Insira um título em inglês" name="title-enen" id="title-en" className={getInputClass(titleEnErr)} value={title_en} onChange={(event) => this.handleChange(event, "title_en")} />
              {titleEnErr ? <span className="fbrito-input-error">{titleEnErr}</span> : null}
            </div>
          </div>

          <br />
          <div className="row fbrito-block-padding">
            <div className="col-md p-2">
              <label htmlFor="date-range" className="form-label">Horário</label>
              <input type="text" placeholder="Insira um horário" name="date-range" id="date-range" className={getInputClass(dateErr)} value={date_range} onChange={(event) => this.handleChange(event, "date_range")} />
              {dateErr ? <span className="fbrito-input-error">{dateErr}</span> : null}
            </div>
            <div className="col-md p-2">
              <label htmlFor="date-range-en" className="form-label">Horário - Inglês</label>
              <input type="text" placeholder="Insira um horário em inglês" name="date-range-en" id="date-range-en" className={getInputClass(dateEnErr)} value={date_range_en} onChange={(event) => this.handleChange(event, "date_range_en")} />
              {dateEnErr ? <span className="fbrito-input-error">{dateEnErr}</span> : null}
            </div>
          </div>

          <br />
          <div className="row fbrito-block-padding">
            <div className="col-md p-2">
              <label htmlFor="where" className="form-label">Onde</label>
              <input type="text" placeholder="Indique local" name="where" id="where" className={getInputClass(whereErr)} value={where} onChange={(event) => this.handleChange(event, "where") }/>
              {whereErr ? <span className="fbrito-input-error">{whereErr}</span> : null}
            </div>
            <div className="col-md p-2">
              <label htmlFor="where-en" className="form-label">Onde - Inglês</label>
              <input type="text" placeholder="Indique local em inglês" name="where-en" id="where-en" className={getInputClass(whereEnErr)} value={where_en} onChange={(event) => this.handleChange(event, "where_en") }/>
              {whereEnErr ? <span className="fbrito-input-error">{whereEnErr}</span> : null}
            </div>
          </div>

          <br />
          <div className="row fbrito-block-padding">
            <div className="col-md p-2">
              <label htmlFor="category" className="form-label">Categoria</label>
              {infoCategories && infoCategories.length ? (
                <select
                  name="category"
                  id="category"
                  className="form-control"
                  placeholder="Categoria"
                  onChange={(event) => this.handleChange(event, "category_id")}
                  value={category_id || 1} >
                    {infoCategories.map(item => (
                      <option key={item.id} value={item.id}>{item.name}</option>))}   
                </select>) : "Sem categorias"}
            </div>

            <div className="col-md p-2">
              <label htmlFor="order_number" className="form-label">Marcar ordem</label>
              <input type="number" placeholder="Ordem (inteiro)" min="0" name="order_number" id="order_number" className="form-control" value={order_number} onChange={(event) => this.handleChange(event, "order_number")}/>
            </div>
          </div>
    
          <Space number={3} />
          <div className="row fbrito-block-padding">
            <div className="col-sm p-2"><a href="admin/horario" className="fbrito-btn">Voltar</a></div>
            <div className="col-sm p-2">
              <div name="save" className="fbrito-btn" onClick={this.save}>Guardar</div>
              {updateScheduleMessage ? (<div>{updateScheduleMessage}</div>): null}
            </div>
            <div className="col-sm p-2">
              <div name="delete" className="fbrito-btn fbrito-btn--red" onClick={this.delete}>Eliminar</div>
              {deleteScheduleMessage ? (<div>{deleteScheduleMessage}</div>): null}
            </div>
          </div>
        </form>
        <Space number={3} />
      </div>
    );
  }
}

export default AdminScheduleEdit;