import React, { useState, useEffect } from 'react';
import api from '../../api';
import Loader from '../../components/Loader';
import Space from '../../components/Space';
import { infoBackgroundSection, infoContentType } from '../../utils/ConstantsUtils';
import { getContentTypePT, getInputClass } from '../../utils/StringUtils';

const AdminSectionEdit = ({ adminIsLoggedIn }) => {
  const [state, setState] = useState({});
  const [blog, setBlog] = useState(null);
  const [images, setImages] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    
    // Redirect to admin login if admin is not logged in
    if (!adminIsLoggedIn) {
        window.location.href = "/4HY=e5T}_9}UkN";
    } else {  
      const urlArray = document.location.href.split('/');
      let id = null;

      // Get blog if exists
      const urlParams = new URLSearchParams(window.location.search);
      const blog = urlParams.get('blog') || false;
      setBlog(blog);

      try {
        id = parseInt(urlArray[6]);
        if (id) { 
          getSectionData(id);
        }
      } catch(e) {
        window.location.href = "/4HY=e5T}_9}UkN";
      }
    }
  }, [])

  /**
   * Handle dynamic inputs in state
   * @param {*} event - Current element clicked
   * @param {*} name - Corresponding name of the current element clicked
   */
  const handleChange = (event, name) => {
    const stateAux = { ...state };
    stateAux[name] = event.target.value;
    setState({ ...stateAux });
  }

  /**
   * Fetch section data with given section id
   * @param {*} id - Section id
   */
  const getSectionData = (id) => {
    setState({ loading: true });
    const data = { "id": id };
    api.getSectionById(data).then(res => {
      if(res?.data) {
        const dataId = parseInt(res.data.id);
        const dataServiceId = res.data.service_id;
        const dataContentId = res.data.content_id ? parseInt(res.data.content_id) : null;
        setState({
          id: dataId,
          title: res.data.title,
          title_en: res.data.title_en,
          bg_color: res.data.bg_color || "#FFFFFF",
          type: res.data.type || "quote",
          service_id: dataServiceId,
          content_id: dataContentId,
          section_order: res.data.section_order || '1',
          loading: false
        });
        getImages(dataId, dataServiceId, dataContentId);
      } else {
        setState({ loading: false });
      }
    }).catch(() => {
      setState({ loading: false });
    });
  }

  /**
   * Update section data
   */
  const save = () => {
    const { id, title, title_en, bg_color, type, service_id, content_id, section_order } = state;
    let updateSectionMessage, typeErr, bgColorErr, sectionOrderErr = false;
 
    if(id && service_id && type && bg_color && section_order) {
      const data = {
        "id": `${id || ''}`,
        "title": title,
        "title_en": title_en,
        "bg_color": bg_color || '',
        "type": type,
        "service_id": service_id,
        "content_id": content_id,
        "section_order": section_order || '1' };

      setState({ ...state, loading: true });
      api.updateSection(data).then(res => {
        if(res && res.data) {
          updateSectionMessage = "Atualizado com sucesso.";
          setState({ ...state, updateSectionMessage, typeErr, bgColorErr, sectionOrderErr, loading: false });
        } else {
          updateSectionMessage = "Não foi possível atualizar.";
          setState({ ...state, updateSectionMessage, typeErr, bgColorErr, sectionOrderErr, loading: false });
        }
      }).catch(() => {
        updateSectionMessage = "Não foi possível atualizar.";
        setState({ ...state, updateSectionMessage, typeErr, bgColorErr, sectionOrderErr, loading: false });
      });      
    } else {
      if (!type) { typeErr = "Por favor indique o tipo de conteúdo." }
      if (!bg_color) { bgColorErr = "Por favor indique a cor do background." }
      if (!section_order) { sectionOrderErr = "Por favor indique a order." }
      if (!type && !bg_color && !section_order) { updateSectionMessage = "Não foi possível atualizar." }
      setState({ ...state, typeErr, bgColorErr, sectionOrderErr, updateSectionMessage });
    }
  }

  /**
   * Delete section by calling api
   */
  const deleteSection = () => {
    let deleteSectionMessage = false;

    const { id, type, content_id } = state;

    if(id) {
      const data = { "id": id, "type": type, "content_id": content_id };
      setState({ ...state, loading: true });
      api.deleteSectionById(data).then(res => {
        if(res?.data) {
          setState({ ...state, loading: false });
          goBack();
        } else {
          deleteSectionMessage = "Não foi possível eliminar.";
          setState({ ...state, deleteSectionMessage, loading: false });
        }
      }).catch(() => {
        deleteSectionMessage = "Não foi possível eliminar.";
        setState({ ...state, deleteSectionMessage, loading: false });
      });      
    } else {
      deleteSectionMessage = "Não foi possível eliminar.";
      setState({ ...state, deleteSectionMessage });
    }
  }

  /**
   * Get all images from a section of a service
   * @param {*} id - Section id
   * @param {*} service_id - Service id that contains the section id
   * @param {*} content_id - Image content id that represents the group of images for a section
   */
  const getImages = (id, service_id, content_id) => {
    if(id && service_id && content_id) {
      const data = { "section_id": id, "service_id": service_id, "section_content_id": content_id };
      api.getServiceSectionImgs(data).then(res => {
        if(res?.data) {
          setImages(res.data);
        }
      });      
    }
  }

  const deleteImg = (id) => {
    const data = { "id": id };
    api.deleteImageData(data).then(res => {
      if (res && res.data) {
        window.location.reload();
      }
    });
  }

  /**
   * Go back to the previous page (edit service/blog page)
   */
  const goBack = () => {
    const { service_id } = state;
    if (service_id) {
      window.location.href = blog ? ("/admin/blog/editar/" + service_id) : ("/admin/servico/editar/" + service_id) ;
    }
  }

  /**
   * Redirects to edit or add the content type of the corresponding section
   */
   const editContent = () => {
    const { id, type, content_id } = state;

    if (type === "quote") editQuote(content_id, id);
    if (type === "free_text") editFreetext(content_id, id);
    if (type === "image_data_gallery") editImageGallery(content_id, id);
    if (type === "image_data_slider") editImageSlider(content_id, id);
  }

  /**
   * Redirects to edit the quote with given quote id or add it in case of no id
   * @param {*} quoteId - Clicked quote id to be edited
   * @param {*} sectionId - Clicked section id
   */
   const editQuote = (quoteId, sectionId) => {
    const id = state?.service_id; // Service id

    if (quoteId) { // Redirect to quote edit page with quote id and service id as param
      window.location.href = "/admin/citacao/editar/" + quoteId + "?servico=" + id +  (blog ? `&blog=${id}` : '');      
    } else { // Redirect to quote edit page with service id and section id as param
      window.location.href = "admin/citacao/adicionar/servico" + id + "?servico=" + id + "&seccao=" + sectionId + (blog ? `&blog=${id}` : '');
    }
  }

  /**
   * Redirects to edit the freetext with given freetext id or add it in case of no id
   * @param {*} freetextId - Clicked freetext id to be edited
   * @param {*} sectionId - Clicked section id
   */
  const editFreetext = (freetextId, sectionId) => {
    const id = state?.service_id; // Service id

    if (freetextId) { // Redirect to freetext edit page with freetext id and service id as param
      window.location.href = "/admin/texto-livre/editar/" + freetextId + "?servico=" + id +  (blog ? `&blog=${id}` : '');
    } else { // Redirect to freetext add page with service id and section id as param
      window.location.href = "admin/texto-livre/adicionar?servico=" + id + "&seccao=" + sectionId + (blog ? `&blog=${id}` : '');
    }
  }

  /**
   * Redirects to edit the image with given image id or add it in case of no id
   * @param {*} imgId - Clicked image to be edited
   * @param {*} sectionId - Clicked section id
   */
  const editImageGallery = (imgId, sectionId) => {
    const id = state?.service_id; // Service id

    if (imgId) { // Redirect to image edit page with image id and service id as param
      window.location.href = `admin/imagem/adicionar/service/s?id=${id}&type=gallery&seccao=${sectionId}&galeria=${imgId}` + (blog ? `&blog=${id}` : '');
    } else { // Redirect to image add page with service id and section id as param
      window.location.href = `admin/imagem/adicionar/service/s?id=${id}&type=gallery&seccao=${sectionId}` + (blog ? `&blog=${id}` : '');
    }
  }

  /**
   * Redirects to edit the image with given image id or add it in case of no id
   * @param {*} imgId - Clicked image to be edited
   * @param {*} sectionId - Clicked section id
   */
  const editImageSlider = (imgId, sectionId) => {
    const id = state?.service_id; // Service id

    if (imgId) { // Redirect to image edit page with image id and service id as param
      window.location.href = `admin/imagem/adicionar/service/s?id=${id}&type=slider&seccao=${sectionId}&galeria=${imgId}` + (blog ? `&blog=${id}` : '');
    } else { // Redirect to image add page with service id and section id as param
      window.location.href = `admin/imagem/adicionar/service/s?id=${id}&type=slider&seccao=${sectionId}` + (blog ? `&blog=${id}` : '');
    }
  }

  const { content_id, title, title_en, bg_color, type, section_order, typeErr, bgColorErr, 
    sectionOrderErr, updateSectionMessage, deleteSectionMessage, loading } = state;

  return (
    <div className="fbrito-page fbrito-admin-service fbrito-bg-grey">
      <Loader loading={loading} />

      <div className="fbrito-page-title">Editar Secção</div>

      <form> 
        <div className="row fbrito-block-padding">
          <div className="col-md p-2">
            <label htmlFor="type" className="form-label">Tipo de conteúdo <span className="fbrito-input-error">*</span></label>
              {infoContentType?.length ? (
                <select
                  name="type"
                  id="type"
                  className={getInputClass(typeErr)} 
                  placeholder="Conteúdo"
                  onChange={(event) => handleChange(event, "type")}
                  value={type || 1} 
                  disabled>
                    {infoContentType.map(item => (
                      <option key={item.code} value={item.code}>{item.name}</option>))}   
                </select>) : "Sem tipo de conteúdo" }
                {typeErr ? <span className="fbrito-input-error">{typeErr}</span> : null}
          </div>
          <div className="col-md p-2 row">
            <div className="col-md">            
              <label htmlFor="bg-color" className="form-label">Background <span className="fbrito-input-error">*</span></label>
              {infoBackgroundSection?.length ? (
                <select
                  name="bg-color"
                  id="bg-color"
                  className={getInputClass(bgColorErr)} 
                  placeholder="Conteúdo"
                  onChange={(event) => handleChange(event, "bg_color")}
                  value={bg_color || "#FFFFFF"}>
                    {infoBackgroundSection.map(item => (
                      <option key={item.code} value={item.code}>{item.name}</option>))}   
                </select>) : "Sem cor disponível" }
              {bgColorErr ? <span className="fbrito-input-error">{bgColorErr}</span> : null}
            </div>
            <div className="col-md">
              <label htmlFor="section-order" className="form-label">Ordem <span className="fbrito-input-error">*</span></label>
              <input type="number" placeholder="Limite (inteiro)" min="1" name="section-order" id="section-order" className={getInputClass(sectionOrderErr)} value={section_order || '1'} onChange={(event) => handleChange(event, "section_order")} />
              {sectionOrderErr ? <span className="fbrito-input-error">{sectionOrderErr}</span> : null}
            </div>
          </div>
        </div>

        <br />
        <div className="row fbrito-block-padding">
          <div className="col-md p-2">
            <label htmlFor="title" className="form-label">Título (Opcional)</label>
            <input type="text" placeholder="Insira um título" name="title" id="title" className="form-control"  value={title || ''} onChange={(event) => handleChange(event, "title")} />
          </div>
          <div className="col-md p-2">
            <label htmlFor="title-en" className="form-label">Título - Inglês (Opcional)</label>
            <input type="text" placeholder="Insira um título" name="title-en" id="title-en" className="form-control"  value={title_en || ''} onChange={(event) => handleChange(event, "title_en")} />
          </div>
        </div>

        <Space number={2} />
        <div className="row fbrito-block-padding">
          <div className="fbrito-btn-admin" onClick={editContent}>
            {`${content_id && (type !== "image_data_gallery" && type !== "image_data_slider") ? "Editar" : "Adicionar"} ${getContentTypePT(type)}`}
          </div>
        </div>

        <br />
        {(type === "image_data_gallery" || type === "image_data_slider") && images?.length ? (
          <div className="row fbrito-block-padding">
            {images.map((item, i) => (
              <div className="col-2 fbrito-block-padding-2 fbrito-img-container" key={"img" + i}>
                <div><span className="fbrito-btn-admin btn-del" onClick={() => deleteImg(item.id)}>X</span> &nbsp;	&nbsp; </div>
                <img className="fbrito-admin-gallery" src={item.image_base} width="100%" />
                <div className='fbrito-order'>Ordem: {item.order || "-"}</div>
              </div>))}
          </div>
        ) : null}

        <Space number={3} />
        <div className="row fbrito-block-padding" style={{ marginTop: "70px !important" }}>
          <div className="col-sm p-2"><div onClick={goBack} className="fbrito-btn">Voltar</div></div>
          <div className="col-sm p-2">
            <div className="fbrito-btn" onClick={save}>Guardar</div>
            {updateSectionMessage ? <div>{updateSectionMessage}</div> : null}
          </div>
          <div className="col-sm p-2">
            <div className="fbrito-btn fbrito-btn--red" onClick={deleteSection}>Eliminar</div>
            {deleteSectionMessage ? <div>{deleteSectionMessage}</div> : null}
          </div>
        </div>
      </form>
      <Space number={3} />
    </div>
  );
}

export default AdminSectionEdit;