/**
 * Get loaded image
 */
const loadImg = (src) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', resolve);
    image.addEventListener('error', reject);
    image.src = src;
  });
}

/**
 * Get base64 data from  imagefile
 * @param {*} file - File image type
 * @param {*} setImgData - Function to update image data in state
 */
const getBase64 = (file, setImgData, setImgWidth, setImgHeight) =>  {
  if (file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    console.log(reader)
    reader.onload = function () {

      const newImage = new Image(); 
      newImage.onload = function(){
       // Update width and height
       if (setImgWidth) { setImgWidth(newImage.width); }
        if (setImgHeight) { setImgHeight(newImage.height); }
      };
      newImage.src = reader.result; 

      // Update base64
      if (setImgData) { setImgData(reader.result); }
    };
    reader.onerror = function (error) { };
  }
}

/**
 * Update state to go to the next image from slider
 * @param {*} imgId - Current image id
 * @param {*} setImgId - Function to update the current image id
 * @param {*} imgsList - Array of all images
 */
const imgSliderNext = (imgId, setImgId, imgsList) => {
  if (imgId && setImgId && imgsList) {
    if (imgId < imgsList.length) { setImgId(`${parseInt(imgId) + 1}`) }
  }  
}

/**
 * 
 * @param {*} imgId - Current image id
 * @param {*} setCurrentImgId - Function to update the current image id
 */
const imgSliderPrevious = (imgId, setImgId) => {
  if (imgId && setImgId) {
    if (imgId > 1) { setImgId(`${parseInt(imgId) - 1}`) }
  }
}

export {
  loadImg,
  getBase64,
  imgSliderNext,
  imgSliderPrevious,
};
