/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import api from '../api';
import { getLanguage } from '../utils/LanguageUtils';
import Loader from './Loader';

const QuotesSlider = ({ relatedCode, quoteId }) => {
  const [state, setState] = useState({});

  useEffect(() => {
    if (quoteId) {
      setState({ ...state, loading: true });

      const data = { "id": quoteId, "language": getLanguage() };
      api.getQuoteById(data).then(res => {
        if (res?.data?.length) {
          setState({ ...state, quotes: res.data, loading: false });
        } else {
          setState({ ...state, loading: false });
        }
      }).catch(() => {
        setState({ ...state, loading: false });
      }); 
    } else if (relatedCode) {
      setState({ ...state, loading: true });

      const data = { "related_code": relatedCode, "language": getLanguage() };
      api.getQuotesByCode(data).then(res => {
        if (res && res.data) {
          setState({ ...state, quotes: res.data, loading: false });
        } else {
          setState({ ...state, loading: false });
        }
      }).catch(() => {
        setState({ ...state, loading: false });
      }); 
    }
  }, [])

  const { loading, quotes } = state;

  return (
    <div className={quotes?.length && (relatedCode?.includes("service") || quoteId) ? "fbrito-quotes-section" : ""}>
      <div className={quotes?.length && (relatedCode?.includes("service") || quoteId) ? "fbrito-quotes-slider" : ""}>
          <Loader loading={loading} />
        
          { quotes?.length ? (
            <div className="fbrito-quotes">
              <div id="fbritoCarouselIndicators" className="carousel slide" data-ride="carousel" data-interval="7000">
              {!quoteId ? (
                <ol className="carousel-indicators">
                  {quotes.map((item, i) => (
                    <li key={"text" + i} data-target='#fbritoCarouselIndicators' data-slide-to={i + ""} className={i === 0 ? 'active' : '' }></li>
                  ))}
                </ol>) :null}
            
                <div className="carousel-inner">
                  {quotes.map((item, i) => (
                    <div key={"text" + i} className={i === 0 ? 'carousel-item active' : 'carousel-item'}>
                      <div className='fbrito-quote-01'>“{item.text}”</div>
                      <div className='fbrito-quote-02'>{item.author}</div>
                    </div> ))}
                </div>

                {/* {!quoteId ? (
                  <>
                    <a className="carousel-control-prev" href="#fbritoCarouselIndicators" role="button" data-slide="prev">
                      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span className="sr-only"></span>
                    </a>
                    <a className="carousel-control-next" href="#fbritoCarouselIndicators" role="button" data-slide="next">
                      <span className="carousel-control-next-icon" aria-hidden="true"></span>
                      <span className="sr-only"></span>
                    </a>
                  </>
                ) : null} */}

              </div>
            </div>) : null}
        </div>
    </div>
  );
  
}

export default QuotesSlider;
