import React, { useState, useEffect } from 'react';
import api from '../../api';
import Loader from '../../components/Loader';
import Space from '../../components/Space';

const AdminServices = ({ adminIsLoggedIn }) => {
  const [state, setState] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    
    // Redirect to admin login if admin is not logged in
    if (!adminIsLoggedIn) {
      window.location.href = "/4HY=e5T}_9}UkN";
    } else {
      setState({ loading: true });

      const data = { }
      api.getServicesAll(data).then(res => {
        if(res && res.data) {
          setState({ services: res.data, loading: false });
        } else {
          setState({ loading: false });
        }
      }).catch(() => {
        setState({ loading: false });
      });
    }
  }, [])

  /**
   * Redirect to edit service page with given service id
   * @param {*} id - Id of clicked service
   */
  const editService = (id) => {
    window.location.href = "/admin/servico/editar/" + id;
  }

  const { loading, services } = state;

  return (
    <div className="fbrito-page fbrito-admin-services fbrito-bg-grey">
      <Loader loading={loading} />

      <div className="fbrito-page-title">Serviços</div>

      <h3>Lista de serviços (<a href="admin/servico/adicionar">Adicionar</a>)</h3>

      {services && services.length ? (
        <React.Fragment>
          <div className="row">
            <div className="col-1"><b>Ordem</b></div>
            <div className="col-1"><b>Destaque</b></div>
            <div className="col-4"><b>Nome</b></div>
            <div className="col-4"><b>Categorias</b></div>
            <div className="col-2"></div>
          </div>

          {services.map((item, i) => (
            <div key={"text" + i} className="row">
              <div className="col-1">{item.service_order || '-'}</div>
              <div className="col-1">{item.highlight || '-'}</div>
              <div className="col-4">{item.name}</div>
              <div className="col-4">
                {item.categories?.length? (
                  item.categories.map((cat, index) => (item.categories?.length === 1) || (item.categories?.length === index + 1) ?  cat.name : `${cat.name}, `)
                ) : "-"}
              </div>
              <div className="col-2">
                <div className="fbrito-btn-admin" onClick={() => editService(item.id)}>Editar</div>
              </div>
            </div>))}
          </React.Fragment>) : (<div>Ainda não existem serviços.</div>)}
        
      <Space number={2} />
    </div>
  );
}

export default AdminServices;