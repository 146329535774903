import React from 'react';
import CategoriesBlock from '../components/CategoriesBlock';
import Menu from '../components/Menu';
import Space from '../components/Space';
import { getTranslations } from '../utils/LanguageUtils';

const NotFound = () => (
  <React.Fragment>
    <Menu pageKey="not-found" />
    <div className="fbrito-page fbrito-notfound-page">
      <div className="fbrito-bg-grey">
        <div className="fbrito-page-title">{getTranslations().screens.notFound.title}</div>
        <div className="row text-center">
          <div className="col-12 fbrito-notfound-page-description">{getTranslations().screens.notFound.description}</div>
          <Space number={2} />
          <div className="col-12">
            <a href="/" className="fbrito-btn">{getTranslations().screens.notFound.homepageLink}</a>
          </div>
        </div>
        <Space number={4} />
      </div>
  
      {/* Categories block */}
      <CategoriesBlock />
      {/* End - Categories block */}

      <Space number={2} />
    </div>
  </React.Fragment>
);

export default NotFound;
