import React, { useEffect, useState } from 'react';
import api from '../../api';
import { getInputClass } from '../../utils/StringUtils';
import Loader from './../../components/Loader';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CustomUploadAdapterPlugin } from '../../utils/CKEditorUtils';
import Space from '../../components/Space';

const AdminFreetextEdit = ({ adminIsLoggedIn }) => {
  const [state, setState] = useState({});
  const [blog, setBlog] = useState(null);
  const [textValue1, setTextValue1] = useState('');
  const [textValue2, setTextValue2] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    
    // Redirect to admin login if admin is not logged in
    if (!adminIsLoggedIn) {
      window.location.href = "/4HY=e5T}_9}UkN";
    } else {
      const urlArray = document.location.href.split('/');
      let id = null;
      try {
        id = parseInt(urlArray[6]);

        if (id) {
          // Get service id and blog if exists
          const urlParams = new URLSearchParams(window.location.search);
          const serviceId = urlParams.get('servico') || false;
          const blog = urlParams.get('blog') || false;
          
          setState({ serviceId });
          setBlog(blog)

          getFreetextData(id, serviceId)
        }
      } catch(e) {
        window.location.href = "/4HY=e5T}_9}UkN";
      }
    }
    return null;
  }, [])

  /**
   * Fetch freetext data with given freetext id
   * @param {*} id - Freetext id
   * @param {*} serviceId - Service id
   */
  const getFreetextData = (id, serviceId) => {
    setState({ ...state, loading: true });
    const data = { "id": id };
    api.getFreetextById(data).then(res => {
      if(res?.data?.length) {
        setState({
          ...state,
          serviceId,
          id: parseInt(res.data[0].id),
          text_order:  res.data[0].text_order ? res.data[0].text_order : 0,
          loading: false
        });

        const text1 =  res.data[0].text ? res.data[0].text : '';
        const text2 = res.data[0].text_en ? res.data[0].text_en : '';
        setTextValue1(text1);
        setTextValue2(text2);

      } else {
        setState({ ...state, loading: false });
      }
    }).catch(() => {
      setState({ ...state, loading: false });
    });
  }

  /**
   * Handle dynamic inputs in state
   * @param {*} event - Current element clicked
   * @param {*} name - Corresponding name of the current element clicked
   */
   const handleChange = (event, name) => {
    const stateAux = { ...state };
    stateAux[name] = event.target.value;
    setState({ ...stateAux });
  }

  /**
   * Handle dynamic first editor input in state
   * @param {*} event - Current element clicked
   * @param {*} editor - editor name of the current element clicked
   */
  const handleChangeEditor1 = (event, editor) => {
    if (editor) {
      const data = editor.getData();
      setTextValue1(data);
    }
  }

  /**
   * Handle dynamic second editor input in state
   * @param {*} event - Current element clicked
   * @param {*} editor - editor name of the current element clicked
   */
  const handleChangeEditor2 = (event, editor) => {
    if (editor) {
      const data = editor.getData();
      setTextValue2(data);
    }
  }

  /**
   * Delete freetext
   */
  const deleteFreetext = () => {
    const { id } = state;
    let deleteFreetextMessage = false;

    if(id) {
      const data = { "id": id };
      setState({ ...state, loading: true });
      api.deleteFreetext(data).then(res => {
        if(res && res.data) {
          setState({ ...state, loading: false });
          goBack();
        } else {
          deleteFreetextMessage = "Não foi possível eliminar.";
          setState({  ...state, deleteFreetextMessage, loading: false });
        }
      }).catch(() => {
        deleteFreetextMessage = "Não foi possível eliminar.";
        setState({ ...state, deleteFreetextMessage, loading: false });
      });      
    } else {
      deleteFreetextMessage = "Não foi possível eliminar.";
      setState({ ...state, deleteFreetextMessage });
    }
  }

  /**
   * Update quote by calling api
   */
  const save = () => {
    const { id, text_order, serviceId } = state;
    let updateFreetextMessage, textErr, textEnErr, textOrderErr = false;

    if(id && textValue1 && textValue2 && text_order && serviceId) {
      const data = { "id": id, "text": textValue1, "text_en": textValue2, "text_order": text_order, "service_id": serviceId };
      setState({ ...state, loading: true });
      api.updateFreetext(data).then(res => {
        if(res && res.data) {
          updateFreetextMessage = "Atualizado com sucesso";
          setState({ ...state, updateFreetextMessage, textErr, textEnErr, textOrderErr, loading: false });
        } else {
          updateFreetextMessage = "Não foi possível atualizar.";
          setState({  ...state, updateFreetextMessage, textErr, textEnErr, textOrderErr, loading: false });
        }
      }).catch(() => {
        updateFreetextMessage = "Não foi possível atualizar.";
        setState({ ...state, updateFreetextMessage, textErr, textEnErr, textOrderErr, loading: false });
      });      
    } else {
      if (!textValue1) { textErr = "Por favor indique o texto." }
      if (!textValue2) { textEnErr = "Por favor indique o texto em inglês." }
      if (!text_order) { textOrderErr = "Por favor indique a ordem." }
      if (!textValue1 && !textValue2 && !text_order && !serviceId) { updateFreetextMessage = "Não foi possível atualizar." }
      setState({ ...state, textErr, textEnErr, textOrderErr, updateFreetextMessage });
    }
  }

  /**
   * Go back to the previous page (edit service page)
   */
  const goBack = () => {
    const { serviceId } = state;
    if (serviceId) {
      window.location.href = blog ? ("/admin/blog/editar/" + serviceId) : ("/admin/servico/editar/" + serviceId) ;
    }
  }

  const { text_order, textErr, textEnErr, textOrderErr, loading, updateFreetextMessage, deleteFreetextMessage } = state;

  return ( 
    <div className="fbrito-page fbrito-admin-services fbrito-bg-grey">
      <Loader loading={loading} />
      <div className="fbrito-page-title">{`${blog ? 'Blog' :'Serviço'} - Editar Texto Livre`}</div>
      <form>
        <div className="row fbrito-block-padding">
          <div className="col-md p-2">
            <label className="form-label">Texto livre <span className="fbrito-input-error">*</span></label>
            <CKEditor
              editor={ClassicEditor}
              data={textValue1 || ''}
              onChange={handleChangeEditor1}
              config={{
                extraPlugins: [CustomUploadAdapterPlugin], 
                mediaEmbed: { previewsInData:true }
                }} />
          </div>
          {textErr ? <span className="fbrito-input-error">{textErr}</span> : null}
        </div>

        <br />
        <div className="row fbrito-block-padding">
          <div className="col-md p-2">
            <label className="form-label">Texto livre - Inglês <span className="fbrito-input-error">*</span></label>
            <CKEditor
              editor={ClassicEditor}
              data={textValue2 || ''}
              onChange={handleChangeEditor2}
              config={{
                extraPlugins: [CustomUploadAdapterPlugin], 
                mediaEmbed: { previewsInData:true }
                }} />
            {textEnErr ? <span className="fbrito-input-error">{textEnErr}</span> : null}
          </div>
        </div>

        <br />
        <div className="row fbrito-block-padding">
          <div className="col-md p-2">
            <label htmlFor="text-order" className="form-label">Marcar ordem <span className="fbrito-input-error">*</span></label>
            <input type="number" placeholder="Ordem (inteiro)" min="1" name="text-order" id="text-order" className={getInputClass(textOrderErr)} value={text_order || ''} onChange={(event) => handleChange(event, "text_order")} />
            {textOrderErr ? <span className="fbrito-input-error">{textOrderErr}</span> : null}
          </div>
          <div className="col-md p-2"></div>
        </div>

        <Space number={3} />
        <div className="row fbrito-block-padding">
          <div className="col-sm p-2"><div onClick={goBack} className="fbrito-btn">Voltar</div></div>
          <div className="col-sm p-2">
            <div name="save" className="fbrito-btn" onClick={save}>Guardar</div>
            {updateFreetextMessage ? (<div>{updateFreetextMessage}</div>): null}
          </div>
          <div className="col-sm p-2">
            <div name="delete" className="fbrito-btn fbrito-btn--red" onClick={deleteFreetext}>Eliminar</div>
            {deleteFreetextMessage ? <div>{deleteFreetextMessage}</div>: null}
          </div>
        </div>
        
      </form>
      <Space number={3} />
    </div>
  );
}

export default AdminFreetextEdit;
