import React from 'react';
import { getTranslations } from '../utils/LanguageUtils';
import FBritoAbout01 from '../assets/imgs/fbrito-about-01.svg';

const AboutHeader = ({ aboutInfo} ) => {
  return (
    <div className="fbrito-about-00">
      {aboutInfo ? (
        <div className="fbrito-about-00-1">
          <div className="fbrito-about-01"><img src={FBritoAbout01} alt={getTranslations().components.aboutHeader.imgAlt} /></div>
          {aboutInfo.text1 ? (<div className="fbrito-about-02">{aboutInfo.text1}</div>) : null}
          {/* <div className="fbrito-about-02">{getTranslations().components.aboutHeader.description}</div> */}
        </div>) : null}
    </div>
  );
};

export default AboutHeader;
