import React, { Component } from 'react';
import api from '../api';
import { StringUtils } from "../utils";
import { getLanguage, getTranslations } from '../utils/LanguageUtils';
import Loader from './Loader';

class ServiceCard extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { categoryId, highlight } = this.props;

    // Get services or blog from specific category
    if(categoryId) {
      let data = { "category_id": categoryId, language: getLanguage() };

      this.setState({ loading: true });
      api.getServicesByCategoryId(data).then(res => {
        if(res && res.data) {
            this.setState({ services: res.data, loading: false });
        } else {
          this.setState({ loading: false });
        }
      }).catch(() => {
        this.setState({ loading: false });
      });

    }

    // Get highlights of services or blog
    if(highlight) {
      const dataHighlights = { language: getLanguage() };
      this.setState({ loading: true });
      api.getHighlights(dataHighlights).then(res => {
        if(res && res.data) {
            this.setState({ services: res.data, loading: false });
        } else {
          this.setState({ loading: false });
        }
      }).catch(() => {
        this.setState({ loading: false });
      });
    }
  }

  /**
   * Build service url ("/category/service")
   * @param {*} categoryCode - Category code
   * @param {*} serviceCode - Service code
   */
  getServiceUrl = (serviceCode) => {
    // const { type } = this.props;
    // let serviceUrl = "/";

    // if (type === "blog") {
    //   serviceUrl = serviceCode ? ("/blog/" + serviceCode) : "/blog";
    // } else {
      // serviceUrl = serviceCode ? ("/servico/" + serviceCode) : "/";

      // if (categoryCode && serviceCode) {
      //   serviceUrl = "/" + categoryCode + "/" + serviceCode;
      //   if (categoryCode === "activities") {
      //     serviceUrl = "/actividades/" + serviceCode;
      //   }
      // }      
    // }

    return serviceCode ? ("/servico/" + serviceCode) : "/";
  }

  render() {
    const { loading, services } = this.state;

    return (
      <div className="row fbrito-row-responsive">
        <Loader loading={loading} />

        {services && services.length ? (
          services.map(item => (
            <div className='col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center' key={item.id}>
              {/* <a href={this.getServiceUrl(item.category_code, item.code)} className={'fbrito-service-card fbrito-cat-bdr-' + item.category_code}> */}

              <a href={this.getServiceUrl(item.code)} className='fbrito-service-card fbrito-cat-bdr-blog'>
                <div 
                  // className={!item.image ? `fbrito-service-card__img fbrito-bg-cat-${item.category_code}` : "fbrito-service-card__img"}
                  className={!item.image ? `fbrito-service-card__img fbrito-bg-cat-blog` : "fbrito-service-card__img"}
                  style={item.image ? { backgroundImage: "url(" + item.image + ")"} : {}} ></div>
                {/* <div className={'fbrito-service-card__name fbrito-cat-' + item.category_code}>{item.name}</div> */}
                <div className='fbrito-service-card__name fbrito-cat-blog'>{item.name}</div>
                <div className='fbrito-service-card__comment'>{item.comments}</div>
                <div className='fbrito-service-card__description'>
                  {StringUtils.truncateString(item.description, item.desc_limit || 100)}
                </div>
              </a>
            </div>))) : ( <div className='col-12'>{getTranslations().components.serviceCard.noResult}</div>)}
      </div>);
  }
}

export default ServiceCard;
