import React from 'react';

const ImageGallery = ({ images, setCurrentImgId, className, dataTarget }) => {
  return (
    <div className={className}>
        <div className="fbrito-jg">

        {images.map(item => (
            <a key={item.id} style={{ "--w": item.w, "--h":  item.h}} data-toggle="modal" data-target={dataTarget + item.id} onClick={() => setCurrentImgId(item.id)}>
                <img src={item.image} />
            </a>))}

        </div>
    </div>
  );
}

export default ImageGallery;
