/**
 * Object with all categories types ids and code
 */
const categoryType = {
  yogaId: 1,
  designId: 2,
  activityId: 3,
  blogId: 4,
  yogaStr: 'yoga',
  designStr: 'design',
  activityStr: 'activity',
  blogStr: 'blog',
  yogaName: 'Yoga',
  designName: 'Life Design',
  activityName: 'Sustentabilidade',
  blogName: "Blog"
};

/**
 * Object with all categories types for url
 */
 const categoryTypeUrl = {
  yoga: 'yoga',
  design: 'design',
  activities: 'actividades',
  blog: 'blog'
};

/**
 * Array of objects with all backgrounds for content types of sections
 */
 const infoBackgroundSection = [
  { code: "#FFFFFF", name: "Branco" },
  { code: "#F1F1F1", name: "Cizento" }
];

/**
 * Array of objects with all 3 categories info
 */
 const infoCategories = [
  { id: categoryType.yogaId, code: categoryType.yogaStr, name: categoryType.yogaName },
  { id: categoryType.designId, code: categoryType.designStr, name: categoryType.designName },
  { id: categoryType.activityId, code: categoryType.activityStr, name: categoryType.activityName },
  { id: categoryType.blogId, code: categoryType.blogStr, name: categoryType.blogName }
];

/**
 * Array of objects with all content types for sections
 */
const infoContentType = [
  { code: "quote", name: "Citação" },
  { code: "free_text", name: "Texto livre" },
  { code: "image_data_gallery", name: "Galeria de imagens" },
  { code: "image_data_slider", name: "Slider de imagens" }
];

/**
 * Object with Francisco personal info
 */
 const fbritoInfo = {
  number: "+351 968 817 363",
  email: "franciscofaiabrito@gmail.com",
};

/**
 * Object with all social media links
 */
const socialLinks = {
  facebook: "https://www.facebook.com/yogacarcavelos",
  linkedin: "https://www.linkedin.com/in/franciscoffvb",
  behance: "https://www.behance.net/franciscoffvb",
};

/**
 * Object with quotes type
 */
const quotesType = {
  activity: "activity",
  home: "home",
  portfolio: "portfolio",
  yoga: "yoga",
  service: "service", // Add id after, Example: "service1"
};

/**
 * Object with pages type
 */
 const pagesType = {
  activity: "Página da Actividade",
  home: "Página Inicial",
  portfolio: "Página do Portfolio",
  yoga: "Página de Yoga",
  service: "Página do Serviço", // Add id after, Example: "service1"
  about: "Página Sobre",
  design: "Página do Design",
};

/**
 * URL of the site
 */
// const feUrl = "http://localhost:3000"; // Local
// const feUrl = "https://dev-francisco-brito.000webhostapp.com"; // Dev
const feUrl = "https://francisco.cf"; // QA
// const feUrl = "https://francisco-brito.com"; // Prod

/**
 * URL of the site
 */
const beUrl = "http://localhost"; // Local
// const beUrl = "https://dev-francisco-brito.000webhostapp.com"; // Dev
// const beUrl = "https://francisco.cf"; // QA
// const beUrl = "https://francisco-brito.com"; // Prod

export {
  categoryType,
  categoryTypeUrl,
  infoBackgroundSection,
  infoCategories,
  infoContentType,
  fbritoInfo,
  quotesType,
  pagesType,
  socialLinks,
  feUrl,
  beUrl,
};
