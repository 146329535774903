import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import Footer from './../components/Footer';
import Menu from './../components/Menu';

const PublicRoute = ({ component: Component, pageKey, ...rest }) => {
  const footerNotDiff = (pageKey !== 'public_contacts') && (pageKey !== 'public_service') && (pageKey !== 'public_blog_type');

  return (
    <Route
    {...rest}
    render={props => (
      <Fragment>
        <Menu pageKey={pageKey} />
        <Component {...props} />
        {footerNotDiff ? <Footer /> : null}
      </Fragment>)}
  />
  );
};

export default PublicRoute;
