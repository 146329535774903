import React, { useEffect, useState } from 'react';
import { quotesType } from '../../utils/ConstantsUtils';
import { getLanguage, getTranslations } from '../../utils/LanguageUtils';
import ScheduleCard from './../../components/ScheduleCard';
import ServiceCard from './../../components/ServiceCard';
import QuotesSlider from './../../components/QuotesSlider';
import CategoriesBlock from '../../components/CategoriesBlock';
import AboutHeader from './../../components/AboutHeader';
import Loader from '../../components/Loader';
import api from '../../api';
import FBritoHeader1 from '../../assets/imgs/fbrito-homepage-header.jpg';
import FBritoHeader2 from '../../assets/imgs/fbrito-homepage-header-resized.jpg';
import { loadImg } from '../../utils/ImagesUtils';
import Space from '../../components/Space';

const Homepage = ({location}) => {
  const [loadingAbout, setLoadingAbout] = useState(false);
  const [aboutInfo, setAboutInfo] = useState(null);

  useEffect(() => {
    // Load header image
    const headerImg = document.getElementById('fbrito-intro-01');
    headerImg.style.backgroundImage = `url(${FBritoHeader2})`;

    loadImg(FBritoHeader1).then(() => {
      const headerImg = document.getElementById('fbrito-intro-01');
      headerImg.style.backgroundImage = `url(${FBritoHeader1})`;
    });

    // Handle scroll
    const elem = document.getElementsByTagName("html");
    elem[0].scrollTo(0,0);

    // Get about data
    fetchAboutInfo();

  }, [])

  /**
   * Fetch about info from api
   */
  const fetchAboutInfo = () => {
    const data = { "language": getLanguage() };
    setLoadingAbout(true);
    api.getAboutPage(data).then(res => {
      if (res?.data?.length) {
        setAboutInfo(res.data[0]);
        setLoadingAbout(false);
      }
      setLoadingAbout(false);
    }).catch(() => {
      setLoadingAbout(false);
    });
  }

  return (
    <div className="fbrito-home-page">

      <Loader loading={loadingAbout} homepageStyle/>

      {/* Intro hero image */}
      <div className="fbrito-intro">
        <div className="fbrito-intro-01" id='fbrito-intro-01'></div>

        <div className="fbrito-intro-02">
          <span>{getTranslations().screens.public.homepage.intro1V1}</span><br />
          <span>{getTranslations().screens.public.homepage.intro1V2}</span>
        </div>
        <div className="fbrito-intro-03">
          <span>{getTranslations().screens.public.homepage.intro2V1}</span><br />
          <span>{getTranslations().screens.public.homepage.intro2V2}</span><br />
          <span>{getTranslations().screens.public.homepage.intro2V3}</span>
        </div>
        <div className="fbrito-intro-04">
          <span>{getTranslations().screens.public.homepage.intro3V1}</span><br /> 
          <span>{getTranslations().screens.public.homepage.intro3V2}</span><br />
          <span>{getTranslations().screens.public.homepage.intro3V3}</span><br />
          <span>{getTranslations().screens.public.homepage.intro3V4}</span>
        </div>
      </div>
      {/* End - Intro hero image */}
      
      {/* Categories block */}
      <CategoriesBlock />
      {/* End - Categories block */}

      {/* Services highlights block */}
      <div className="fbrito-bg-grey">
        <div className="fbrito-page-title">{getTranslations().screens.public.homepage.highlight}</div>
        <div className="fbrito-block-padding fbrito-center">
          <ServiceCard highlight/>
        </div>
      </div>
       {/* End - Highlights block */}

      {/* About block */}
      <div className="fbrito-about">
        <div className="fbrito-page-title">{getTranslations().screens.public.homepage.aboutMe}</div>
        <AboutHeader aboutInfo={aboutInfo} />
        <Space number={2} />
        <div className="fbrito-center">
          <a href="sobre" className="fbrito-btn">{getTranslations().screens.public.homepage.moreBtn}</a>
        </div>
        <Space number={3} />
      </div>
      {/* End - About block */}

      {/* Schedule block */}
      <div className="fbrito-bg-grey">
        <div className="fbrito-page-title">{getTranslations().screens.public.homepage.schedule}</div>
        <div className="fbrito-block-padding">
          <div className="fbrito-center">
            <ScheduleCard />
          </div>
          <div className="fbrito-center">
            <a href="contactos" className="fbrito-btn">{getTranslations().screens.public.homepage.contactBtn}</a>
          </div>
        </div>
        <Space number={3} />
      </div>
       {/* End - Schedule block */}

      {/* Quotes block */}
      <div className="fbrito-home-quotes">
        <QuotesSlider relatedCode={quotesType.home} />
      </div>
      {/* End - Quotes block */}

    </div>
  );
}

export default Homepage;
