import React, { Component } from 'react';
import api from '../../api';
import Space from '../../components/Space';
import Loader from './../../components/Loader';

class AdminQuotes extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { adminIsLoggedIn } = this.props;

    window.scrollTo(0, 0);

    // Redirect to admin login if admin is not logged in
    if (!adminIsLoggedIn) {
      window.location.href = "/4HY=e5T}_9}UkN";
    } else {
      this.setState({
        loadingHome: true,
        loadingYoga: true,
        loadingPortfolio: true,
        loadingActivity: true,
      });
      const dataHome = { "related_code": "home" };
      const dataYoga = { "related_code": "yoga" };
      const dataPortfolio = { "related_code": "portfolio" };
      const dataActivity = { "related_code": "activity" };

      api.getQuotesByCode(dataHome).then(res => {
        if(res && res.data) {
          this.setState({ quotesHome: res.data, loadingHome: false });
        } else {
          this.setState({ loadingHome: false });
        }
      }).catch(() => {
        this.setState({ loadingHome: false });
      });

      api.getQuotesByCode(dataYoga).then(res => {
        if(res && res.data) {
          this.setState({ quotesYoga: res.data, loadingYoga: false });
        } else {
          this.setState({ loadingYoga: false });
        }
      }).catch(() => {
        this.setState({ loadingYoga: false });
      });

      api.getQuotesByCode(dataPortfolio).then(res => {
        if(res && res.data) {
          this.setState({ quotesPortfolio: res.data, loadingPortfolio: false });
        } else {
          this.setState({ loadingPortfolio: false });
        }
      }).catch(() => {
        this.setState({ loadingPortfolio: false });
      });

      api.getQuotesByCode(dataActivity).then(res => {
        if(res && res.data) {
          this.setState({ quotesActivity: res.data, loadingActivity: false });
        } else {
          this.setState({ loadingActivity: false });
        }
      }).catch(() => {
        this.setState({ loadingActivity: false });
      });

    }
  }

  /**
   * Redirect to edit quote page with given quote id
   * @param {*} id - Id of clicked quote
   */
  editQuote = (id) => {
    window.location.href = "/admin/citacao/editar/" + id;
  }

  render() {
    const { quotesHome, quotesYoga, quotesPortfolio, quotesActivity, loadingHome, loadingYoga, loadingPortfolio, loadingActivity } = this.state;

    return (
      <div className="fbrito-page fbrito-admin-quotes fbrito-bg-grey">
        <Loader loading={loadingHome || loadingYoga || loadingPortfolio || loadingActivity} />

        <div className="fbrito-page-title">Citações / Recomendações</div>
        
        <h3>Página inicial (<a href={"admin/citacao/adicionar/home"}>Adicionar</a>)</h3>
        {quotesHome && quotesHome.length ? (
          <React.Fragment>
            <div className="row">
              <div className="col-6">Citação</div>
              <div className="col-3">Autor</div>
              <div className="col-3"></div>
            </div>

            {quotesHome.map((item, i) => (
              <div key={"text" + i} className="row">
                <div className="col-6">{item.text}</div>
                <div className="col-3">{item.author}</div>
                <div className="col-3">
                  <div className="fbrito-btn-admin" onClick={() => this.editQuote(item.id)}>Editar</div>
                </div>
              </div>))}
            </React.Fragment>) : (<div>Não existem citações para a Página Inicial.</div>)}

        <Space number={2} />
        <h3>Página Yoga (<a href={"admin/citacao/adicionar/yoga"}>Adicionar</a>)</h3>
        {quotesYoga && quotesYoga.length ? (
          <React.Fragment>
            <div className="row">
              <div className="col-6">Citação</div>
              <div className="col-3">Autor</div>
              <div className="col-3"></div>
            </div>

            {quotesYoga.map((item, i) => (
              <div key={"text" + i} className="row">
                <div className="col-6">{item.text}</div>
                <div className="col-3">{item.author}</div>
                <div className="col-3">
                  <div className="fbrito-btn-admin" onClick={() => this.editQuote(item.id)}>Editar</div>
                </div>
              </div>))}
            </React.Fragment>) : (<div>Não existem citações para a Página de Yoga.</div>)}

        <Space number={2} />
        <h3>Página de UX / Web Design / Branding (<a href={"admin/citacao/adicionar/portfolio"}>Adicionar</a>)</h3>
        {quotesPortfolio && quotesPortfolio.length ? (
          <React.Fragment>
            <div className="row">
              <div className="col-6">Citação</div>
              <div className="col-3">Autor</div>
              <div className="col-3"></div>
            </div>

            {quotesPortfolio.map((item, i) => (
              <div key={"text" + i} className="row">
                <div className="col-6">{item.text}</div>
                <div className="col-3">{item.author}</div>
                <div className="col-3">
                  <div className="fbrito-btn-admin" onClick={() => this.editQuote(item.id)}>Editar</div>
                </div>
              </div>))}
            </React.Fragment>) : (<div>Não existem citações para a Página de Life Design.</div>)}

        <Space number={2} />
        <h3>Página de Sustentabilidade (<a href={"admin/citacao/adicionar/activity"}>Adicionar</a>)</h3>
        {quotesActivity && quotesActivity.length ? (
          <React.Fragment>
            <div className="row">
              <div className="col-6">Citação</div>
              <div className="col-3">Autor</div>
              <div className="col-3"></div>
            </div>

            {quotesActivity.map((item, i) => (
              <div key={"text" + i} className="row">
                <div className="col-6">{item.text}</div>
                <div className="col-3">{item.author}</div>
                <div className="col-3">
                  <div className="fbrito-btn-admin" onClick={() => this.editQuote(item.id)}>Editar</div>
                </div>
              </div>))}
            </React.Fragment>) : (<div>Não existem citações para a página de Sustentabilidade.</div>)}

          <Space number={2} />
      </div>
    );
  }
}

export default AdminQuotes;