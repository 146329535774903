/**
 * Image upload adaptar for ckeditor 
 */
class UploadAdapter {
	constructor(loader) {
		this.loader = loader
	}

	/**
	 * Upload an image from ckeditor
	 * @returns The image base64 data uploaded
	 */
	upload () {
		return this.loader.file.then(async uploadedFile => {
			return new Promise(async (resolve, reject) => {
					const reader = new FileReader();
					reader.readAsDataURL(uploadedFile); 
					reader.onload = function () {
						resolve({default: reader.result});
					};  
				});
		})
	}

	abort() {}

}

export default UploadAdapter;

/**
 * Custom plugin with upload file adapter
 * @param {*} editor - CKEditor
 */
export function CustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = loader => {
    return new UploadAdapter(loader)
  }
}
