import React, { Component } from 'react';
import api from '../../api';
import Space from '../../components/Space';
import Loader from './../../components/Loader';

class AdminSchedule extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { adminIsLoggedIn } = this.props;

    window.scrollTo(0, 0);

    // Redirect to admin login if admin is not logged in
    if (!adminIsLoggedIn) {
      window.location.href = "/4HY=e5T}_9}UkN";
    } else {
      this.setState({ loading: true });

      api.getScheduleAll().then(res => {
        if(res && res.data) {
          this.setState({ schedule: res.data, loading: false });
        } else {
          this.setState({ loading: false });
        }
      }).catch(() => {
        this.setState({ loading: false });
      });
    }
  }

  /**
   * Redirect to edit schedule page with given schedule id
   * @param {*} id - Id of clicked schedule
   */
  editSchedule = (id) => {
    window.location.href = "/admin/horario/editar/" + id;
  }

  render() {
    const { schedule, loading } = this.state;

    return (
    <div className="fbrito-page fbrito-admin-schedule fbrito-bg-grey">
      <Loader loading={loading} />
      <div className="fbrito-page-title">Horário Semanal</div>
      
      <h3>Lista de horários (<a href={"admin/horario/adicionar"}>Adicionar</a>)</h3>

      {schedule && schedule.length ? (
        <React.Fragment>
          <div className="row">
            <div className="col-lg-3">Título</div>
            <div className="col-lg-3">Horário</div>
            <div className="col-lg-2">Localização</div>
            <div className="col-lg-2">Ordem</div>
            <div className="col-lg-2"></div>
          </div>

          {schedule.map((item, i) => (
            <div key={"text" + i} className="row">
              <div className="col-lg-3">{item.title}</div>
              <div className="col-lg-3">{item.date_range}</div>
              <div className="col-lg-2">{item.where}</div>
              <div className="col-lg-2">{item.order_number}</div>
              <div className="col-lg-2">
                <div className="fbrito-btn-admin" onClick={() => this.editSchedule(item.id)}>Editar</div>
              </div>
            </div>))}
          </React.Fragment>) : (<div>Horários ainda não definidos.</div>)}
      
      <Space number={2} />
    </div>
    );
  }
}

export default AdminSchedule;