import React, { useState, useEffect } from 'react';
import { categoryType, quotesType } from '../../utils/ConstantsUtils';
import { getTranslations } from '../../utils/LanguageUtils';
import QuotesSlider from '../../components/QuotesSlider';
import ScheduleCard from '../../components/ScheduleCard';
import ServiceCard from '../../components/ServiceCard';
import Loader from '../../components/Loader';
import api from '../../api';
import ImageModal from '../../components/ImageModal';
import ImageGallery from '../../components/ImageGallery';
import Space from '../../components/Space';

const Activities = () => {
  const [currentImgId, setCurrentImgId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    setLoading(true);
    const data = { "page": "activity", "type": "gallery" };

    api.getImageDataByPage(data).then(res => {
      if (res?.data?.length) {

        const imagesAux = []
        res.data.forEach((item, index) => {
          imagesAux.push({ id: `${index + 1}`, w: item.width, h: item.height, image: item.image_base });
        })

        setImages(imagesAux)
        setLoading(false);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  }, [])

  const itemImage = images.find(item => item.id === currentImgId);

  return (
    <div className="fbrito-page fbrito-activities-page">
      <Loader loading={loading} />

      <div className="fbrito-bg-grey">
        <div className="fbrito-page-title">{getTranslations().screens.public.activities.title}</div>
        <div className="fbrito-block-padding fbrito-center">
          <div className="row fbrito-row-responsive">
            <ServiceCard categoryId={categoryType.activityId} />  
          </div>
        </div>
        <Space number={2} />
      </div>

      <div>
      <Space number={3} />
        <div className="fbrito-quotes-section">
          <div className="fbrito-quotes-slider">
            <QuotesSlider relatedCode={quotesType.activity} />
          </div>
          <ImageGallery
            images={images} 
            setCurrentImgId={setCurrentImgId} 
            className="fbrito-quotes-imgs"
            dataTarget="#modal-activity-" />
        </div>
        <Space number={3} />
      </div>

      <div className="fbrito-bg-grey-light">
        <div className="fbrito-page-title">{getTranslations().screens.public.activities.schedule}</div>
        <div className="fbrito-block-padding">
          <div className="fbrito-center">
            <ScheduleCard />
          </div>
          <div className="fbrito-center">
            <a href="contactos" className="fbrito-btn">{getTranslations().screens.public.activities.contactBtn}</a>
          </div>
        </div>
        <Space number={3} />
      </div>

      {/* Modal with current image clicked */}
      <ImageModal
        images={images}
        currentImgId={currentImgId}
        setCurrentImgId={setCurrentImgId}
        itemImage={itemImage}
        type="activity" />
      {/* End - Modal with current image clicked */}
    </div>
  );
}

export default Activities;
