import React, { useState, useEffect } from 'react';
import { categoryType, quotesType, socialLinks } from '../../utils/ConstantsUtils';
import { getTranslations } from '../../utils/LanguageUtils';
import ServiceCard from './../../components/ServiceCard';
import QuotesSlider from '../../components/QuotesSlider';
import Loader from '../../components/Loader';
import api from '../../api';
import ImageModal from '../../components/ImageModal';
import ImageGallery from '../../components/ImageGallery';
import Space from '../../components/Space';

const Design = () => {
  const [currentImgId,setCurrentImgId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    setLoading(true);
    const data = { "page": "design", "type": "gallery" };

    api.getImageDataByPage(data).then(res => {
      if (res && res.data && res.data.length) {

        const imagesAux = []
        res.data.forEach((item, index) => {
          imagesAux.push({ id: `${index + 1}`, w: item.width, h: item.height, image: item.image_base });
        })

        setImages(imagesAux)
        setLoading(false);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  }, [])

  const itemImage = images.find(item => item.id === currentImgId);

  return (
    <div className="fbrito-page fbrito-design-page">
      <Loader loading={loading} />
      
      <div className="fbrito-bg-grey">
        <div className="fbrito-page-title">{getTranslations().screens.public.design.title}</div>
        <div className="fbrito-block-padding fbrito-center">
          <div className="row fbrito-row-responsive">
            <ServiceCard categoryId={categoryType.designId} />
          </div>
        </div>
        <Space number={2} />
      </div>

      <div>
        <div className="fbrito-page-title">{getTranslations().screens.public.design.portfolio}</div>
        <div className="fbrito-quotes-section">
          <div className="fbrito-quotes-slider">
            <QuotesSlider relatedCode={quotesType.portfolio} />
          </div>
          <ImageGallery
            images={images} 
            setCurrentImgId={setCurrentImgId} 
            className="fbrito-quotes-imgs"
            dataTarget="#modal-portfolio-" />
        </div>

        <br />
        <div className="fbrito-center">
          <a href={socialLinks.behance} className="fbrito-btn" ><span>{getTranslations().screens.public.design.button}&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-external-link-alt"></i></a>
        </div>
        <Space number={3} />
      </div>

      {/* Modal with current image clicked */}
      <ImageModal
        images={images}
        currentImgId={currentImgId}
        setCurrentImgId={setCurrentImgId}
        itemImage={itemImage}
        type="portfolio" />
      {/* End - Modal with current image clicked */}

    </div>
  );
}

export default Design;
