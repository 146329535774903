import React, { useState } from 'react';
import { fbritoInfo, socialLinks } from '../utils/ConstantsUtils';
import FBritoLogo from '../assets/imgs/logo.svg';
import FBritoFacebook from '../assets/imgs/social/icon-facebook.svg';
import FBritoLinkedin from '../assets/imgs/social/icon-linkedin.svg';
import FBritoBehance from '../assets/imgs/social/icon-behance.svg';
import FBritoFacebookHover from '../assets/imgs/social/icon-facebook-hover.svg';
import FBritoLinkedinHover from '../assets/imgs/social/icon-linkedin-hover.svg';
import FBritoBehanceHover from '../assets/imgs/social/icon-behance-hover.svg';
import { validateEmail } from '../utils/StringUtils';
import { getTranslations } from '../utils/LanguageUtils';
import Loader from './Loader';
import api from '../api';
import emailjs from 'emailjs-com';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [facebookImg, setFacebookImg] = useState(FBritoFacebook);
  const [linkedinImg, setLinkedinImg] = useState(FBritoLinkedin);
  const [behanceImg, setBehanceImg] = useState(FBritoBehance);

  /**
   * Handle email value on change
   * @param {*} e - Current event clicked
   */
  const handleChange = (e) => {
    setEmail(e.target.value)

    if (e.target.value && validateEmail(e.target.value)) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }

  /**
   * Handle send email
   */
  const sendEmail = () => {
    let messageAux = (<div>{getTranslations().components.footer.subscriptionFailed}<i className="fas fa-redo"></i></div>);
    setLoading(true);

    if(email && validateEmail(email)) {

      const templateParams = { email };
    
      // Service ID: service_2inwsa9
      // tEMPLATE ID: template_pi94ebu
      // pUBLIC KEY: vDjTuiLT3fQOV2Fal

      emailjs.send('service_2inwsa9','template_pi94ebu', templateParams, 'vDjTuiLT3fQOV2Fal')
      // emailjs.send('service_n3bjlew','template_0q8t4we', templateParams, 'o8gvvt27CD1N4Ppm0')
        .then((response) => {
          if (response?.status === 200) {
            susbribeEmail();
            messageAux = (<div>{getTranslations().components.footer.subscriptionSuccess}<i className="fas fa-redo"></i></div>);
            setMessage(messageAux);
            setEmail('');
            setLoading(false);
          }
          setLoading(false);
        }, () => {
          setMessage(messageAux);
          setLoading(false);
        });

      
      // const sgMail = require('@sendgrid/mail')

      // sgMail.setApiKey("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")
      // const msg = {
      //   to: 'radsgiva@gmail.com', // your recipient
      //   from: 'radsgiva@gmail.com', // your verified sender
      //   subject: 'Nova subscrição | http://francisco-brito.com',
      //   html: `<div>Nova subscrição à newsletter: <strong>${email}</strong></div>`,
      // }
      // sgMail.send(msg).then(() => {
      //   //susbribeEmail();
      //   messageAux = (<div>{getTranslations().components.footer.subscriptionSuccess}<i className="fas fa-redo"></i></div>)
      //   setMessage(messageAux);
      //   setEmail('');
      //   setLoading(false);
      // }).catch(() => {
      //   setMessage(messageAux);
      //   setLoading(false);
      // })
    } else {
      setMessage(messageAux);
      setLoading(false);
    }
  }

  /**
   * Add susbriber in database
   */
  const susbribeEmail = () => {
    setLoading(true);
    let data = { "email": email };
    api.createSubscriber(data).then(res => {
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  }

  /**
   * Change social logo image on mouse hover
   * @param {String} socialType - Social type (ex.: "facebook")
   */
  const changeImgOver = (socialType) => {
    if (socialType === "facebook") { setFacebookImg(FBritoFacebookHover) }
    if (socialType === "linkedin") { setLinkedinImg(FBritoLinkedinHover) }
    if (socialType === "behance") { setBehanceImg(FBritoBehanceHover) }
  }

  /**
   * Change social logo image on mouse out
   * @param {String} socialType - Social type (ex.: "facebook")
   */
   const changeImgOut = (socialType) => {
    if (socialType === "facebook") { setFacebookImg(FBritoFacebook) }
    if (socialType === "linkedin") { setLinkedinImg(FBritoLinkedin) }
    if (socialType === "behance") { setBehanceImg(FBritoBehance) }
  }

  const refreshEmail = () => {
    setEmail("");
    setMessage("");
  }

  return (
    <React.Fragment>
      <Loader loading={loading} />
      <div className="fbrito--footer">
        <div className="fbrito--footer-01">
          <div className="fbrito--footer-01-1">
            <span className="fbrito-image"><img src={FBritoLogo} alt="Logo" className="fbrito-logo" height="36px"/></span>
            <span className="fbrito-name">{getTranslations().components.footer.websiteName}</span>
          </div>
        </div>

        <div className="fbrito--footer-02">
          <div className="fbrito--footer-02-1">
            <div><a href={`mailto:${fbritoInfo.email}`}>{fbritoInfo.email}</a></div>
            <div><a href={`tel:${fbritoInfo.number}`}>{fbritoInfo.number}</a></div>
          </div>

          <div className="fbrito--footer-02-2"> 
            <a href={socialLinks.facebook} target="_blank" rel="noreferrer">
            <img 
                onMouseOver={() => changeImgOver("facebook")} 
                onMouseOut={() => changeImgOut("facebook")} 
                src={facebookImg}
                alt="Facebook" />
            </a>
            <a href={socialLinks.linkedin} target="_blank" rel="noreferrer">
            <img 
                onMouseOver={() => changeImgOver("linkedin")} 
                onMouseOut={() => changeImgOut("linkedin")} 
                src={linkedinImg} 
                alt="LinkedIn" />
            </a>
            <a href={socialLinks.behance} target="_blank" rel="noreferrer">
            <img 
                onMouseOver={() => changeImgOver("behance")} 
                onMouseOut={() => changeImgOut("behance")} 
                src={behanceImg} 
                alt="Behance"/>
            </a>
          </div>
          
          <div className="fbrito--footer-02-3"> 
            <div className="fbrito--footer-02-3-1">Newsletter</div>

            
              <div className="fbrito--footer-02-3-2">
                <input type="email" placeholder={getTranslations().components.footer.emailPlaceholder} value={email} onChange={(e) => handleChange(e)} />
                
                <button
                  className={disabled ? "disabled" : ""}
                  type="button"
                  onClick={!disabled ? sendEmail : null}
                  >{getTranslations().components.footer.subscribe.toUpperCase()}</button>                
              </div> 
              
              {message ? (<div className="fbrito--footer-02-3-3" onClick={refreshEmail}>{message}</div>) : null}
          </div>
        </div>

        <div className="fbrito--footer-03">{`© ${new Date().getFullYear()} ${getTranslations().components.footer.rights}`}</div>
      </div>
    </React.Fragment>);
}

export default Footer;
