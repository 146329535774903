
import Homepage from '../screens/public/Homepage';
import About from './../screens/public/About';
import Schedule from './../screens/public/Schedule';
import Yoga from './../screens/public/Yoga';
import Design from './../screens/public/Design';
import Activities from './../screens/public/Activities';
import Blog from './../screens/public/Blog';
import Contacts from './../screens/public/Contacts';
import Service from './../screens/public/Service';
import AdminHomepage from './../screens/admin/AdminHomepage';
import AdminLogin from './../screens/admin/AdminLogin';
import AdminChangePassword from './../screens/admin/AdminChangePassword';
import AdminSchedule from './../screens/admin/AdminSchedule';
import AdminScheduleEdit from './../screens/admin/AdminScheduleEdit';
import AdminScheduleAdd from './../screens/admin/AdminScheduleAdd';
import AdminServices from './../screens/admin/AdminServices';
import AdminServiceEdit from './../screens/admin/AdminServiceEdit';
import AdminServiceAdd from './../screens/admin/AdminServiceAdd';
import AdminQuotes from './../screens/admin/AdminQuotes';
import AdminQuoteEdit from './../screens/admin/AdminQuoteEdit';
import AdminQuoteAdd from './../screens/admin/AdminQuoteAdd';
import AdminBlog from './../screens/admin/AdminBlog';
import AdminBlogEdit from './../screens/admin/AdminBlogEdit';
import AdminBlogAdd from './../screens/admin/AdminBlogAdd';
import AdminSubscriptions from './../screens/admin/AdminSubscriptions';
import AdminAbout from './../screens/admin/AdminAbout';
import AdminAboutEdit from './../screens/admin/AdminAboutEdit';
import AdminFreetextEdit from './../screens/admin/AdminFreetextEdit';
import AdminFreetextAdd from './../screens/admin/AdminFreetextAdd';
import AdminImages from '../screens/admin/AdminImages';
import AdminImageAdd from '../screens/admin/AdminImageAdd';
import AdminSectionEdit from './../screens/admin/AdminSectionEdit';
import AdminSectionAdd from '../screens/admin/AdminSectionAdd';

const ADMIN_ROUTES = [
  {
    path: '/4HY=e5T}_9}UkN',
    component: AdminLogin,
    key: 'admin_home'
  },
  {
    path: '/admin',
    component: AdminHomepage,
    key: 'admin'
  },
  {
    path: '/admin/alterar-password',
    component: AdminChangePassword,
    key: 'admin_change_password'
  },
  {
    path: '/admin/horario',
    component: AdminSchedule,
    key: 'admin_schedule'
  },
  {
    path: '/admin/horario/editar/:id?',
    component: AdminScheduleEdit,
    key: 'admin_schedule_edit'
  },
  {
    path: '/admin/horario/adicionar',
    component: AdminScheduleAdd,
    key: 'admin_schedule_add'
  },
  {
    path: '/admin/servicos',
    component: AdminServices,
    key: 'admin_services'
  },
  {
    path: '/admin/servico/editar/:id?',
    component: AdminServiceEdit,
    key: 'admin_services_edit'
  },
  {
    path: '/admin/servico/adicionar',
    component: AdminServiceAdd,
    key: 'admin_services_add'
  },
  {
    path: '/admin/citacoes',
    component: AdminQuotes,
    key: 'admin_quotes'
  },
  {
    path: '/admin/citacao/editar/:id?',
    component: AdminQuoteEdit,
    key: 'admin_quotes_edit'
  },
  {
    path: '/admin/citacao/adicionar/:categoryCode?',
    component: AdminQuoteAdd,
    key: 'admin_quotes_add'
  },
  {
    path: '/admin/blog',
    component: AdminBlog,
    key: 'admin_blog'
  },
  {
    path: '/admin/blog/editar/:id?',
    component: AdminBlogEdit,
    key: 'admin_blog_edit'
  },
  {
    path: '/admin/blog/adicionar',
    component: AdminBlogAdd,
    key: 'admin_blog_add'
  },
  {
    path: '/admin/subscricoes',
    component: AdminSubscriptions,
    key: 'admin_subscriptions'
  },
  {
    path: '/admin/sobre',
    component: AdminAbout,
    key: 'admin_about'
  },
  {
    path: '/admin/sobre/editar',
    component: AdminAboutEdit,
    key: 'admin_about_edit'
  },
  {
    path: '/admin/texto-livre/editar/:id?',
    component: AdminFreetextEdit,
    key: 'admin_freetext_edit'
  },
  {
    path: '/admin/texto-livre/adicionar',
    component: AdminFreetextAdd,
    key: 'admin_freetext_add'
  },
  {
    path: '/admin/imagens',
    component: AdminImages,
    key: 'admin_images'
  },
  {
    path: '/admin/imagem/adicionar/:page?/:serviceId?',
    component: AdminImageAdd,
    key: 'admin_image_add'
  },
  {
    path: '/admin/seccao/editar/:id?',
    component: AdminSectionEdit,
    key: 'admin_section_edit'
  },
  {
    path: '/admin/seccao/adicionar',
    component: AdminSectionAdd,
    key: 'admin_section_add'
  },
];

const PUBLIC_ROUTES = [
  {
    path: '/',
    component: Homepage,
    key: 'home'
  },
  {
    path: '/sobre',
    component: About,
    key: 'public_about'
  },
  {
    path: '/horario',
    component: Schedule,
    key: 'public_schedule'
  },
  {
    path: '/yoga',
    component: Yoga,
    key: 'public_yoga'
  },
  {
    path: '/design',
    component: Design,
    key: 'public_design'
  },
  {
    path: '/actividades',
    component: Activities,
    key: 'public_activities'
  },
  {
    path: '/blog',
    component: Blog,
    key: 'public_blog'
  },
  {
    path: '/contactos',
    component: Contacts,
    key: 'public_contacts'
  },
  {
    path: '/servico/:serviceSlug?',
    component: Service,
    key: 'public_service'
  },
  {
    path: '/blog/:serviceSlug?',
    component: Service,
    key: 'public_blog_type'
  },
  {
    path: '/yoga/:serviceSlug?',
    component: Service,
    key: 'public_service'
  },
  {
    path: '/design/:serviceSlug?',
    component: Service,
    key: 'public_service'
  },
  {
    path: '/actividades/:serviceSlug?',
    component: Service,
    key: 'public_service'
  },
];

export {
  ADMIN_ROUTES,
  PUBLIC_ROUTES
};
