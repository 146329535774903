import React, { useState } from 'react';
import FBritoLogo from '../assets/imgs/logo.svg';
import FBritoLogoHover from '../assets/imgs/logo-hover.svg';
import { getLanguage, getOtherLanguage, getTranslations } from '../utils/LanguageUtils';

const Menu = ({ pageKey }) => {
  const [logoImg, setLogoImg] = useState(FBritoLogo);

  /**
   * Change current language into the other one (en/pt)
   */
  const changeLanguage = () => {
    const language = getLanguage() === "pt" ? "en" : "pt"
    localStorage.setItem("language", language);
    window.location.reload();
  }

  return (
    pageKey ? (
      <React.Fragment>
        {/* Menu bar mobile version closed --> */}
        <div className={"fbrito-menu-mob " + pageKey}>
          <div className="fbrito-menu-mob-00">
            <a href="/" className="fbrito-menu-mob-01">
              <span>
                <img
                  onMouseOver={() => setLogoImg(FBritoLogoHover)} 
                  onMouseOut={() => setLogoImg(FBritoLogo)} 
                  src={logoImg} 
                  alt="Logo" 
                  className="fbrito-logo" 
                  height="30px"/>
              </span>
              <span className="fbrito-name">{getTranslations().components.menu.websiteName}</span>
            </a>
            <div id="menu-open">{getTranslations().components.menu.open.toUpperCase()}</div>
          </div>
        </div>
        <div id="myMenu">
          <div className="fbrito-menu-mob">
            <div className="fbrito-menu-mob-00">
              <a href="/" className="fbrito-menu-mob-01">
                <span>
                  <img
                    onMouseOver={() => setLogoImg(FBritoLogoHover)} 
                    onMouseOut={() => setLogoImg(FBritoLogo)} 
                    src={logoImg} 
                    alt="Logo" 
                    className="fbrito-logo" 
                    height="30px"/>
                  </span>
                <span className="fbrito-name">{getTranslations().components.menu.websiteName}</span>
              </a>
              <div id="menu-close">{getTranslations().components.menu.close}</div>
            </div>
          </div>
          <div className="fbrito-menu-mob-options">
            <div className={pageKey === "public_about" ? "first fbrito-menu-active" : "first"}><a href="sobre">{getTranslations().components.menu.about}</a></div>
            <div className={pageKey === "public_schedule" ? "fbrito-menu-active" : ""}><a href="horario">{getTranslations().components.menu.schedule}</a></div>
            <div className={pageKey === "public_yoga" ? "fbrito-menu-active" : ""}><a href="yoga">{getTranslations().components.menu.yoga}</a></div>
            <div className={pageKey === "public_design" ? "fbrito-menu-active" : ""}><a href="design">{getTranslations().components.menu.design}</a></div>
            <div className={pageKey === "public_activities" ? "fbrito-menu-active" : ""}><a href="actividades">{getTranslations().components.menu.activities}</a></div>
            <div className={pageKey === "public_contacts" ? "fbrito-menu-active" : ""}><a href="contactos">{getTranslations().components.menu.contacts}</a></div>
            <div className={pageKey === "public_blog" ? "fbrito-menu-active" : ""}><a href="blog">{getTranslations().components.menu.blog}</a></div>
            <li className="fbrito-language"><a onClick={changeLanguage}>{`Read in ${getOtherLanguage().toUpperCase()}`}</a></li>
        
          </div>
        </div>
        {/* End - Menu bar mobile version closed */}

        {/* Menu bar desktop version */}
        <div className={"fbrito-menu " + pageKey}>
          <nav className="navbar navbar-expand-lg">
            <a className="navbar-brand" href="/">
              <span>
                <img
                  onMouseOver={() => setLogoImg(FBritoLogoHover)} 
                  onMouseOut={() => setLogoImg(FBritoLogo)} 
                  src={logoImg} 
                  alt="Logo" 
                  className="fbrito-logo" />
              </span>
              <span className="fbrito-name">{getTranslations().components.menu.websiteName}</span>
            </a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
              <span className="fbrito-open-menu">{getTranslations().components.menu.open.toUpperCase()}</span>
            </button>
            <div className="collapse navbar-collapse" id="collapsibleNavbar">
              <ul className="navbar-nav">
                <li className={pageKey === "public_about" ? "fbrito-menu-active" : ""}><a href="sobre">{getTranslations().components.menu.about}</a></li>
                <li className={pageKey === "public_schedule" ? "fbrito-menu-active" : ""}><a href="horario">{getTranslations().components.menu.schedule}</a></li>
                <li className={pageKey === "public_yoga" ? "fbrito-menu-active" : ""}><a href="yoga">{getTranslations().components.menu.yoga}</a></li>
                <li className={pageKey === "public_design" ? "fbrito-menu-active" : ""}><a href="design">{getTranslations().components.menu.design}</a></li>
                <li className={pageKey === "public_activities" ? "fbrito-menu-active" : ""}><a href="actividades">{getTranslations().components.menu.activities}</a></li>
                <li className={pageKey === "public_contacts" ? "fbrito-menu-active" : ""}><a href="contactos">{getTranslations().components.menu.contacts}</a></li>
                <li className={pageKey === "public_blog" ? "fbrito-menu-active" : ""}><a href="blog">{getTranslations().components.menu.blog}</a></li>
                <li className="fbrito-language"><a onClick={changeLanguage}>{`Read in ${getOtherLanguage().toUpperCase()}`}</a></li>

              </ul>
            </div>
          </nav>
        </div>
        {/* End - Menu bar desktop version */}
      </React.Fragment>): null
  );
}

export default Menu;
