
import { translationsEN } from './../translations/en';
import { translationsPT } from './../translations/pt';

/**
 * Get current language ("pt" - by default, or "en")
 */
const getLanguage = () => {
  return localStorage.language || "pt";
}

/**
 * Get other language ("pt" or "en")
 */
const getOtherLanguage = () => {
  let language = "pt"
  
  if (localStorage.language === "pt" || !localStorage.language) {
    language = "en"
  }

  return language;
}


/**
 * Get translations object depending in the current language
 */
const getTranslations = () => {
  return getLanguage() === "en" ? translationsEN : translationsPT;
}

export {
  getLanguage,
  getOtherLanguage,
  getTranslations,
};