import React, { Component } from 'react';
import api from '../../api';
import Loader from '../../components/Loader';
import Space from '../../components/Space';

class AdminSubscriptions extends Component {
  constructor(props) {
    super(props);

    this.state = { };
  }

  componentDidMount() {
    const { adminIsLoggedIn } = this.props;

    window.scrollTo(0, 0);

    // Redirect to admin login if admin is not logged in
    if (!adminIsLoggedIn) {
      window.location.href = "/4HY=e5T}_9}UkN";
    } else {
      this.getSubscribersData();
    }
  }

  /**
   * Fetch all subscribers from api
   */
  getSubscribersData = () => {
    this.setState({ loading: true });

    api.getSubscribersAll().then(res => {
      if(res && res.data) {
        this.setState({ subscribers: res.data, loading: false });
      } else {
        this.setState({ loading: false });
      }
    }).catch(() => {
      this.setState({ loading: false });
    });  
  }

  /**
   * Delete subscriber from api qith given id
   * @param {*} id - Subscriber id
   */
   deleteSubscriber = (id) => {
    if (id) {
      this.setState({ loading: true });
      const data = { "id": id };
      api.deleteSubscriber(data).then(res => {
        if(res?.data) {
          this.setState({ loading: false });
          this.getSubscribersData();
        } else {
          this.setState({ loading: false });
        }
      }).catch(() => {
        this.setState({ loading: false });
      });        
    }
  }

  render() {
    const { loading, subscribers } = this.state;
  
    return (
      <div className="fbrito-page fbrito-subscriptions">
        
        <Loader loading={loading} />
        
        <div className="fbrito-page-title">Subscrições</div>

        {subscribers && subscribers.length ? (
        <React.Fragment>
          <div className="row">
            <div className="col-lg-3">Email</div>
            <div className="col-lg-2"></div>
          </div>

          {subscribers.map((item, i) => (
            <div key={"text" + i} className="row">
              <div className="col-lg-3">{item.email}</div>
              <div className="col-lg-2">
                <div className="fbrito-btn-admin btn-del" onClick={() => this.deleteSubscriber(item.id)}>Eliminar</div>
              </div>
            </div>))}
          </React.Fragment>) : (<div>Sem subscritores ainda.</div>)}
        
        <Space number={2} />
      </div>
    );
  }
}

export default AdminSubscriptions;