import React, { Component } from 'react';
import api from '../../api';
import Space from '../../components/Space';
import { getInputClass, random } from '../../utils/StringUtils';
import Loader from './../../components/Loader';

class AdminQuoteAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { adminIsLoggedIn } = this.props;

    window.scrollTo(0, 0);

    // Redirect to admin login if admin is not logged in
    if (!adminIsLoggedIn) {
      window.location.href = "/4HY=e5T}_9}UkN";
    } else {
      const urlArray = document.location.pathname.split('/');
      let categoryCode = null;

      try {
        categoryCode = urlArray[4];
        const serviceParam = categoryCode.includes("servico");

        // Get service id, blog, and section id params if exists
        const urlParams = new URLSearchParams(window.location.search);
        const blog = urlParams.get('blog') || false;
        const serviceId = urlParams.get('servico') || false;
        const sectionId = urlParams.get('seccao') || false;

        this.setState({ related_code: categoryCode, serviceParam, blog, serviceId, sectionId })
      } catch (err) {
        this.setState({ related_code: "home" })
      }
    } 
  }

  /**
   * Handle dynamic inputs in state
   * @param {*} event - Current element clicked
   * @param {*} name - Corresponding name of the current element clicked
   */
  handleChange(event, name) {
    const stateAux = { ...this.state };
    stateAux[name] = event.target.value;
    this.setState({ ...stateAux });
  }

  /**
   * Add new quote into database via php api call
   */
  add = () => {
    const { text, author, related_code, text_en, sectionId } = this.state;
    let addQuoteMessage, textErr, authorErr, textEnErr = false;

    if (text && author && text_en) {
      const related_code_aux = related_code ? related_code.replace("servico", "service") : "";
      const randomId = random(5000, 5000000)

      const data = { "text": text, "author": author, "text_en": text_en, "related_code": related_code_aux, "section_id": parseInt(sectionId), "random_id": randomId  };
      this.setState({ loading: true });
      api.createQuote(data).then(res => {
        if(res?.data) {
          this.setState({ loading: false });
          this.goBack();
        } else {
          addQuoteMessage = "Não foi possível adicionar citação / recomendação.";
          this.setState({ addQuoteMessage, loading: false });
        }
      }).catch((e) => {
        addQuoteMessage = "Não foi possível adicionar citação / recomendação.";
        this.setState({ addQuoteMessage, loading: false });
      });      
    } else {
      if (!text) { textErr = "Por favor indique o texto." }
      if (!author) { authorErr = "Por favor indique o autor." }
      if (!text_en) { textEnErr = "Por favor indique o texto em inglês." }
      if (!text && !author && !text_en) { addQuoteMessage = "Não foi possível adicionar citação / recomendação." }
      this.setState({ textErr, authorErr, textEnErr, addQuoteMessage });
    }
  }

  /**
   * Go back to the previous page (edit service page)
   */
  goBack = () => {
    const { blog, serviceId } = this.state;

    if (serviceId) {
      window.location.href = blog ? ("/admin/blog/editar/" + serviceId) : ("/admin/servico/editar/" + serviceId);
    } else {
      window.location.href = "admin/citacoes";
    }
  }

  render() {
    const { textErr, authorErr, textEnErr, text, author, text_en, related_code, loading, addQuoteMessage, serviceParam } = this.state;

    return (
      <div className="fbrito-page fbrito-admin-quotes fbrito-bg-grey">
        <Loader loading={loading} />
        <div className="fbrito-page-title">Adicionar Citação / Recomendação</div>
        <form>
          <div className="row fbrito-block-padding">
            <div className="col-md p-2">
              <label htmlFor="text" className="form-label">Frase</label>
              <textarea rows="5" type="text" placeholder="Insira uma frase" name="text" id="text" className={getInputClass(textErr)} value={text || ""} onChange={(event) => this.handleChange(event, "text")} />
              {textErr ? <span className="fbrito-input-error">{textErr}</span> : null}
            </div>
          </div>
          
          <br />
          <div className="row fbrito-block-padding">
            <div className="col-md p-2">
              <label htmlFor="text-en" className="form-label">Frase - Inglês</label>
              <textarea rows="5" type="text" placeholder="Insira uma frase em inglês" name="text-en" id="text-en" className={getInputClass(textEnErr)} value={text_en || ""} onChange={(event) => this.handleChange(event, "text_en")} />
              {textEnErr ? <span className="fbrito-input-error">{textErr}</span> : null}
            </div>
          </div>

          <br />
          <div className="row fbrito-block-padding">
            <div className="col-md p-2">
              <label htmlFor="author" className="form-label">Autor</label>
              <input type="text" placeholder="Insira um autor" name="author" id="author" className={getInputClass(authorErr)} value={author || ""} onChange={(event) => this.handleChange(event, "author")} />
              {authorErr ? <span className="fbrito-input-error">{authorErr}</span> : null}
            </div>
          </div>

          {!serviceParam ? (
            <React.Fragment>
              <div className="row fbrito-block-padding">
                <div className="col-md p-2">
                  {/* <label htmlFor="code" className="form-label">Tipo de frase</label> */}
                  <select
                    hidden
                    className="form-control"
                    name="code"
                    id="code"
                    onChange={(event) => this.handleChange(event, "related_code")}
                    placeholder="Escolher..."
                    value={related_code || "home"}>
                      <option value="home">Página Inicial</option>
                      <option value="yoga">Página de Yoga</option>
                      <option value="portfolio">Página do Portfolio</option>
                      <option value="activity">Página das Actividade</option>
                  </select>
                </div>
                <div className="col-md p-2"></div>
              </div>
            </React.Fragment>
          ) : null}

          <Space number={3} />
          <div className="row fbrito-block-padding">
            <div className="col-sm p-2"><div onClick={this.goBack} className="fbrito-btn">Voltar</div></div>
            <div className="col-sm p-2">
              <div className="fbrito-btn" onClick={this.add}>Adicionar</div>
              {addQuoteMessage ? (<div>{addQuoteMessage}</div>): null}
            </div>
            <div className="col-sm p-2"></div>
          </div>
        </form>
        <Space number={3} />
      </div>
    );
  }
}

export default AdminQuoteAdd;