import React, { Component } from 'react';
import api from '../../api';
import Loader from '../../components/Loader';
import Space from '../../components/Space';
import { infoCategories } from '../../utils/ConstantsUtils';
import { getInputClass } from '../../utils/StringUtils';

class AdminScheduleAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { adminIsLoggedIn } = this.props;

    window.scrollTo(0, 0);

    // Redirect to admin login if admin is not logged in
    if (!adminIsLoggedIn) { window.location.href = "/4HY=e5T}_9}UkN"; }
  }

  /**
   * Handle dynamic inputs in state
   * @param {*} event - Current element clicked
   * @param {*} name - Corresponding name of the current element clicked
   */
  handleChange(event, name) {
    const stateAux = { ...this.state };
    stateAux[name] = event.target.value;
    this.setState({ ...stateAux });
  }

  /**
   * Add new schedule in database via php api
   */
  add = () => {
    const { title, date, where, order, category_id, title_en, date_en, where_en } = this.state;
    let addScheduleMessage = false;
    let titleErr = false;
    let dateErr = false;
    let whereErr = false;
    let orderErr = false;
    let titleEnErr = false;
    let dateEnErr = false;
    let whereEnErr = false;

    if(title && date && where && title_en && date_en && where_en) {
      const data = { "title": title, "date_range": date, "where": where, "category_id": category_id || 1,
        "order_number": order || 1, "title_en": title_en, "date_range_en": date_en, "where_en": where_en };

      this.setState({ loading: true });
      api.createSchedule(data).then(res => {
        if(res && res.data) {
          this.setState({ loading: false });
          window.location.href = "/admin/horario";
        } else {
          addScheduleMessage = "Não foi possível adicionar horário.";
          this.setState({ addScheduleMessage, loading: false });
        }
      }).catch(() => {
        addScheduleMessage = "Não foi possível adicionar horário.";
        this.setState({ addScheduleMessage, loading: false });
      });      
    } else {
      if (!title) { titleErr = "Por favor indique o título." }
      if (!date) { dateErr = "Por favor indique o horário." }
      if (!where) { whereErr = "Por favor indique a localização." }
      if (!order) { orderErr = "Por favor indique o número de ordem." }
      if (!title_en) { titleEnErr = "Por favor indique o título em inglês." }
      if (!date_en) { dateEnErr = "Por favor indique o horário em inglês." }
      if (!where_en) { whereEnErr = "Por favor indique a localização em inglês." }

      if (!title && !date && !where && !order && !title_en && !date_en && !where_en) {
        addScheduleMessage = "Não foi possível adicionar horário."
      }

      this.setState({ titleErr, dateErr, whereErr, orderErr, addScheduleMessage, titleEnErr, dateEnErr, whereEnErr });
    }
  }

  render() {
    const { titleErr, dateErr, whereErr, orderErr, title, date, where, order, category_id, loading,
      addScheduleMessage, titleEnErr, dateEnErr, whereEnErr, title_en, date_en, where_en } = this.state;

    return (
      <div className="fbrito-page fbrito-admin-schedule fbrito-bg-grey">
        <Loader loading={loading} />
        <div className="fbrito-page-title">Adicionar Horário Semanal</div>
        <form>
          <div className="row fbrito-block-padding">
            <div className="col-md p-2">
              <label htmlFor="title" className="form-label">Título</label>
              <input type="text" placeholder="Insira um título" name="title" id="title" className={getInputClass(titleErr)} value={title} onChange={(event) => this.handleChange(event, "title")}/>
              {titleErr ? <span className="fbrito-input-error">{titleErr}</span> : null}
            </div>
            <div className="col-md p-2">
              <label htmlFor="title-en" className="form-label">Título - Inglês</label>
              <input type="text" placeholder="Insira um título em inglês" name="title-en" id="title-en" className={getInputClass(titleEnErr)} value={title_en} onChange={(event) => this.handleChange(event, "title_en")}/>
              {titleEnErr ? <span className="fbrito-input-error">{titleEnErr}</span> : null}
            </div>
          </div>
          
          <br />
          <div className="row fbrito-block-padding">
            <div className="col-md p-2">
              <label htmlFor="date-range" className="form-label">Horário</label>
              <input type="text" placeholder="Insira um horário" name="date-range" id="date-range" className={getInputClass(dateErr)} value={date} onChange={(event) => this.handleChange(event, "date")}/>
              {dateErr ? <span className="fbrito-input-error">{dateErr}</span> : null}
            </div>
            <div className="col-md p-2">
              <label htmlFor="date-range-en" className="form-label">Horário - Inglês</label>
              <input type="text" placeholder="Insira um horário em inglês" name="date-range-en" id="date-range-en" className={getInputClass(dateEnErr)} value={date_en} onChange={(event) => this.handleChange(event, "date_en")}/>
              {dateEnErr ? <span className="fbrito-input-error">{dateEnErr}</span> : null}
            </div>
          </div>

          <br />
          <div className="row fbrito-block-padding">
            <div className="col-md p-2">
              <label htmlFor="where" className="form-label">Onde</label>
              <input type="text" placeholder="Indique local" name="where" id="where" className={getInputClass(whereErr)} value={where} onChange={(event) => this.handleChange(event, "where")}/>
              {whereErr ? <span className="fbrito-input-error">{whereErr}</span> : null}
            </div>
            <div className="col-md p-2">
              <label htmlFor="where-en" className="form-label">Onde - Inglês</label>
              <input type="text" placeholder="Indique local em inglês" name="where-en" id="where-en" className={getInputClass(whereEnErr)} value={where_en} onChange={(event) => this.handleChange(event, "where_en")}/>
              {whereEnErr ? <span className="fbrito-input-error">{whereEnErr}</span> : null}
            </div>
          </div>
    
          <br />
          <div className="row fbrito-block-padding">
            <div className="col-md p-2">
              <label htmlFor="category" className="form-label">Categoria</label>
              {infoCategories && infoCategories.length ? (
                <select
                  name="category"
                  id="category"
                  className="form-control"
                  placeholder="Categoria"
                  onChange={(event) => this.handleChange(event, "category_id")}
                  defaultValue={category_id || 1} >
                    {infoCategories.map(item => (
                      <option key={item.id} value={item.id}>{item.name}</option>))}   
                </select>) : "Sem categorias" }
            </div>
            <div className="col-md p-2">
              <label htmlFor="order-number" className="form-label">Nº de Ordem</label>
              <input type="number" min="0" placeholder="Indique número de ordem..." name="order-number" id="order-number" className={getInputClass(orderErr)} value={order || 1} onChange={(event) => this.handleChange(event, "order")}/>
              {orderErr ? <span className="fbrito-input-error">{orderErr}</span> : null}
            </div>
          </div>
          
          <Space number={3} />
          <div className="row fbrito-block-padding">
            <div className="col-sm p-2"><a href="/admin/horario" className="fbrito-btn">Voltar</a></div>
            <div className="col-sm p-2">
              <div className="fbrito-btn" onClick={this.add}>Adicionar</div>
              {addScheduleMessage ? (<div>{addScheduleMessage}</div>): null}
            </div>
            <div className="col-sm p-2"></div>
          </div>
        </form>
        <Space number={3} />
      </div>
    );
  }
}

export default AdminScheduleAdd;