import React, { useState, useEffect } from 'react';
import api from '../../api';
import Loader from '../../components/Loader';
import Space from '../../components/Space';
import { truncateString } from '../../utils/StringUtils';

const AdminBlog = ({ adminIsLoggedIn }) => {
  const [state, setState] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    
    // Redirect to admin login if admin is not logged in
    if (!adminIsLoggedIn) {
      window.location.href = "/4HY=e5T}_9}UkN";
    } else {
      setState({ loading: true });

      api.getAboutPage({}).then(res => {
        if(res && res.data) {
          setState({ about: res.data, loading: false });
        } else {
          setState({ loading: false });
        }
      }).catch(() => {
        setState({ loading: false });
      });
    }
  }, [])

  /**
   * Redirect to edit about page information
   */
  const editAboutInfo = () => {
    window.location.href = "/admin/sobre/editar/";
  }

  const { loading, about } = state;

  return (
    <div className="fbrito-page fbrito-admin-about">
      <Loader loading={loading} />

      <div className="fbrito-page-title">Sobre Mim</div>

      {about && about.length ? (
        <React.Fragment>
          <div className="row">
            <div className="col-3">Texto 1</div>
            <div className="col-3">Texto 2</div>
            <div className="col-3">Texto 3</div>
            <div className="col-3"></div>
          </div>

          {about.map((item, i) => (
            <div key={"text" + i} className="row">
              <div className="col-3">{truncateString(item.text1, 20)}</div>
              <div className="col-3">{truncateString(item.text2, 20)}</div>
              <div className="col-3">{truncateString(item.text3, 20)}</div>
              <div className="col-3" >
                <div className="fbrito-btn-admin" onClick={editAboutInfo}>Editar</div>
              </div>
            </div>))}
          </React.Fragment>) : (<div>Ainda não existe informação.</div>)}
        
        <Space number={3} />
    </div>
  );
}

export default AdminBlog;
