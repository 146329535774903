import React, { useState, useEffect } from 'react';
import api from '../../api';
import Loader from '../../components/Loader';
import Space from '../../components/Space';
import { infoBackgroundSection, infoContentType } from '../../utils/ConstantsUtils';
import { getInputClass } from '../../utils/StringUtils';

const AdminSectionAdd = ({ adminIsLoggedIn }) => {
  const [state, setState] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    
    // Redirect to admin login if admin is not logged in
    if (!adminIsLoggedIn) {
      window.location.href = "/4HY=e5T}_9}UkN";
    } else {
			// Get service id if exists
			const urlParams = new URLSearchParams(window.location.search);
			const serviceId = urlParams.get('servico') || false;
      const content_type = urlParams.get('conteudo') || false;

			setState({ 
        bg_color: '#FFFFFF',
        type: content_type || 'quote',
        section_order: '1',
        serviceId
      });
    }
  }, [])

  /**
   * Handle dynamic inputs in state
   * @param {*} event - Current element clicked
   * @param {*} name - Corresponding name of the current element clicked
   */
  const handleChange = (event, name) => {
    const stateAux = { ...state };
    stateAux[name] = event.target.value;
    setState({ ...stateAux });
  }

  /**
   * Add new section in service
   */
  const addSection = () => {
    const { title, title_en, bg_color, type, section_order, serviceId } = state;
    let addSectionMessage, typeErr, bgColorErr, sectionOrderErr = false;

    if(serviceId && type && bg_color && section_order) {
      const data = {
        "title": title || "",
        "title_en": title_en || "",
        "bg_color": bg_color || '#FFFFFF',
        "type": type || 'quote',
        "service_id": serviceId,
        "content_id": "",
        "section_order": section_order || '1'
      };

      setState({ ...state, loading: true });
      api.createSection(data).then(res => {
        if(res?.data) {
          setState({ ...state, typeErr, bgColorErr, sectionOrderErr, loading: false });
          goBack();
        } else {
          addSectionMessage = "Não foi possível adicionar secção.";
          setState({ ...state, addSectionMessage, typeErr, bgColorErr, sectionOrderErr,  loading: false });
        }
      }).catch(() => {
        addSectionMessage = "Não foi possível adicionar secção.";
        setState({ ...state, addSectionMessage, typeErr, bgColorErr, sectionOrderErr, loading: false });
      });      
    } else {
      if (!type) { typeErr = "Por favor indique o tipo de conteúdo." }
      if (!bg_color) { bgColorErr = "Por favor indique a cor do background." }
      if (!section_order) { sectionOrderErr = "Por favor indique a order." }
      if (!type && !bg_color && !section_order) { addSectionMessage = "Não foi adicionar secção." }

      setState({ ...state, typeErr, bgColorErr, sectionOrderErr, addSectionMessage });
    }
  }

  const goBack = () => {
    const { serviceId } = state;

    // Get blog param if exists
		const urlParams = new URLSearchParams(window.location.search);
		const blog = urlParams.get('blog') || false;

    window.location.href = blog ? `/admin/blog/editar/${serviceId}` :  `/admin/servico/editar/${serviceId}`;
  }

  const { title, title_en, bg_color, type, section_order, typeErr, bgColorErr, 
    sectionOrderErr, addSectionMessage, loading } = state;

  return (
    <div className="fbrito-page fbrito-admin-service fbrito-bg-grey">
      <Loader loading={loading} />

      <div className="fbrito-page-title">Adicionar Secção</div>

      <form> 
        <div className="row fbrito-block-padding">
          <div className="col-md p-2">
            <label htmlFor="type" className="form-label">Tipo de conteúdo <span className="fbrito-input-error">*</span></label>
              {infoContentType?.length ? (
                <select
                  name="type"
                  id="type"
                  className={getInputClass(typeErr)} 
                  placeholder="Conteúdo"
                  onChange={(event) => handleChange(event, "type")}
                  value={type || 1} >
                    {infoContentType.map(item => (
                      <option key={item.code} value={item.code}>{item.name}</option>))}   
                </select>) : "Sem tipo de conteúdo" }
                {typeErr ? <span className="fbrito-input-error">{typeErr}</span> : null}
          </div>
          <div className="col-md p-2 row">
            <div className="col-md">            
              <label htmlFor="bg-color" className="form-label">Background <span className="fbrito-input-error">*</span></label>
              {infoBackgroundSection?.length ? (
                <select
                  name="bg-color"
                  id="bg-color"
                  className={getInputClass(bgColorErr)} 
                  placeholder="Conteúdo"
                  onChange={(event) => handleChange(event, "bg_color")}
                  value={bg_color || "#FFFFFF"}>
                    {infoBackgroundSection.map(item => (
                      <option key={item.code} value={item.code}>{item.name}</option>))}   
                </select>) : "Sem cor disponível" }
              {bgColorErr ? <span className="fbrito-input-error">{bgColorErr}</span> : null}
            </div>
            <div className="col-md">
              <label htmlFor="section-order" className="form-label">Ordem <span className="fbrito-input-error">*</span></label>
              <input type="number" placeholder="Limite (inteiro)" min="1" name="section-order" id="section-order" className={getInputClass(sectionOrderErr)} value={section_order || '1'} onChange={(event) => handleChange(event, "section_order")} />
              {sectionOrderErr ? <span className="fbrito-input-error">{sectionOrderErr}</span> : null}
            </div>
          </div>
        </div>

        <br />
        <div className="row fbrito-block-padding">
          <div className="col-md p-2">
            <label htmlFor="title" className="form-label">Título (Opcional)</label>
            <input type="text" placeholder="Insira um título" name="title" id="title" className="form-control"  value={title || ''} onChange={(event) => handleChange(event, "title")} />
          </div>
          <div className="col-md p-2">
            <label htmlFor="title-en" className="form-label">Título - Inglês (Opcional)</label>
            <input type="text" placeholder="Insira um título" name="title-en" id="title-en" className="form-control"  value={title_en || ''} onChange={(event) => handleChange(event, "title_en")} />
          </div>
        </div>

        <Space number={3} />
        <div className="row fbrito-block-padding" style={{ marginTop: "70px !important" }}>
          <div className="col-sm p-2"><div onClick={goBack} className="fbrito-btn">Voltar</div></div>
          <div className="col-sm p-2">
            <div className="fbrito-btn" onClick={addSection}>Adicionar</div>
            {addSectionMessage ? <span>{addSectionMessage}</span> : null}
          </div>
          <div className="col-sm p-2"></div>
        </div>
      </form>
      <Space number={3} />
    </div>
  );
}

export default AdminSectionAdd;




