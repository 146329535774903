import React, { useState, useEffect } from 'react';
import api from '../../api';
import Loader from '../../components/Loader';
import Space from '../../components/Space';
import { getInputClass } from '../../utils/StringUtils';

const AdminBlogEdit = ({ adminIsLoggedIn }) => {
  const [state, setState] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    
    // Redirect to admin login if admin is not logged in
    if (!adminIsLoggedIn) {
      window.location.href = "/4HY=e5T}_9}UkN";
    } else {
      setState({ loading: true });

      api.getAboutPage({}).then(res => {
        if(res && res.data && res.data.length) {
          setState({
            text1: res.data[0].text1 || '', text1_en: res.data[0].text1_en || '',
            text2: res.data[0].text2 || '', text2_en: res.data[0].text2_en || '',
            text3: res.data[0].text3 || '', text3_en: res.data[0].text3_en || '',
            text4: res.data[0].text4 || '', text4_en: res.data[0].text4_en || '',
            text5: res.data[0].text5 || '', text5_en: res.data[0].text5_en || '',
            quote: res.data[0].quote || '', quote_en: res.data[0].quote_en || '',
            loading: false });
        } else {
          setState({ loading: false });
        }
      }).catch(() => {
        setState({ loading: false });
      });
    }
  }, [])

  /**
   * Handle dynamic inputs in state
   * @param {*} event - Current element clicked
   * @param {*} name - Corresponding name of the current element clicked
   */
	const handleChange = (event, name) => {
    const stateAux = { ...state };
    stateAux[name] = event.target.value;
    setState({ ...stateAux });
  }

  /**
   * Redirect to edit about page information
   */
  const save = () => {
		const { text1, text1_en, text2, text2_en, text3, text3_en, text4, text4_en, text5, text5_en, quote, quote_en } = state;
	
		let text1Err, text1EnErr, text2Err, text2EnErr, text3Err, text3EnErr, text4Err, text4EnErr, text5Err, text5EnErr,
			quoteErr, quoteEnErr, updateAboutMessage = false;

		if (text1 && text1_en && text2 && text2_en && text3 && text3_en && text4 && text4_en && text5 && text5_en && quote && quote_en) {
			const data = { "id": "1", "text1": text1, "text1_en": text1_en, "text2": text2, "text2_en": text2_en,
				"text3": text3, "text3_en": text3_en, "text4": text4, "text4_en": text4_en,
				"text5": text5, "text5_en": text5_en, "quote": quote,"quote_en": quote_en }

			setState({ ...state, text1Err, text1EnErr, text2Err, text2EnErr, text3Err, text3EnErr, text4Err, text4EnErr, 
				text5Err, text5EnErr, quoteErr, quoteEnErr, updateAboutMessage, loading: true });

			api.editAboutPage(data).then(res => {
				if(res && res.data) {
					updateAboutMessage = "Atualizado com sucesso.";
					setState({
						...state, text1Err, text1EnErr, text2Err, text2EnErr, text3Err, text3EnErr, text4Err, text4EnErr, 
						text5Err, text5EnErr, quoteErr, quoteEnErr, updateAboutMessage, loading: false });
				} else {
					updateAboutMessage = "Não foi possível atualizar.";
					setState({ ...state, text1Err, text1EnErr, text2Err, text2EnErr, text3Err, text3EnErr, text4Err, text4EnErr, 
						text5Err, text5EnErr, quoteErr, quoteEnErr, updateAboutMessage, loading: false });
				}
			}).catch(() => {
				updateAboutMessage = "Não foi possível atualizar.";
				setState({ ...state, text1Err, text1EnErr, text2Err, text2EnErr, text3Err, text3EnErr, text4Err, text4EnErr, 
					text5Err, text5EnErr, quoteErr, quoteEnErr, updateAboutMessage, loading: false });
			});
		} else {
			if (!text1) { text1Err = "Por favor insira texto." }
			if (!text2) { text2Err = "Por favor insira texto." }
			if (!text3) { text3Err = "Por favor insira texto." }
			if (!text4) { text4Err = "Por favor insira texto." }
			if (!text5) { text5Err = "Por favor insira texto." }
			if (!quote) { quoteErr = "Por favor insira citação." }
			if (!text1_en) { text1EnErr = "Por favor insira texto em inglês." }
			if (!text2_en) { text2EnErr = "Por favor insira texto em inglês." }
			if (!text3_en) { text3EnErr = "Por favor insira texto em inglês." }
			if (!text4_en) { text4EnErr = "Por favor insira texto em inglês." }
			if (!text5_en) { text5EnErr = "Por favor insira texto em inglês." }
			if (!quote_en) { quoteEnErr = "Por favor insira citação em inglês." }

			updateAboutMessage = "Não foi possível atualizar.";
			setState({ ...state, text1Err, text1EnErr, text2Err, text2EnErr, text3Err, text3EnErr, text4Err, text4EnErr, 
				text5Err, text5EnErr, quoteErr, quoteEnErr, updateAboutMessage });
		}
  }

  const { loading, text1, text1_en, text2, text2_en, text3, text3_en, text4, text4_en, text5, text5_en, quote, quote_en,
		text1Err, text1EnErr, text2Err, text2EnErr, text3Err, text3EnErr, text4Err, text4EnErr, text5Err, text5EnErr,
		quoteErr, quoteEnErr, updateAboutMessage} = state;

  return (
    <div className="fbrito-page fbrito-admin-about">
      <Loader loading={loading} />

      <div className="fbrito-page-title">Sobre Mim | Editar</div>

			<form>
				<div className="row fbrito-block-padding">
					<div className="col-md p-2">
						<label htmlFor="text1" className="form-label">Texto 1 <span className="fbrito-input-error">*</span></label>
						<textarea  rows="5" type="text" placeholder="Insira um texto" name="text1" id="text1" className={getInputClass(text1Err)} value={text1 || ''} onChange={(event) => handleChange(event, "text1")} />
						{text1Err ? <span className="fbrito-input-error">{text1Err}</span> : null}
					</div>
					<div className="col-md p-2">
						<label htmlFor="text1-en" className="form-label">Texto 1 - Inglês <span className="fbrito-input-error">*</span></label>
						<textarea  rows="5" type="text" placeholder="Insira um texto em inglês" name="text1-en" id="text1-en" className={getInputClass(text1EnErr)} value={text1_en || ''} onChange={(event) => handleChange(event, "text1_en")} />
						{text1EnErr ? <span className="fbrito-input-error">{text1EnErr}</span> : null}
					</div>
				</div>

				<br />
				<div className="row fbrito-block-padding">
					<div className="col-md p-2">
						<label htmlFor="text2" className="form-label">Texto 2 <span className="fbrito-input-error">*</span></label>
						<textarea  rows="5" type="text" placeholder="Insira um texto" name="text2" id="text2" className={getInputClass(text2Err)} value={text2 || ''} onChange={(event) => handleChange(event, "text2")} />
						{text2Err ? <span className="fbrito-input-error">{text2Err}</span> : null}
					</div>
					<div className="col-md p-2">
						<label htmlFor="text2-en" className="form-label">Texto 2 - Inglês <span className="fbrito-input-error">*</span></label>
						<textarea  rows="5" type="text" placeholder="Insira um texto em inglês" name="text2-en" id="text2-en" className={getInputClass(text2EnErr)} value={text2_en || ''} onChange={(event) => handleChange(event, "text2_en")} />
						{text2EnErr ? <span className="fbrito-input-error">{text2EnErr}</span> : null}
					</div>
				</div>

				<br />
				<div className="row fbrito-block-padding">
					<div className="col-md p-2">
						<label htmlFor="text3" className="form-label">Texto 3 <span className="fbrito-input-error">*</span></label>
						<textarea  rows="5" type="text" placeholder="Insira um texto" name="text3" id="text3" className={getInputClass(text3Err)} value={text3 || ''} onChange={(event) => handleChange(event, "text3")} />
						{text3Err ? <span className="fbrito-input-error">{text3Err}</span> : null}
					</div>
					<div className="col-md p-2">
						<label htmlFor="text3-en" className="form-label">Texto 3 - Inglês <span className="fbrito-input-error">*</span></label>
						<textarea  rows="5" type="text" placeholder="Insira um texto em inglês" name="text3-en" id="text3-en" className={getInputClass(text3EnErr)} value={text3_en || ''} onChange={(event) => handleChange(event, "text3_en")} />
						{text3EnErr ? <span className="fbrito-input-error">{text3EnErr}</span> : null}
					</div>
				</div>

				<br />
				<div className="row fbrito-block-padding">
					<div className="col-md p-2">
						<label htmlFor="text4" className="form-label">Texto 4 <span className="fbrito-input-error">*</span></label>
						<textarea  rows="5" type="text" placeholder="Insira um texto" name="text4" id="text4" className={getInputClass(text4Err)} value={text4 || ''} onChange={(event) => handleChange(event, "text4")} />
						{text4Err ? <span className="fbrito-input-error">{text4Err}</span> : null}
					</div>
					<div className="col-md p-2">
						<label htmlFor="text4-en" className="form-label">Texto 4 - Inglês <span className="fbrito-input-error">*</span></label>
						<textarea  rows="5" type="text" placeholder="Insira um texto em inglês" name="text4-en" id="text4-en" className={getInputClass(text4EnErr)} value={text4_en || ''} onChange={(event) => handleChange(event, "text4_en")} />
						{text4EnErr ? <span className="fbrito-input-error">{text4EnErr}</span> : null}
					</div>
				</div>

				<br />
				<div className="row fbrito-block-padding">
					<div className="col-md p-2">
						<label htmlFor="text5" className="form-label">Texto 5 <span className="fbrito-input-error">*</span></label>
						<textarea  rows="5" type="text" placeholder="Insira um texto" name="text5" id="text5" className={getInputClass(text5Err)} value={text5 || ''} onChange={(event) => handleChange(event, "text5")} />
						{text5Err ? <span className="fbrito-input-error">{text5Err}</span> : null}
					</div>
					<div className="col-md p-2">
						<label htmlFor="text5-en" className="form-label">Texto 5 - Inglês <span className="fbrito-input-error">*</span></label>
						<textarea  rows="5" type="text" placeholder="Insira um texto em inglês" name="text5-en" id="text5-en" className={getInputClass(text5EnErr)} value={text5_en || ''} onChange={(event) => handleChange(event, "text5_en")} />
						{text5EnErr ? <span className="fbrito-input-error">{text5EnErr}</span> : null}
					</div>
				</div>

				<br />
				<div className="row fbrito-block-padding">
					<div className="col-md p-2">
						<label htmlFor="quote" className="form-label">Citação <span className="fbrito-input-error">*</span></label>
						<textarea  rows="5" type="text" placeholder="Insira um citação" name="quote" id="quote" className={getInputClass(quoteErr)} value={quote || ''} onChange={(event) => handleChange(event, "quote")} />
						{quoteErr ? <span className="fbrito-input-error">{quoteErr}</span> : null}
					</div>
					<div className="col-md p-2">
						<label htmlFor="quote-en" className="form-label">Citação - Inglês <span className="fbrito-input-error">*</span></label>
						<textarea  rows="5" type="text" placeholder="Insira uma citação em inglês" name="quote-en" id="quote-en" className={getInputClass(quoteEnErr)} value={quote_en || ''} onChange={(event) => handleChange(event, "quote_en")} />
						{quoteEnErr ? <span className="fbrito-input-error">{quoteEnErr}</span> : null}
					</div>
				</div>

		<Space number={3} />
        <div className="row fbrito-block-padding" style={{ marginTop: "70px !important" }}>
          <div className="col-sm p-2"><a href="admin/sobre" className="fbrito-btn">Voltar</a></div>
          <div className="col-sm p-2">
            <div className="fbrito-btn" onClick={save}>Guardar</div>
            {updateAboutMessage ? <div>{updateAboutMessage}</div> : null}
          </div>
					<div className="col-sm p-2"></div>
        </div>
      </form>

	  <Space number={3} />
    </div>
  );
}

export default AdminBlogEdit;
