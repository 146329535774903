import React from 'react';
import FBritoCat1 from '../assets/imgs/categories/fbrito-cat-1.svg';
import FBritoCat2 from '../assets/imgs/categories/fbrito-cat-2.svg';
import FBritoCat3 from '../assets/imgs/categories/fbrito-cat-3.svg';
import { getTranslations } from '../utils/LanguageUtils';

const CategoriesBlock = () => (
  <div className="row fbrito-categories-block">
    <div className="col-sm-12 col-md-4 text-center">
      <div><img src={FBritoCat1} alt={getTranslations().components.categoriesBlock.alt1} /></div>
      <div className="fbrito-page-subtitle">{getTranslations().components.categoriesBlock.title1}</div>
      <div className="fbrito-page-subtitle-01">{getTranslations().components.categoriesBlock.title1V1}<br />{getTranslations().components.categoriesBlock.title1V2}</div>
      <div className="fbrito-category-descrption-00">{getTranslations().components.categoriesBlock.description1}</div>
      <div className="fbrito-center">
        <a href="yoga" className="fbrito-btn">{getTranslations().components.categoriesBlock.button}</a>
      </div>
    </div>

    <div className="col-sm-12 col-md-4 text-center fbrito-category-design">
      <div><img src={FBritoCat2} alt={getTranslations().components.categoriesBlock.alt2} /></div>
      <div className="fbrito-page-subtitle">{getTranslations().components.categoriesBlock.title2}</div>
      <div className="fbrito-page-subtitle-01">{getTranslations().components.categoriesBlock.title2V1}</div>
      {/* <div className="fbrito-page-subtitle-01">{getTranslations().components.categoriesBlock.title2V1}<br />{getTranslations().components.categoriesBlock.title2V2}</div> */}
      <div className="fbrito-category-descrption-00">{getTranslations().components.categoriesBlock.description2}</div>
      <div className="fbrito-center">
        <a href="design" className="fbrito-btn">{getTranslations().components.categoriesBlock.button}</a>
      </div>
    </div>

    <div className="col-sm-12 col-md-4 text-center">
      <div><img src={FBritoCat3} alt={getTranslations().components.categoriesBlock.alt3}/></div>
      <div className="fbrito-page-subtitle">{getTranslations().components.categoriesBlock.title3}</div>
      <div className="fbrito-page-subtitle-01">{getTranslations().components.categoriesBlock.title3V1}</div>
      {/* <div className="fbrito-page-subtitle-01">{getTranslations().components.categoriesBlock.title3V1}<br />{getTranslations().components.categoriesBlock.title3V2}</div> */}
      <div className="fbrito-category-descrption-00">{getTranslations().components.categoriesBlock.description3}</div>
      <div className="fbrito-center">
        <a href="actividades" className="fbrito-btn">{getTranslations().components.categoriesBlock.button}</a>
      </div>
    </div> 
  </div>);

export default CategoriesBlock;
