export const translationsPT = {
  screens: {
    public: {
      about: {
        title: "Sobre Mim",
        contactBtn: "Contactar",
        modalTitle: "Francisco Brito",
      },
      activities: {
        title: "Sustentabilidade",
        schedule: "Horário Semanal",
        contactBtn: "Contactar",
        modalTitle: "Francisco Brito",
      },
      contacts: {
        title: "Contactos",
        intro: "Olá! Como posso ser útil?",
        imgAlt: "Foto do Francisco Brito"
      },
      design: {
        title: "Life Design",
        portfolio: "Portfolio",
        button: "Ver Portfolio",
        modalTitle: "Francisco Brito",
      },
      homepage: {
        intro1V1: "O meu propósito de vida e de trabalho é contribuir para",
        intro1V2: "o florescimento humano e para o desenvolvimento sustentável.",
        intro2V1: "O meu propósito de vida e de trabalho",
        intro2V2: "é contribuir para o florescimento humano",
        intro2V3: "e para o desenvolvimento sustentável.",
        intro3V1: "O meu propósito de vida e de",
        intro3V2: "trabalho é contribuir para",
        intro3V3: "o florescimento humano e para",
        intro3V4: "o desenvolvimento sustentável.",
        highlight: "Em Destaque",
        aboutMe: "Sobre Mim",
        moreBtn: "Saber mais",
        schedule: "Horário Semanal",
        contactBtn: "Contactar",
        blog: "Blog",
        modalTitle: "Francisco Brito"
      },
      schedule: {
        title: "Horário Semanal",
        button: "Contactar",
      },
      service: {
        share: "Partilhar",
        noResult: "Serviço não encontrado...",
        modalTitle: "Francisco Brito",
        gallery: "Galeria de Imagens",
        slider: "Slider de Imagens",
        quotes: "Citações / Recomendações"
      },
      yoga: {
        title: "Yoga / Meditação",
        quotes: "Citações",
        schedule: "Horário Semanal",
        button: "Contactar",
        modalTitle: "Francisco Brito"
      }
    },
    notFound: {
      title: "Página Não Encontrada",
      description: "Mas pode saber mais sobre o que faço a baixo",
      homepageLink: "Página Principal"
    }
  },
  components: {
    aboutHeader: {
      imgAlt: "Foto do Francisco Brito"
    },
    categoriesBlock: {
      alt1: "Categoria 1 - Yoga", 
      title1: "Yoga / Meditação",
      title1V1: "Yoga / ",
      title1V2: "Meditação",
      description1: "Dou aulas de grupo e aulas personalizadas de yoga e meditação para o florescimento físico, mental e espiritual.",
      alt2: "Categoria 2 - UX / Web Design / Branding", 
      title2: "Life Design",
      title2V1: "Life Design",
      title2V2: "",
      description2: "Facilito workshops de design thinking, faço logótipos, websites, pinturas murais e outros serviços de design e arte.",
      alt3: "Categoria 3 - Sustentabilidade", 
      title3: "Sustentabilidade",
      title3V1: "Sustentabilidade",
      title3V2: "",
      description3: "Organizo e facilito eventos de desenvolvimento pessoal e cidadania que integram métodos criativos e de diálogo.",
      button: "Saber Mais"
    },
    footer: {
      websiteName: "Francisco Brito",
      rights: " Francisco Brito. Todos os direitos reservados.",
      emailPlaceholder: "Email",
      subscribe: "Subscrever",
      subscriptionSuccess: "Subscrição efectuada! ",
      subscriptionFailed: "Ocorreu um erro! Por favor tente novamente. "
    },
    loader: {
      message: "A carregar conteúdos... ",
    },
    menu: {
      websiteName: "FRANCISCO BRITO",
      open: "Menu",
      close: "voltar",
      currentLanguage: "PT",
      about: "Sobre",
      schedule: "Horário",
      yoga: "Yoga",
      design: "Design",
      activities: "Sustentabilidade",
      blog: "Blog",
      contacts: "Contactos"
    },
    scheduleCard: {
      noResult: "Horários ainda a definir..."
    },
    serviceCard: {
      noResult: "Ainda sem serviços..."
    }
  }
}