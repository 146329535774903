import React, { Component } from 'react';
import api from '../../api';
import Space from '../../components/Space';
import { getInputClass } from '../../utils/StringUtils';
import Loader from './../../components/Loader';

class AdminQuoteEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { adminIsLoggedIn } = this.props;

    window.scrollTo(0, 0);

    // Redirect to admin login if admin is not logged in
    if (!adminIsLoggedIn) {
      window.location.href = "/4HY=e5T}_9}UkN";
    } else {

      const urlArray = document.location.href.split('/');
      let id = null;
      try {
        id = parseInt(urlArray[6]);
        if (id) {
          // Get service id if exists
          const urlParams = new URLSearchParams(window.location.search);
          const serviceId = urlParams.get('servico') || false;
          this.setState({ serviceId });

          this.getQuoteData(id)
        }
      } catch(e) {
        window.location.href = "/4HY=e5T}_9}UkN";
      }
    }
  }

  /**
   * Fetch quote data with given quote id
   * @param {*} id - Quote id
   */
  getQuoteData = (id) => {
    this.setState({ loading: true });
    const data = { "id": id };
    api.getQuoteById(data).then(res => {
      if(res && res.data && res.data.length) {
        this.setState({
          id: parseInt(res.data[0].id),
          text: res.data[0].text,
          text_en: res.data[0].text_en,
          author: res.data[0].author,
          related_code: res.data[0].related_code,
          loading: false
        });
      } else {
        this.setState({ loading: false });
      }
    }).catch(() => {
      this.setState({ loading: false });
    });
  }

  /**
   * Handle dynamic inputs in state
   * @param {*} event - Current element clicked
   * @param {*} name - Corresponding name of the current element clicked
   */
  handleChange(event, name) {
    const stateAux = { ...this.state };
    stateAux[name] = event.target.value;
    this.setState({ ...stateAux });
  }

  /**
   * Delete quote by calling api
   */
  delete = () => {
    const { id, serviceId} = this.state;
    let deleteQuoteMessage = false;

    if(id) {
      const data = { "id": id };
      this.setState({ loading: true });
      api.deleteQuote(data).then(res => {
        if(res && res.data) {
          this.setState({ loading: false });
          if (serviceId) {
            window.location.href = "/admin/servico/editar/" + serviceId;
          } else {
            window.location.href = "/admin/citacoes";
          }
        } else {
          deleteQuoteMessage = "Não foi possível eliminar.";
          this.setState({ deleteQuoteMessage, loading: false });
        }
      }).catch(() => {
        deleteQuoteMessage = "Não foi possível eliminar.";
        this.setState({ deleteQuoteMessage, loading: false });
      });      
    } else {
      deleteQuoteMessage = "Não foi possível eliminar.";
      this.setState({ deleteQuoteMessage });
    }
  }

  /**
   * Update quote by calling api
   */
  save = () => {
    const { id, text, author, text_en, related_code } = this.state;
    let updateQuoteMessage, textErr, authorErr, textEnErr = false;

    if (id && text && author && related_code) {
      const data = { "id": id, "text": text, "author": author, "text_en": text_en, "related_code": related_code };
      this.setState({ loading: true });
      api.updateQuote(data).then(res => {
        if(res && res.data) {
          updateQuoteMessage = "Atualizado com sucesso";
          this.setState({ updateQuoteMessage, loading: false });
        } else {
          updateQuoteMessage = "Não foi possível atualizar.";
          this.setState({ updateQuoteMessage, loading: false });
        }
      }).catch(() => {
        updateQuoteMessage = "Não foi possível atualizar.";
        this.setState({ updateQuoteMessage, loading: false });
      });      
    } else {
      if (!text) { textErr = "Por favor indique o texto." }
      if (!author) { authorErr = "Por favor indique o autor." }
      if (!text_en) { textEnErr = "Por favor indique o texto." }
      if (!text && !author && !text_en ) { updateQuoteMessage = "Não foi possível atualizar." }
      this.setState({ textErr, authorErr, textEnErr, updateQuoteMessage });
    }
  }

  /**
   * Go back to the previous page
   */
  goBack = () => {
    const { serviceId } = this.state;
    if (serviceId) {
      // Get blog param if exists
      const urlParams = new URLSearchParams(window.location.search);
      const blog = urlParams.get('blog') || false;
      
      window.location.href = blog ? ("/admin/blog/editar/" + serviceId) : ("/admin/servico/editar/" + serviceId);
    } else {
      window.location.href = "/admin/citacoes";
    }
  }

  render() {
    const { textErr, authorErr, textEnErr, text, text_en, author, loading, updateQuoteMessage, deleteQuoteMessage } = this.state;

    return ( 
      <div className="fbrito-page fbrito-admin-quotes fbrito-bg-grey">
        <Loader loading={loading} />
        <div className="fbrito-page-title">Editar Citação / Recomendação</div>
        <form>
          <div className="row fbrito-block-padding">
            <div className="col-md p-2">
              <label htmlFor="text" className="form-label">Frase</label>
              <textarea rows="5" type="text" placeholder="Insira uma frase" name="text" id="text" className={getInputClass(textErr)} value={text || ""} onChange={(event) => this.handleChange(event, "text")} />
              {textErr ? <span className="fbrito-input-error">{textErr}</span> : null}
            </div>
          </div>

          <br />
          <div className="row fbrito-block-padding">
            <div className="col-md p-2">
              <label htmlFor="text-en" className="form-label">Frase - Inglês</label>
              <textarea rows="5" type="text" placeholder="Insira uma frase em inglês" name="text-en" id="text-en" className={getInputClass(textEnErr)} value={text_en || ""} onChange={(event) => this.handleChange(event, "text_en")} />
              {textEnErr ? <span className="fbrito-input-error">{textErr}</span> : null}
            </div>
          </div>

          <br />
          <div className="row fbrito-block-padding">
            <div className="col-md p-2">
              <label htmlFor="author" className="form-label">Autor</label>
              <input type="text" placeholder="Insira um autor" name="author" id="author" className={getInputClass(authorErr)} value={author} onChange={(event) => this.handleChange(event, "author")} />
              {authorErr ? <span className="fbrito-input-error">{authorErr}</span> : null}
            </div>
          </div>

          <Space number={3} />
          <div className="row fbrito-block-padding">
            <div className="col-sm p-2"><div onClick={this.goBack} className="fbrito-btn">Voltar</div></div>
            <div className="col-sm p-2">
              <div name="save" className="fbrito-btn" onClick={this.save}>Guardar</div>
              {updateQuoteMessage ? (<div>{updateQuoteMessage}</div>): null}
            </div>
            <div className="col-sm p-2">
              <div name="delete" className="fbrito-btn fbrito-btn--red" onClick={this.delete}>Eliminar</div>
              {deleteQuoteMessage ? <div>{deleteQuoteMessage}</div>: null}
            </div>
          </div>
          
        </form>
        <Space number={3} />
      </div>
    );
  }
}

export default AdminQuoteEdit;