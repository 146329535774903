/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { beUrl } from '../utils/ConstantsUtils';

const apiClient = axios.create({
  baseURL: `${beUrl}/fbrito-api`,
  timeout: 30 * 1000,
  headers: { 
    'Content-Type': 'text/plain'
  }
});

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => Promise.reject(error));

function post(url, payload = false) {
  return apiClient.post(url, payload)
    .then((response) => {
      if (response.data) {
        return response.data;
      }

      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export {
  post,
};
