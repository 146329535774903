import React from 'react';
import { fbritoInfo } from '../../utils/ConstantsUtils';
import { getTranslations } from '../../utils/LanguageUtils';
import FBritoAbout01 from '../../assets/imgs/fbrito-about-01.svg';
import Footer from '../../components/Footer';
import Space from '../../components/Space';

const Contacts = () => {
  return (
    <div>
      <div className="fbrito-page fbrito-contacts-page fbrito-about fbrito-bg-grey">
        <div className="fbrito-page-title">{getTranslations().screens.public.contacts.title}</div>

        <div className="fbrito-about-00">
          <div className="fbrito-about-00-1">
            <div className="fbrito-about-01"><img src={FBritoAbout01} alt={getTranslations().screens.public.contacts.imgAlt}/></div>
            <div className="fbrito-about-02 fbrito-about-02--text-c">
              <div className="fbrito-about-02-1">
                <div className="fbrito-about-02-1-1">{getTranslations().screens.public.contacts.intro}</div>
                <div>{fbritoInfo.number}</div>
                <div>{fbritoInfo.email}</div>
              </div>
            </div>
          </div>
        </div>

        <Space number={5} />
        <Footer />
      </div>
    </div>
  );
}

export default Contacts;
