import React, { useState, useEffect } from 'react';
import api from '../../api';
import Footer from '../../components/Footer';
import Loader from '../../components/Loader';
import Space from '../../components/Space';
import { getInputClass } from '../../utils/StringUtils';

const AdminChangePassword = ({ adminIsLoggedIn }) => {
  const initState = {
    password: '',
    passwordConfirm: ''
  };

  const [state, setState] = useState(initState)

  useEffect(() => {
    window.scrollTo(0, 0);
    
    // Redirect to admin login if admin is not logged in
    if (!adminIsLoggedIn) {
        window.location.href = "/4HY=e5T}_9}UkN";
    }
  }, [])

  /**
   * Handle dynamic inputs in state
   * @param {*} event - Current element clicked
   * @param {*} name - Corresponding name of the current element clicked
   */
  const handleChange = (event, name) => {
    const stateAux = { ...state };
    stateAux[name] = event.target.value;
    setState({ ...stateAux });
  }

  /**
   * Change password by calling the api
   */
  const changePassword = () => {
    const { password, passwordConfirm } = state;
    let passwordErr, passwordConfirmErr, errorMessage = false;

    setState({ ...state, loading: true });

    if (password && passwordConfirm) {
 
      if (password !== passwordConfirm) { 
        errorMessage = "Senhas difentes. Por favor confirme a senha.";
        setState({ ...state, errorMessage, loading: false });
      } else {
        const username = localStorage.getItem("username");
        let data = { "username": username, "password": password };

        api.adminChangePassword(data).then(res => {
          if(res &&  res.data) {
            window.location.href = "/admin";
          } else {
            errorMessage = "Não foi possível alerar senha. Por favor tente novamente.";
            setState({ ...state, errorMessage, loading: false })
          }
          
        }).catch(() => {
          errorMessage = "Não foi possível  alerar senha. Por favor tente novamente." 
          setState({ ...state, errorMessage, loading: false });
        });
      }

    } else {
      if (!password) { passwordErr = "Por favor indique a nova sennha." }
      if (!passwordConfirm) { passwordConfirmErr = "Por favor confirme a nova senha." }

      setState({ ...state, passwordErr, passwordConfirmErr, errorMessage, loading: false});
    }
  }

  const { password, passwordConfirm, passwordErr, passwordConfirmErr, errorMessage, loading } = state;

  return (
    <div className="fbrito-page fbrito-admin-home fbrito-bg-grey">
      <Loader loading={loading} />
      <div className="fbrito-page-title">Admin | Alterar senha</div>
      <div className="fbrito-block-padding fbrito-center text-center fbrito-description">
        O meu propósito de vida e de trabalho é <br />
        contribuir para o florescimento humano <br />
        e para o desenvolvimento sustentável.</div>
      <br />
      <div className="row fbrito-block-padding">
        <div className="col-sm-4"></div>
        <div className="cols-sm-4 fbrito-center">
          <form>
            <div className="form-group">
              <input placeholder="Nova senha" type="password" name="password" className={getInputClass(passwordErr)} value={password} onChange={(event) => handleChange(event, "password")} />
              {passwordErr ? <span className="fbrito-input-error">{passwordErr}</span> : null}
            </div>    
            <div className="form-group">
              <input placeholder="Confirmar nova senha" type="password" name="passwordConfirm" className={getInputClass(passwordConfirmErr)} value={passwordConfirm} onChange={(event) => handleChange(event, "passwordConfirm")} />
              {passwordConfirmErr ? <span className="fbrito-input-error">{passwordConfirmErr}</span> : null}
            </div>
            <br />
            <div className="fbrito-center">
              <div className="fbrito-btn" onClick={changePassword}>Alterar</div>
            </div>
            <div className="fbrito-center">
              {errorMessage ? <span className="fbrito-input-error">{errorMessage}</span> : null}
            </div>
          </form>
        </div>
        <div className="col-sm-4"></div>
      </div>
      <Space number={3} />
      
      {/* Footer */}
      <Footer />
      {/* End - Footer */}
    </div>);
}

export default AdminChangePassword;
