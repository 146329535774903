import React from 'react';
import { Spinner } from 'react-bootstrap';
import { getTranslations } from '../utils/LanguageUtils';

const Loader = (props) => {
  const { loading, homepageStyle } = props;

  return (
    loading ? (
      <div className="fbrito-loader fbrito-bg-grey" style={homepageStyle ? {marginTop: '74px'} : {}}>
        <Spinner animation="border" variant="secondary" />
        <div>{getTranslations().components.loader.message}</div>
      </div>) : null
  );
}

export default Loader;
