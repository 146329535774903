import React, { Component } from 'react';
import api from '../api';
import { getLanguage, getTranslations } from '../utils/LanguageUtils';
import Loader from './Loader';

class ScheduleCard extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.setState({ loading: true });

    const data = { "language": getLanguage() };

    api.getScheduleAll(data).then(res => {
      if(res && res.data) {
          this.setState({ schedule: res.data, loading: false });
      } else {
        this.setState({ loading: false });
      }
    }).catch(() => {
      this.setState({ loading: false });
    });
  }

  render() {
    const { loading, schedule } = this.state;

    return (
      <div className="row fbrito-row-responsive">
        <Loader loading={loading} />

        {schedule && schedule.length ? (
          schedule.map(item => (
            <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center" key={item.id}>
              <div className='fbrito-schedule-card'>
                <div className={"fbrito-schedule-card__title fbrito-cat-"+item.category_code} >{item.title}</div>
                <div className="fbrito-schedule-card__daterange">{item.date_range}</div>
                <div>{item.where}</div>
              </div>
            </div>)))  : (<div className='col-12'>{getTranslations().components.scheduleCard.noResult}</div>)}

      </div>);
  }
}

export default ScheduleCard;
