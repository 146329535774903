import React from 'react';
import ServiceCard from '../../components/ServiceCard';
import Space from '../../components/Space';
import { categoryType } from '../../utils/ConstantsUtils';

const Blog = () => {
  return (
    <div className="fbrito-page fbrito-blog">
      <div className="fbrito-bg-grey">
        <div className="fbrito-page-title">Blog</div>
        <div className="fbrito-block-padding fbrito-center">
          <div className="row fbrito-row-responsive">
            <ServiceCard categoryId={categoryType.blogId} />
          </div>
        </div>
        <Space number={2} />
      </div>
    </div>
  );
}

export default Blog;