import React, { Component } from 'react';
import api from '../../api';
import Footer from '../../components/Footer';
import Loader from '../../components/Loader';
import Space from '../../components/Space';

class AdminLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: ''
    };
  }

  componentDidMount() {
    const { adminIsLoggedIn } = this.props;

    window.scrollTo(0, 0);

    // Redirect to admin homepage if admin is logged in
    if (adminIsLoggedIn) { window.location.href = "/admin"; }
  }

  /**
   * Handle dynamic inputs in state
   * @param {*} event - Current element clicked
   * @param {*} name - Corresponding name of the current element clicked
   */
   handleChange(event, name) {
    const stateAux = { ...this.state };
    stateAux[name] = event.target.value;
    this.setState({ ...stateAux });
  }

  /**
   * Login with username and password by calling the api
   */
  login = () => {
    const { username, password } = this.state;

    let usernameErr = false;
    let passwordErr = false;
    let errorMessage = false;

    this.setState({ loading: true });

    if (username && password) {
      // Older pass "$2y$10$JKkjP5DABsXaXvTbR.cNzup.MqRgtCYvh.AwrTPcWTWdzcR5L7yGK"

      let data = { "username": username, "password": password };

      api.adminLogin(data).then(res => {
        if(res &&  res.data) {
          localStorage.setItem("username", username);
          localStorage.setItem("adminIsLoggedIn", res.data);
          window.location.href = "/admin";
        } else {
          errorMessage = "Não foi possível efetuar o login. Por favor tente novamente." 
        }
        this.setState({ errorMessage, loading: false })
      }).catch(() => {
        errorMessage = "Não foi possível efetuar o login. Por favor tente novamente." 
        this.setState({ errorMessage, loading: false });
      });
    } else {
      if (!username) { usernameErr = "Por favor indique o nome de utilizador." }
      if (!password) { passwordErr = "Por favor indique a senha." }
      this.setState({ usernameErr, passwordErr, errorMessage, loading: false});
    }
  }

  render() {
    const { username, password, usernameErr, passwordErr, errorMessage, loading } = this.state;

    return (
      <div className="fbrito-page fbrito-admin-home fbrito-bg-grey">
        <Loader loading={loading} />
        <div className="fbrito-page-title">Admin</div>
        <div className="fbrito-block-padding fbrito-center text-center fbrito-description">
          O meu propósito de vida e de trabalho é <br />
          contribuir para o florescimento humano <br />
          e para o desenvolvimento sustentável.</div>
        <br />
        <div className="row fbrito-block-padding">
          <div className="col-sm-4"></div>
          <div className="cols-sm-4 fbrito-center">
            <form>
              <div className="form-group">
                <input placeholder="Nome" type="text" name="username" className={usernameErr ? "form-control" : "form-control fbrito-has-error"} value={username} onChange={(event) => this.handleChange(event, "username")} />
                {usernameErr ? <span className="fbrito-input-error">{usernameErr}</span> : null}
              </div>    
              <div className="form-group">
                <input placeholder="Senha" type="password" name="password" className={passwordErr ? "form-control" : "form-control fbrito-has-error"} value={password} onChange={(event) => this.handleChange(event, "password")} />
                {passwordErr ? <span className="fbrito-input-error">{passwordErr}</span> : null}
              </div>
              <br />
              <div className="fbrito-center">
                <div className="fbrito-btn" onClick={this.login}>Entrar</div>
              </div>
              <div className="fbrito-center">
                {errorMessage ? <span className="fbrito-input-error">{errorMessage}</span> : null}
              </div>
            </form>
          </div>
          <div className="col-sm-4"></div>
        </div>
        <Space number={3} />
        
        {/* Footer */}
        <Footer />
        {/* End - Footer */}
      </div>);
  }
}

export default AdminLogin;
