import React, { useState, useEffect } from 'react';
import api from '../../api';
import Loader from '../../components/Loader';
import Space from '../../components/Space';

const AdminBlog = ({ adminIsLoggedIn }) => {
  const [state, setState] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    
    // Redirect to admin login if admin is not logged in
    if (!adminIsLoggedIn) {
      window.location.href = "/4HY=e5T}_9}UkN";
    } else {
      setState({ loading: true });

      const data = { type: "blog" }
      api.getServicesAll(data).then(res => {
        if(res && res.data) {
          setState({ services: res.data, loading: false });
        } else {
          setState({ loading: false });
        }
      }).catch(() => {
        setState({ loading: false });
      });
    }
  }, [])

  /**
   * Redirect to edit service page with given service id
   * @param {*} id - Id of clicked service
   */
  const editService = (id) => {
    window.location.href = "/admin/blog/editar/" + id;
  }

  const { loading, services } = state;

  return (
    <div className="fbrito-page fbrito-admin-services fbrito-bg-grey">
      <Loader loading={loading} />

      <div className="fbrito-page-title">Blog</div>

      <h3>Lista de publicações (<a href="admin/blog/adicionar">Adicionar</a>)</h3>

      {services && services.length ? (
        <React.Fragment>
          <div className="row">
            <div className="col-3">Ordem</div>
            <div className="col-3">Destaque</div>
            <div className="col-3">Nome</div>
            <div className="col-3"></div>
          </div>

          {services.map((item, i) => (
            <div key={"text" + i} className="row">
              <div className="col-3">{item.service_order || '-'}</div>
              <div className="col-3">{item.highlight | '-'}</div>
              <div className="col-3">{item.name}</div>
              <div className="col-3">
                <div className="fbrito-btn-admin" onClick={() => editService(item.id)}>Editar</div>
              </div>
            </div>))}
          </React.Fragment>) : (<div>Ainda não existem publicações.</div>)}
        
        <Space number={2} />
    </div>
  );
}

export default AdminBlog;