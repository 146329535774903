/**
 * Replaces double quotes from text to single quote
 * @param {String} text - Text that contains double quote in between
 */
 const doubleToSingleQuote = (text) => {
  return text.replace(/"/g, "'");
}

/**
 * Get color in portuguese by color in hexadecimal
 * @param {String} colorHex - Color code in hexadecimal
 * @returns Color in portuguese
 */
const getColorByHex = (colorHex) => {
  let color = "Sem cor";
  if (colorHex === "#FFFFFF") color = "Branco";
  if (colorHex === "#F1F1F1") color = "Cizento";
  return color
}

/**
 * Get content type in portuguese by content type code
 * @param {String} typeCode - Content type code
 * @returns Content type in portuguese
 */
const getContentTypePT = (typeCode) => {
  let typePT = "";
  if (typeCode === "quote") typePT = "Citação";
  if (typeCode === "free_text") typePT = "Texto livre";
  if (typeCode === "image_data_gallery") typePT = "Galeria de imagens";
  if (typeCode === "image_data_slider") typePT = "Slider de Imagens";
  return typePT
}

/**
 * Get class name of an input depending in error value
 * @param {*} error - Value of an error (string)
 * @returns class name (string)
 */
const getInputClass = (error) => {
  return error ? "form-control fbrito-has-error" : "form-control";
}

/**
 * Generate a random id between minimum and maximum values given
 * @param {*} min - Minimum valor for the random id
 * @param {*} max - Minimum valor for the random id
 */
const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;

/**
 * Truncate a string with given length
 * @param {String} string - String to be truncated
 * @param {Number} length - Length for truncate the string
 */
const truncateString = (string, length) => {
  if (!length || !string) { return string; }
  return string.substr(0, length - 1) + (string.length > length ? '...' : '');
}

/**
 * Email type validation
 * @param {String} email - Email to validate
 * @returns Boolean (true/false)
 */
 const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export {
  doubleToSingleQuote,
  getColorByHex,
  getContentTypePT,
  getInputClass,
  random,
  truncateString,
  validateEmail,
};
