import React, { useEffect, useState } from 'react';
import api from '../../api';
import Loader from '../../components/Loader';
import Space from '../../components/Space';

const AdminImages = ({ adminIsLoggedIn }) => {
  const [imagesHome, setImagesHome] = useState([]);
  const [imagesAbout, setImagesAbout] = useState([]);
  const [imagesYoga, setImagesYoga] = useState([]);
  const [imagesPortfolio, setImagesPortfolio] = useState([]);
  const [imagesActivity, setImagesActivity] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    // Redirect to admin login if admin is not logged in
    if (!adminIsLoggedIn) {
      window.location.href = "/4HY=e5T}_9}UkN";
    } else {
      getHomeImages();
      getAboutImages();
      getYogaImages();
      getPortfolioImages();
      getActivityImages();
    }
  }, [])

  const getHomeImages = () => {
    setLoading(true);
    const data = { "page": "home", "type": "gallery" };

    api.getImageDataByPage(data).then(res => {
      if (res && res.data && res.data.length) {
        setImagesHome([...res.data]);
        setLoading(false);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  }

  const getAboutImages = () => {
    setLoading(true);
    const data = { "page": "about", "type": "gallery" };

    api.getImageDataByPage(data).then(res => {
      if (res && res.data && res.data.length) {
        setImagesAbout([...res.data]);
        setLoading(false);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  }

  const getYogaImages = () => {
    setLoading(true);
    const data = { "page": "yoga", "type": "gallery" };

    api.getImageDataByPage(data).then(res => {
      if (res && res.data && res.data.length) {
        setImagesYoga([...res.data]);
        setLoading(false);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  }

  const getPortfolioImages = () => {
    setLoading(true);
    const data = { "page": "design", "type": "gallery" };

    api.getImageDataByPage(data).then(res => {
      if (res && res.data && res.data.length) {
        setImagesPortfolio([...res.data]);
        setLoading(false);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  }

  const getActivityImages = () => {
    setLoading(true);
    const data = { "page": "activity", "type": "gallery" };

    api.getImageDataByPage(data).then(res => {
      if (res && res.data && res.data.length) {
        setImagesActivity([...res.data]);
        setLoading(false);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  }

  const deleteImg = (id) => {
    setLoading(true);
    const data = { "id": id };

    api.deleteImageData(data).then(res => {
      if (res && res.data) {
        window.location.reload();
        setLoading(false);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  }

  return (
    <div className="fbrito-page fbrito-admin-quotes fbrito-bg-grey">
      <Loader loading={loading} />

      <div className="fbrito-page-title">Galeria de Imagens</div>
      
      <h3>Página Inicial (<a href={"admin/imagem/adicionar/home"}>Adicionar</a>)</h3>
      {imagesHome && imagesHome.length ? (
        <React.Fragment>
          <div className="row">
            {imagesHome.map((item, i) => (
              <div className="col-2 fbrito-block-padding-2 fbrito-img-container" key={"img" + i}>
                <div><span className="fbrito-btn-admin btn-del" onClick={() => deleteImg(item.id)}>X</span> &nbsp;	&nbsp; </div>
                <img className="fbrito-admin-gallery" src={item.image_base} width="100%" />
                <div className='fbrito-order'>Ordem: {item.order || "-"}</div>
              </div>))}
          </div>
        </React.Fragment>) : (<div>Não existem imagens para a página Inicial.</div>)}
      
      <Space number={2} />
      <h3>Página Sobre (<a href={"admin/imagem/adicionar/about"}>Adicionar</a>)</h3>
      {imagesAbout && imagesAbout.length ? (
        <React.Fragment>
          <div className="row">
            {imagesAbout.map((item, i) => (
              <div className="col-2 fbrito-block-padding-2 fbrito-img-container" key={"img" + i}>
                <div><span className="fbrito-btn-admin btn-del" onClick={() => deleteImg(item.id)}>X</span> &nbsp;	&nbsp; </div>
                <img className="fbrito-admin-gallery" src={item.image_base} width="100%" />
                <div className='fbrito-order'>Ordem: {item.order || "-"}</div>
              </div>))}
          </div>
        </React.Fragment>) : (<div>Não existem imagens para a página de Sobre.</div>)}

      <Space number={2} />
      <h3>Página Yoga (<a href={"admin/imagem/adicionar/yoga"}>Adicionar</a>)</h3>
      {imagesYoga && imagesYoga.length ? (
        <React.Fragment>
          <div className="row">
            {imagesYoga.map((item, i) => (
              <div className="col-2 fbrito-block-padding-2 fbrito-img-container" key={"img" + i}>
                <div><span className="fbrito-btn-admin btn-del" onClick={() => deleteImg(item.id)}>X</span> &nbsp;	&nbsp; </div>
                <img className="fbrito-admin-gallery" src={item.image_base} width="100%" />
                <div className='fbrito-order'>Ordem: {item.order || "-"}</div>
              </div>))}
          </div>
        </React.Fragment>) : (<div>Não existem imagens para a página de Yoga.</div>)}

      <Space number={2} />
      <h3>Página de Life Design para portfolio (<a href={"admin/imagem/adicionar/design"}>Adicionar</a>)</h3>
      {imagesPortfolio && imagesPortfolio.length ? (
        <React.Fragment>
          <div className="row">
            {imagesPortfolio.map((item, i) => (
              <div className="col-2 fbrito-block-padding-2 fbrito-img-container" key={"img" + i}>
                <div><span className="fbrito-btn-admin btn-del" onClick={() => deleteImg(item.id)}>X</span> &nbsp;	&nbsp; </div>
                <img className="fbrito-admin-gallery" src={item.image_base} width="100%" />
                <div className='fbrito-order'>Ordem: {item.order || "-"}</div>
              </div>))}
          </div>
        </React.Fragment>) : (<div>Não existem imagens para a página de Life Design para portfolio.</div>)}

      <Space number={2} />
      <h3>Sustentabilidade (<a href={"admin/imagem/adicionar/activity"}>Adicionar</a>)</h3>
      {imagesActivity && imagesActivity.length ? (
        <React.Fragment>
          <div className="row">
            {imagesActivity.map((item, i) => (
              <div className="col-2 fbrito-block-padding-2 fbrito-img-container" key={"img" + i}>
                <div><span className="fbrito-btn-admin btn-del" onClick={() => deleteImg(item.id)}>X</span> &nbsp;	&nbsp; </div>
                <img className="fbrito-admin-gallery" src={item.image_base} width="100%" />
                <div className='fbrito-order'>Ordem: {item.order || "-"}</div>
              </div>))}
          </div>
        </React.Fragment>) : (<div>Não existem imagens para a página de Sustentabilidade.</div>)}

      <Space number={2} />
    </div>
  );
}

export default AdminImages;