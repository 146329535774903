import React, { useEffect, useState } from 'react';
import api from '../../api';
import Loader from '../../components/Loader';
import { categoryTypeUrl, feUrl } from '../../utils/ConstantsUtils';
import { getLanguage, getTranslations } from '../../utils/LanguageUtils';
import Footer from '../../components/Footer';
import QuotesSlider from './../../components/QuotesSlider';
import parse from 'html-react-parser';
import ImageModal from '../../components/ImageModal';
import ImageGallery from '../../components/ImageGallery';
import ImageSlider from '../../components/ImageSlider';
import Space from '../../components/Space';
import { Helmet } from 'react-helmet-async';
import { truncateString } from '../../utils/StringUtils';

const Service = () => {
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const [sections, setSections] = useState([]);

  useEffect(() => {
    const code = window.location.pathname.split("/")[2];
    if (code) { fetchService(code); }

    // Change figure tag to iframe for videos in freetext section
    setTimeout(() => {
      const figures = document.getElementsByTagName('figure');
      const len = figures?.length || 0;

      if(len) {
        for(var i=len-1; i>-1; i--){
          var figure = figures[i];
          var iFrameFigure = figures[i].firstChild.firstChild.firstChild;
          figure.outerHTML = `<iframe src="${iFrameFigure.src}"></iframe>`;
        }
      }

    }, "3000")
    
  }, []);

  useEffect(() => {
    const { service } = state;
    if (service?.id) {
      getSections(service.id);
    }
  }, [state.service]);

  const fetchService = (code) => {
    const data = { "code": code, "language": getLanguage() };

    setState({ ...state, loadingService: true });
    api.getServiceByCode(data).then(res => {
      if(res && res.data && res.data.length) {
        setState({ ...state, service: res.data[0], loadingService: false });
      } else {
        setState({ ...state, loadingService: false });
      }
    }).catch(() => {
      setState({ ...state, loadingService: false });
    }); 
  }

  const getSections = (serviceId) => {
    const data = { "language": getLanguage(), "service_id": serviceId };
    setLoading(true);
    api.getSectionsByServiceId(data).then(res => {
      if (res?.data?.length) {
        setSections(res.data)
        setLoading(false);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  }

  const clickCategory = (item) => {
    window.location.href= "/" + categoryTypeUrl[item.code];
  }

  const { service, loadingService } = state;

  // const shareUrl = service ? `${feUrl}/${categoryTypeUrl[service.category_code]}/${service.code}` : feUrl;

  const shareUrl = service ? `${feUrl}/servico/${service.code}` : feUrl;

  const metaTitle = service?.name ? `${service.name} | Francisco Brito` : "Francisco Brito – Professor de Yoga, Designer e Facilitador";
  const metaDescription = service?.description ? truncateString(service?.description, 160) : "Francisco Brito é professor de yoga, designer e facilitador. O seu propósito de vida e de trabalho é contribuir para o florescimento humano e para o desenvolvimento sustentável.";
  const metaImage = service?.image || "https://francisco-brito.com/imgs/open-graph-francisco-brito.jpg";

  return (
    <>

      <Helmet>
          <title>{metaTitle}</title>
          <link rel="canonical" href={feUrl + window.location.pathname}></link>
          <meta itemprop="name" content={metaTitle} />
          <meta itemprop="description" content={metaDescription} />
          {/* <meta itemprop="image" content={metaImage} /> */}
          <meta property="url" content={feUrl + window.location.pathname}/> 
          <meta property="title" content={metaTitle}/>
          <meta property="quote" content={metaDescription} />
          <meta name="description" content={metaDescription} />
          <meta name="image" property="og:image" content={metaImage} />  
          <meta property="og:title" content={metaTitle} />
          <meta property="og:url" content={feUrl + window.location.pathname}/>
          <meta property="og:description" content={metaDescription} />
          <meta name="twitter:title" content={metaTitle} />
          <meta name="twitter:description" property="twitter:description" content={metaDescription} />
          <meta name="twitter:image" property="twitter:image" content={metaImage} />
      </Helmet>

      
      <div className="fbrito-page fbrito-service-page">
        <Loader loading={loadingService || loading} />

        {service ? (
          <React.Fragment>
            <div className='fbrito-bg-grey'>
              <div className='fbrito-service-title fbrito-cat-blog'>{service.name}</div>

              <div className='fbrito-service-description-00'>
                {service.categories.length ? (
                  <div className='fbrito-service-description-01 fbrito-service-labels'>
                    {service.categories.map(item => <span key={item.id} className='fbrito-service-label' onClick={() => clickCategory(item)}>{item.name}</span>)}
                  </div>
                ) : null}

                <div className='fbrito-service-description-01'>{service.description}</div>  
              </div>

              {/* Image display */}
              {service.image ? (
                <div className='fbrito-service-description-00'>
                  <div className='fbrito-service-img'>
                    <img src={service.image} width='100%'/>
                  </div>
                </div>) : null}
              {/* End -Image display */}

              {/* Social media share service */}
              <div className="fbrito-share-text fbrito-center">{getTranslations().screens.public.service.share}</div>
                <div className="fbrito-center">
                <div className="addthis_inline_share_toolbox" data-url={shareUrl} data-title={metaTitle}></div>
              </div>
              <Space number={3} />
              {/* End - Social media share service */}
            </div>

            {sections?.length ? (
              sections.map((item, index) => (
                <div 
                  className={'fbrito-service-description-00' + (item.bg_color==="#F1F1F1" ? ' fbrito-bg-grey' : '')}
                  key={index} >
                
                {item.title ? (<div className="fbrito-page-title">{item.title}</div>) : null }

                {item.type === "free_text" ? (<ServiceFreetext item={item} />) : null}
                {item.type === "quote" ? (<ServiceQuote item={item} />) : null}
                {item.type === "image_data_gallery" ? (<ServiceGallery item={item} />) : null}
                {item.type === "image_data_slider" ? (<ServiceSlider item={item} />) : null}

                </div>
              ))
            ) : null}
          </React.Fragment>) : (<div className='fbrito-page-title'>{getTranslations().screens.public.service.noResult}</div>)}

        {/* Footer */}
        <Footer />
        {/* End - Footer */}
        
      </div>
    </>
  );

}

const ServiceFreetext = ({ item }) => {
  const [state, setState] = useState([]);

  useEffect(() => {
    fetchFreetext(item.content_id);
  }, [])

  const fetchFreetext = (contentId) => {
    const data = { "id": contentId, "language": getLanguage() };
    api.getFreetextById(data).then(res => {
      if(res?.data?.length) {
        setState(res.data);
      }
    });
  }

  return state?.length && state[0]?.text ? <div className={`fbrito-service-free-text${item?.title ? "": " fbrito-service-free-text-p"}`}>{parse(state[0]?.text)}    

  {/* <iframe src="https://www.youtube.com/embed/LI0MBtU61Oc">
 </iframe> */}
 
 </div> : null
}

const ServiceQuote = ({ item }) => {
  return item?.content_id ? <div style={item?.title ? {}: {paddingTop: '50px'}}><QuotesSlider quoteId={item?.content_id} /></div> : null
}

const ServiceGallery = ({ item }) => {
  const [currentImgId, setCurrentImgId] = useState([]);
  const [images, setImages] = useState(null);

  useEffect(() => {
    getImages(item);
  }, [])

  /**
   * Get all images from a section of a service
   * @param {*} item - Section data (id, service id and content id, etc..)
   */
  const getImages = (item) => {
    const {id, service_id, content_id} = item;

    if(id && service_id && content_id) {
      const data = { "section_id": id, "service_id": service_id, "section_content_id": content_id };
      api.getServiceSectionImgs(data).then(res => {
        if(res?.data) {
          const imagesAux = []
          res.data.forEach((item, index) => {
            imagesAux.push({ id: `${index + 1}`, w: item.width, h: item.height, image: item.image_base });
          })
  
          setImages(imagesAux);
        }
      });      
    }
  }

  const itemImage = images?.length ? images.find(item => item.id === currentImgId) : null;

  return (images?.length ? (
    <div>
      <ImageGallery
        images={images}
        setCurrentImgId={setCurrentImgId} 
        className="fbrito-quotes-imgs"
        dataTarget={`#modal-service-${item.content_id}-`} />

      {/* Modal with current image clicked */}
      <ImageModal
        images={images}
        currentImgId={currentImgId}
        setCurrentImgId={setCurrentImgId}
        itemImage={itemImage}
        type={`service-${item.content_id}`}/>
      {/* End - Modal with current image clicked */}
    </div>) : null);
}

const ServiceSlider = ({ item }) => {
  const [images, setImages] = useState(null);

  useEffect(() => {
    getImages(item);
  }, [])

  /**
   * Get all images from a section of a service
   * @param {*} item - Section data (id, service id and content id, etc..)
   */
  const getImages = (item) => {
    const {id, service_id, content_id} = item;

    if(id && service_id && content_id) {
      const data = { "section_id": id, "service_id": service_id, "section_content_id": content_id };
      api.getServiceSectionImgs(data).then(res => {
        if(res?.data) {
          setImages(res?.data);
        }
      });      
    }
  }


  return images?.length ? (<ImageSlider images={images} contentId={item.id} />) : null;
}


export default Service;
