/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const ImageSlider = ({ images, contentId }) => {
  return (
    images?.length ? (
      <div className="fbrito-block-padding-4-4">
        <div id={`fbritoCarouselIndicators${contentId}`} className="carousel slide" data-ride="carousel" data-interval="3000">
          <ol className="carousel-indicators">
            {images.map((item, i) => (
              <li key={"text" + i} data-target={`#fbritoCarouselIndicators${contentId}`} data-slide-to={i + ""} className={i === 0 ? 'active' : '' }></li>
            ))}
          </ol>
      
          <div className="carousel-inner">
            {images.map((item, i) => (
              <div key={"text" + i} className={i === 0 ? 'carousel-item active' : 'carousel-item'}>
                <div className="fbrito-center">
                  <img src={item.image_base} height="300px" />
                </div>
              </div> ))}
          </div>
        </div>
      </div>) : null
  );
  
}

export default ImageSlider;
