import React, { useEffect, useState } from 'react';
import api from '../../api';
import ImageGallery from '../../components/ImageGallery';
import ImageModal from '../../components/ImageModal';
import Space from '../../components/Space';
import { getLanguage, getTranslations } from '../../utils/LanguageUtils';
import AboutHeader from './../../components/AboutHeader';
import Loader from './../../components/Loader';

const About = () => {
  const [loading, setLoading] = useState(false);
  const [aboutInfo, setAboutInfo] = useState(null);
  const [currentImgId,setCurrentImgId] = useState(null);
  const [images, setImages] = useState([]);

  useEffect(() => {
    fetchAboutInfo();
    getImageData();
  }, [])

  /**
   * Fetch about info from api
   */
   const fetchAboutInfo = () => {
    const data = { "language": getLanguage() };
    setLoading(true);
    api.getAboutPage(data).then(res => {
      if (res?.data?.length) {
        setAboutInfo(res.data[0]);
        setLoading(false);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  }

  const getImageData = () => {
    setLoading(true);
    const data = { "page": "about", "type": "gallery" };

    api.getImageDataByPage(data).then(res => {
      if (res && res.data && res.data.length) {

        const imagesAux = []
        res.data.forEach((item, index) => {
          imagesAux.push({ id: `${index + 1}`, w: item.width, h: item.height, image: item.image_base });
        })

        setImages(imagesAux)
        setLoading(false);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  }

  const itemImage = images.find(item => item.id === currentImgId);
  
  return (
    <div className="fbrito-page fbrito-about fbrito-bg-grey">

      <Loader loading={loading} />
      <div className="fbrito-page-title">{getTranslations().screens.public.about.title}</div>
      <AboutHeader aboutInfo={aboutInfo} />

      <div className="fbrito-about-text">

        {/* Gallery of images */}
          <ImageGallery 
            images={images} 
            setCurrentImgId={setCurrentImgId} 
            className="fbrito-about-imgs"
            dataTarget="#modal-about-" />
        {/* End - Gallery of images */}

        {aboutInfo ? (
          <React.Fragment>
            {aboutInfo.text2 ? (<div className="fbrito-about-text-01">{aboutInfo.text2}</div>) : null }
            {aboutInfo.text3 ? (<div className="fbrito-about-text-01">{aboutInfo.text3}</div>) : null }
            {aboutInfo.quote ? (<div className="fbrito-about-text-02">{aboutInfo.quote}</div>) : null }
            {aboutInfo.text4 ? (<div className="fbrito-about-text-01">{aboutInfo.text4}</div>) : null }
            {aboutInfo.text5 ? (<div className="fbrito-about-text-01">{aboutInfo.text5}</div>) : null }           
          </React.Fragment>
        ) : null }

      </div>

      <Space number={2} />
      <div className="fbrito-center">
        <a href="contactos" className="fbrito-btn">{getTranslations().screens.public.about.contactBtn}</a>
      </div>

      <Space number={4} /> 

      {/* Modal with current image clicked */}
      <ImageModal
        images={images}
        currentImgId={currentImgId}
        setCurrentImgId={setCurrentImgId}
        itemImage={itemImage}
        type="about" />
      {/* End - Modal with current image clicked */}

    </div>
  );
}

export default About;
