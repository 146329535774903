import React, { useState, useEffect } from 'react';
import api from '../../api';
import Loader from '../../components/Loader';
import { getInputClass } from '../../utils/StringUtils';
import slugify from 'slugify';
import { getBase64 } from '../../utils/ImagesUtils';
import Space from '../../components/Space';

const AdminBlogAdd = ({ adminIsLoggedIn }) => {
  const [state, setState] = useState({});
  const [imgData, setImgData] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    
    // Redirect to admin login if admin is not logged in
    if (!adminIsLoggedIn) { window.location.href = "/4HY=e5T}_9}UkN"; }
  }, [])

  /**
   * Handle dynamic inputs in state
   * @param {*} event - Current element clicked
   * @param {*} name - Corresponding name of the current element clicked
   */
  const handleChange = (event, name) => {
    const stateAux = { ...state };
    stateAux[name] = event.target.value;
    setState({ ...stateAux });
  }

  /**
   * Handle dynamic inputs in state
   * @param {*} event - Current element clicked
   * @param {*} name - Corresponding name of the current element clicked
   */
  const handleChangeImg = (event) => {
    const files = event && event.target && event.target.files ? event.target.files : null;
    if (files && files.length) {
      getBase64(files[0], setImgData);
    }  
  }

  /**
   * Add new service (blog) in database via php api
   */
  const addService = () => {
    const { name, description, comments, service_order, highlight, name_en, description_en, desc_limit, desc_limit_en } = state;
    let addServiceMessage, nameErr, descriptionErr, nameEnErr, descriptionEnErr = false;

    if(name && name_en && description && description_en) {
      const code = slugify(name);
      const data = { "code": code.toLowerCase(), "name": name, "description": description, "comments": comments, "category_id": 4,
        "service_order": service_order || 1, "highlight": highlight || '0', "name_en": name_en, "description_en": description_en,
        "desc_limit": desc_limit || 100, "desc_limit_en": desc_limit_en || 100, "image": imgData || '', "type": "blog" };

      setState({ ...state, loading: true });
      api.createService(data).then(res => {
        if(res && res.data) {
          setState({ ...state, nameErr, descriptionErr, nameEnErr, descriptionEnErr, loading: false });
          window.location.href = "/admin/blog";
        } else {
          addServiceMessage = "Não foi possível adicionar serviço.";
          setState({ ...state, addServiceMessage, nameErr, descriptionErr, nameEnErr, descriptionEnErr, loading: false });
        }
      }).catch(() => {
        addServiceMessage = "Não foi possível adicionar serviço.";
        setState({ ...state, addServiceMessage, nameErr, descriptionErr, nameEnErr, descriptionEnErr, loading: false });
      });      
    } else {
      if (!name) { nameErr = "Por favor indique o nome." }
      if (!description) { descriptionErr = "Por favor indique a descrição." }
      if (!name_en) { nameEnErr = "Por favor indique o nome em inglês." }
      if (!description_en) { descriptionEnErr = "Por favor indique a descrição em inglês." }
      if (!name  && !description && !name_en && !description_en) {
        addServiceMessage = "Não foi possível adicionar serviço."
      }
      setState({ ...state, nameErr, descriptionErr, nameEnErr, descriptionEnErr, addServiceMessage });
    }
  }

  const { loading, name, description, comments, service_order, highlight, nameErr, descriptionErr, addServiceMessage, 
    name_en, description_en, comments_en, nameEnErr, descriptionEnErr, desc_limit, desc_limit_en } = state;

  return (
    <div className="fbrito-page fbrito-admin-service fbrito-bg-grey">
      <Loader loading={loading} />

      <div className="fbrito-page-title">Blog | Adicionar Publicação</div>

      <form> 
        <div className="row fbrito-block-padding">
          <div className="col-md p-2">
            <label htmlFor="category" className="form-label">Categoria</label>
            <select
              name="category"
              id="category"
              className="form-control"
              placeholder="Categoria"
              onChange={(event) => handleChange(event, "category_id")}
              defaultValue={4} disabled>
                <option key={4} value={4}>Blog</option> 
            </select>
          </div>
          <div className="col-md p-2 row">
            <div className="col-md">            
              <label htmlFor="desc-limit" className="form-label">Nº limite da descrição</label>
              <input type="number" placeholder="Limite (inteiro)" min="0" name="desc-limit" id="desc-limit" className="form-control" value={desc_limit || '100'} onChange={(event) => handleChange(event, "desc_limit")} />
            </div>
            <div className="col-md">
              <label htmlFor="desc-limit-en" className="form-label">Nº limite da descrição - Inglês</label>
              <input type="number" placeholder="Limite (inteiro)" min="0" name="desc-limit-en" id="desc-limit-en" className="form-control" value={desc_limit_en || '100'} onChange={(event) => handleChange(event, "desc_limit_en")} />
            </div>
          </div>
        </div>

        <br />
        <div className="row fbrito-block-padding">
          <div className="col-md p-2">
            <label htmlFor="name" className="form-label">Nome <span className="fbrito-input-error">*</span></label>
            <input type="text" placeholder="Insira um nome" name="name" id="name" className={getInputClass(nameErr)} value={name || ''} onChange={(event) => handleChange(event, "name")} />
            {nameErr ? <span className="fbrito-input-error">{nameErr}</span> : null}
          </div>
          <div className="col-md p-2">
            <label htmlFor="name-en" className="form-label">Nome - Inglês <span className="fbrito-input-error">*</span></label>
            <input type="text" placeholder="Insira um nome" name="name-en" id="name-en" className={getInputClass(nameEnErr)} value={name_en || ''} onChange={(event) => handleChange(event, "name_en")} />
            {nameEnErr ? <span className="fbrito-input-error">{nameEnErr}</span> : null}
          </div>
        </div>

        <br />
        <div className="row fbrito-block-padding">
          <div className="col-md p-2">
            <label htmlFor="description" className="form-label">Descrição <span className="fbrito-input-error">*</span></label>
            <textarea rows="5" type="text" placeholder="Insira uma descrição" name="description" id="description" className={getInputClass(descriptionErr)} value={description || ''} onChange={(event) => handleChange(event, "description")} />
            {descriptionErr ? <span className="fbrito-input-error">{descriptionErr}</span> : null}
          </div>
          <div className="col-md p-2">
            <label htmlFor="description-en" className="form-label">Descrição - Inglês <span className="fbrito-input-error">*</span></label>
            <textarea rows="5" type="text" placeholder="Insira uma descrição" name="description-en" id="description-en" className={getInputClass(descriptionEnErr)} value={description_en || ''} onChange={(event) => handleChange(event, "description_en")} />
            {descriptionEnErr ? <span className="fbrito-input-error">{descriptionEnErr}</span> : null}
          </div>
        </div>

        <br />
        <div className="row fbrito-block-padding">
          <div className="col-md p-2">
            <label htmlFor="comments" className="form-label">Observações</label>
            <input type="text" placeholder="Insira observações" name="comments" id="comments" className="form-control" value={comments || ''} onChange={(event) => handleChange(event, "comments")} />
          </div>
          <div className="col-md p-2">
            <label htmlFor="comments-en" className="form-label">Observações - Inglês</label>
            <input type="text" placeholder="Insira observações" name="comments-en" id="comments-en" className="form-control" value={comments_en || ''} onChange={(event) => handleChange(event, "comments_en")} />
          </div>
        </div>

        <br />
        <div className="row fbrito-block-padding">
          <div className="col-md p-2">
            <label htmlFor="order" className="form-label">Marcar ordem</label>
            <input type="number" placeholder="Ordem (inteiro)" min="0" name="order" id="order" className="form-control" value={service_order || ''} onChange={(event) => handleChange(event, "service_order")} />
          </div>
          <div className="col-md p-2">
            <label htmlFor="highlight" className="form-label">Marcar destaque</label>
            <input type="number" placeholder="Destaque (inteiro)" min="0" name="highlight" id="highlight" className="form-control" value={highlight || '0'} onChange={(event) => handleChange(event, "highlight")} />
          </div>
        </div>

        <br />
        <div className="row fbrito-block-padding">
          <div className="col-md p-2">
            <label htmlFor="image" className="form-label">Imagem</label>
            <input type="file" placeholder="Escolher imagem" name="image" id="image" className="form-control" onChange={handleChangeImg}  />
          </div>
          <div className="col-md p-2">
            {imgData ? (<img src={imgData} height="100px" />) : null}
          </div>
        </div>

        <br />
        <div className="row fbrito-block-padding">
          <div>
            <b>Nota: </b>
            <span>Os textos livres e as citações podem ser adicionados ao editar este blog (após adicioná-lo).</span>
          </div>
        </div>

        <Space number={3} />
        <div className="row fbrito-block-padding" style={{ marginTop: "70px !important" }}>
          <div className="col-sm p-2"><a href="admin/blog" className="fbrito-btn">Voltar</a></div>
          <div className="col-sm p-2">
            <div className="fbrito-btn" onClick={addService}>Adicionar</div>
            {addServiceMessage ? <span>{addServiceMessage}</span> : null}
          </div>
          <div className="col-sm p-2"></div>
        </div>
      </form>
      <Space number={3} />
    </div>
  );
}

export default AdminBlogAdd;