import React, { useState, useEffect, Fragment } from 'react';
import api from '../../api';
import Loader from '../../components/Loader';
import Space from '../../components/Space';
import { getBase64 } from '../../utils/ImagesUtils';
import { getColorByHex, getContentTypePT, getInputClass } from '../../utils/StringUtils';

const AdminBlogEdit = ({ adminIsLoggedIn }) => {
  const [state, setState] = useState({});
  const [imgData, setImgData] = useState('');
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    
    // Redirect to admin login if admin is not logged in
    if (!adminIsLoggedIn) {
        window.location.href = "/4HY=e5T}_9}UkN";
    } else {  
      const urlArray = document.location.href.split('/');
      let id = null;

      try {
        id = parseInt(urlArray[6]);
        if (id) {
          getServiceData(id);
          getSections(id);
        }
      } catch(e) {
        window.location.href = "/4HY=e5T}_9}UkN";
      }
    }
  }, [])

  /**
   * Handle dynamic inputs in state
   * @param {*} event - Current element clicked
   * @param {*} name - Corresponding name of the current element clicked
   */
  const handleChange = (event, name) => {
    const stateAux = { ...state };
    stateAux[name] = event.target.value;
    setState({ ...stateAux });
  }

  /**
   * Handle dynamic inputs in state
   * @param {*} event - Current element clicked
   * @param {*} name - Corresponding name of the current element clicked
   */
  const handleChangeImg = (event) => {
    const files = event?.target?.files || null;
    if (files?.length) {
      getBase64(files[0], setImgData);
    }  
  }

  /**
   * Fetch service data with given service id
   * @param {*} id - Service id
   */
  const getServiceData = (id) => {
    setState({ loading: true });
    const data = { "id": id };
    api.getServiceById(data).then(res => {
      if(res && res.data) {
        setState({
          id: parseInt(res.data.id),
          name: res.data.name,
          name_en: res.data.name_en,
          code: res.data.code,
          description: res.data.description,
          description_en: res.data.description_en,
          highlight: res.data.highlight ? parseInt(res.data.highlight) : 0,
          comments: res.data.comments,
          comments_en: res.data.comments_en,
          service_order: res.data.service_order ? parseInt(res.data.service_order) : 1,
          category_id: res.data.category_id ? parseInt(res.data.category_id) : 4,
          desc_limit: res.data.desc_limit ? parseInt(res.data.desc_limit) : 100,
          desc_limit_en: res.data.desc_limit_en ? parseInt(res.data.desc_limit_en) : 100,
          loading: false
        });

        setImgData(res.data.image);
      } else {
        setState({ loading: false });
      }
    }).catch(() => {
      setState({ loading: false });
    });
  }

  /**
   * Get list of sections by service id
   * @param {*} id - Service id
   */
  const getSections = (id) => {
    setLoading(true);
    const data = { "service_id": id };

    api.getSectionsByServiceId(data).then(res => {
      if (res?.data?.length) {
        setSections([...res.data]);
        setLoading(false);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  }

  /**
   * Redirects to edit the section with given section id
   * @param {*} sectionId - Clicked section id to be edited
   */
  const editSection = (sectionId) => {
    window.location.href = "/admin/seccao/editar/" + sectionId + "?blog=" + id;
  }

  /**
   * Update service data
   */
  const save = () => {
    const { id, name, name_en, code, description, description_en, highlight, comments, comments_en,
      service_order, desc_limit, desc_limit_en } = state;

    let updateServiceMessage, nameErr, descriptionErr, nameEnErr, descriptionEnErr = false;
 
    if(id && name && name_en && description && description_en) {
      const data = {
        "id": `${id || ''}`,
        "name": name,
        "name_en": name_en,
        "code": code || '',
        "image": imgData || '',
        "description": description,
        "description_en": description_en,
        "highlight": `${highlight || '0'}`,
        "comments": comments || '',
        "comments_en": comments_en || '',
        "service_order": `${service_order || ''}`,
        "category_id": "4",
        "desc_limit": `${desc_limit || 100}`,
        "desc_limit_en": `${desc_limit_en || 100}`,
        "type": "blog" };

      setState({ ...state, loading: true });
      api.updateService(data).then(res => {
        if(res && res.data) {
          updateServiceMessage = "Atualizado com sucesso.";
          setState({ ...state, updateServiceMessage, nameErr, descriptionErr, nameEnErr, descriptionEnErr, loading: false });
        } else {
          updateServiceMessage = "Não foi possível atualizar.";
          setState({ ...state, updateServiceMessage, nameErr, descriptionErr, nameEnErr, descriptionEnErr, loading: false });
        }
      }).catch(() => {
        updateServiceMessage = "Não foi possível atualizar.";
        setState({ ...state, updateServiceMessage, nameErr, descriptionErr, nameEnErr, descriptionEnErr, loading: false });
      });      
    } else {
      if (!name) { nameErr = "Por favor indique o nome." }
      if (!description) { descriptionErr = "Por favor indique a descrição." }
      if (!name_en) { nameEnErr = "Por favor indique o nome em inglês." }
      if (!description_en) { descriptionEnErr = "Por favor indique a descrição em inglês." }
      if (!name  && !description && !name_en && !description_en) { updateServiceMessage = "Não foi possível atualizar." }
      setState({ ...state, nameErr, descriptionErr, nameEnErr, descriptionEnErr, updateServiceMessage });
    }
  }

  /**
   * Delete service by calling api
   */
  const deleteService = () => {
    let deleteServiceMessage = false;

    if(state.id) {
      const data = { "id": state.id };
      setState({ ...state, loading: true });
      api.deleteService(data).then(res => {
        if(res && res.data) {
          setState({ ...state, loading: false });
          window.location.href = "/admin/blog";
        } else {
          deleteServiceMessage = "Não foi possível eliminar.";
          setState({ ...state, deleteServiceMessage, loading: false });
        }
      }).catch(() => {
        deleteServiceMessage = "Não foi possível eliminar.";
        setState({ ...state, deleteServiceMessage, loading: false });
      });      
    } else {
      deleteServiceMessage = "Não foi possível eliminar.";
      setState({ ...state, deleteServiceMessage });
    }
  }

  const { id, name, description, comments, service_order, highlight, nameErr, descriptionErr,
    updateServiceMessage, deleteServiceMessage, name_en, description_en, comments_en, nameEnErr, descriptionEnErr,
    desc_limit, desc_limit_en } = state;

  return (
    <div className="fbrito-page fbrito-admin-service fbrito-bg-grey">
      <Loader loading={state.loading || loading} />

      <div className="fbrito-page-title"> Blog | Editar Publicação</div>

      <form> 
        <div className="row fbrito-block-padding">
          <div className="col-md p-2">
            <label htmlFor="category" className="form-label">Categoria</label>
            <select
              name="category"
              id="category"
              className="form-control"
              placeholder="Categoria"
              onChange={(event) => handleChange(event, "category_id")}
              defaultValue={4} disabled>
                <option key={4} value={4}>Blog</option> 
            </select>
          </div>
          <div className="col-md p-2 row">
            <div className="col-md">            
              <label htmlFor="desc-limit" className="form-label">Nº limite da descrição</label>
              <input type="number" placeholder="Limite (inteiro)" min="0" name="desc-limit" id="desc-limit" className="form-control" value={desc_limit || '100'} onChange={(event) => handleChange(event, "desc_limit")} />
            </div>
            <div className="col-md">
              <label htmlFor="desc-limit-en" className="form-label">Nº limite da descrição - Inglês</label>
              <input type="number" placeholder="Limite (inteiro)" min="0" name="desc-limit-en" id="desc-limit-en" className="form-control" value={desc_limit_en || '100'} onChange={(event) => handleChange(event, "desc_limit_en")} />
            </div>
          </div>
        </div>

        <br />
        <div className="row fbrito-block-padding">
          <div className="col-md p-2">
            <label htmlFor="name" className="form-label">Nome <span className="fbrito-input-error">*</span></label>
            <input type="text" placeholder="Insira um nome" name="name" id="name" className={getInputClass(nameErr)} value={name || ''} onChange={(event) => handleChange(event, "name")} />
            {nameErr ? <span className="fbrito-input-error">{nameErr}</span> : null}
          </div>
          <div className="col-md p-2">
            <label htmlFor="name-en" className="form-label">Nome - Inglês <span className="fbrito-input-error">*</span></label>
            <input type="text" placeholder="Insira um nome" name="name-en" id="name-en" className={getInputClass(nameEnErr)} value={name_en || ''} onChange={(event) => handleChange(event, "name_en")} />
            {nameEnErr ? <span className="fbrito-input-error">{nameEnErr}</span> : null}
          </div>
        </div>
      
        <br />
        <div className="row fbrito-block-padding">
          <div className="col-md p-2">
            <label htmlFor="description" className="form-label">Descrição <span className="fbrito-input-error">*</span></label>
            <textarea rows="5" type="text" placeholder="Insira uma descrição" name="description" id="description" className={getInputClass(descriptionErr)} value={description || ''} onChange={(event) => handleChange(event, "description")} />
            {descriptionErr ? <span className="fbrito-input-error">{descriptionErr}</span> : null}
          </div>
          <div className="col-md p-2">
            <label htmlFor="description-en" className="form-label">Descrição - Inglês <span className="fbrito-input-error">*</span></label>
            <textarea rows="5" type="text" placeholder="Insira uma descrição" name="description-en" id="description-en" className={getInputClass(descriptionEnErr)} value={description_en || ''} onChange={(event) => handleChange(event, "description_en")} />
            {descriptionEnErr ? <span className="fbrito-input-error">{descriptionEnErr}</span> : null}
          </div>
        </div>
      
        <br />
        <div className="row fbrito-block-padding">
          <div className="col-md p-2">
            <label htmlFor="comments" className="form-label">Observações</label>
            <input type="text" placeholder="Insira observações" name="comments" id="comments" className="form-control" value={comments || ''} onChange={(event) => handleChange(event, "comments")} />
          </div>
          <div className="col-md p-2">
            <label htmlFor="comments-en" className="form-label">Observações - Inglês</label>
            <input type="text" placeholder="Insira observações" name="comments-en" id="comments-en" className="form-control" value={comments_en || ''} onChange={(event) => handleChange(event, "comments_en")} />
          </div>
        </div>

        <br />
        <div className="row fbrito-block-padding">
          <div className="col-md p-2">
            <label htmlFor="order" className="form-label">Marcar ordem</label>
            <input type="number" placeholder="Ordem (inteiro)" min="0" name="order" id="order" className="form-control" value={service_order || ''} onChange={(event) => handleChange(event, "service_order")} />
          </div>
          <div className="col-md p-2">
            <label htmlFor="highlight" className="form-label">Marcar destaque</label>
            <input type="number" placeholder="Destaque (inteiro)" min="0" name="highlight" id="highlight" className="form-control" value={highlight || '0'} onChange={(event) => handleChange(event, "highlight")} />
          </div>
        </div>

        <br />
        <div className="row fbrito-block-padding">
          <div className="col-md p-2">
            <label htmlFor="image" className="form-label">Imagem</label>
            <input type="file" placeholder="Escolher imagem" name="image" id="image" className="form-control" onChange={handleChangeImg}  />
          </div>
          <div className="col-md p-2">
            {imgData ? (<img src={imgData} height="100px" />) : null}
          </div>
        </div>

        {/* <br />
        <div className="row fbrito-block-padding">
          <div className="col-md p-2">
            <h3>Secções (<a href={`admin/seccao/adicionar?servico=${id}&blog=${id}`}>Adicionar</a>)</h3>
            {sections?.length ? (
              <>
                <div className="row" style={{fontWeight: "bold"}}>
                  <div className="col-1">Ordem</div>
                  <div className="col-1">Tipo</div>
                  <div className="col-2">Background</div>
                  <div className="col-2">Título</div>
                  <div className="col-2">Título - Inglês</div>
                  <div className="col-2"></div>
                  <div className="col-2"></div>
                </div>

              <div className="row">
                {sections.map((item, i) => (
                  <Fragment key={`section${i}`}>
                    <div className="col-1">{item.section_order || "0"}</div>
                    <div className="col-1">{getContentTypePT(item.type)}</div>
                    <div className="col-2">{getColorByHex(item.bg_color)}</div>
                    <div className="col-2">{item.title || "-"}</div>
                    <div className="col-2">{item.title_en || "-"}</div>

                    <div className="col-2">
                      <div className="fbrito-btn-admin" onClick={() => editSection(item.id)}>
                        Editar Secção
                      </div>  
                    </div>
                    
                    <div className="col-2">
                      <div className="fbrito-btn-admin" onClick={() => editContent(item)}>
                        {`${item.content_id ? "Editar" : "Adicionar"} ${getContentTypePT(item.type)}`}
                      </div>
                    </div>
                  </Fragment>))}
              </div>
            </>) : (<div>Não existem secções.</div>)}

          </div>
        </div> */}

        <br />
        <div className="row fbrito-block-padding">
          <div className="col-md p-2">
            <h3>Secções</h3>

            <div>
              <a href={`admin/seccao/adicionar?servico=${id}&blog=${id}&conteudo=quote`}>Adicionar Citação</a> 
              | <a href={`admin/seccao/adicionar?servico=${id}&blog=${id}&conteudo=free_text`}>Adicionar Texto Livre</a> 
              | <a href={`admin/seccao/adicionar?servico=${id}&blog=${id}&conteudo=image_data_gallery`}>Adicionar Galeria</a>
              | <a href={`admin/seccao/adicionar?servico=${id}&blog=${id}&conteudo=image_data_slider`}>Adicionar Slider</a>
            </div>
            
            <br />
            {sections?.length ? (
              <>
                <div className="row" style={{fontWeight: "bold"}}>
                  <div className="col-1">Ordem</div>
                  <div className="col-1">Tipo</div>
                  <div className="col-2">Background</div>
                  <div className="col-3">Título</div>
                  <div className="col-3">Título - Inglês</div>
                  <div className="col-2"></div>
                </div>

              <div className="row">
                {sections.map((item, i) => (
                  <Fragment key={`section${i}`}>
                    <div className="col-1">{item.section_order || "0"}</div>
                    <div className="col-1">{getContentTypePT(item.type)}</div>
                    <div className="col-2">{getColorByHex(item.bg_color)}</div>
                    <div className="col-3">{item.title || "-"}</div>
                    <div className="col-3">{item.title_en || "-"}</div>

                    <div className="col-2">
                      <div className="fbrito-btn-admin" onClick={() => editSection(item.id)}>Editar Secção</div>
                    </div>
                  </Fragment>))}
              </div>
            </>) : (<div>Não existem secções.</div>)}

          </div>
        </div>

        <Space number={3} />
        <div className="row fbrito-block-padding" style={{ marginTop: "70px !important" }}>
          <div className="col-sm p-2"><a href="admin/blog" className="fbrito-btn">Voltar</a></div>
          <div className="col-sm p-2">
            <div className="fbrito-btn" onClick={save}>Guardar</div>
            {updateServiceMessage ? <div>{updateServiceMessage}</div> : null}
          </div>
          <div className="col-sm p-2">
            <div className="fbrito-btn fbrito-btn--red" onClick={deleteService}>Eliminar</div>
            {deleteServiceMessage ? <div>{deleteServiceMessage}</div> : null}
          </div>
        </div>
      </form>
      <Space number={3} />
    </div>
  );
}

export default AdminBlogEdit;