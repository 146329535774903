import React, { useEffect, useState } from 'react';
import api from '../../api';
import { getInputClass, random } from '../../utils/StringUtils';
import Loader from '../../components/Loader';
import { getBase64 } from '../../utils/ImagesUtils';
import { pagesType } from '../../utils/ConstantsUtils';
import Space from '../../components/Space';

const AdminImageAdd = ({ adminIsLoggedIn }) => {
  const [state, setState] = useState({});
  const [imgData, setImgData] = useState('');
  const [imgWidth, setImgWidth] = useState(0);
  const [imgHeight, setImgHeight] = useState(0);
  const [serviceId, setServiceId] = useState(null);
  const [sectionId, setSectionId] = useState(null);
  const [blog, setBlog] = useState(null);
  const [sectionGallery, setSectionGallery] = useState(null);
  const [imgType, setImgType] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    // Redirect to admin login if admin is not logged in
    if (!adminIsLoggedIn) {
      window.location.href = "/4HY=e5T}_9}UkN";
    } else {
      const urlArray = document.location.pathname.split('/');

      try {
        const pageCode = urlArray[4];
        if (urlArray.length > 4) {
          let serviceIdAux = urlArray[5];
          const urlParams = new URLSearchParams(window.location.search);
          serviceIdAux = urlParams.get('id') || false;
          const imgType = urlParams.get('type') || false;
          const sectionIdAux = urlParams.get('seccao') || false;
          const sectionGalleryAux = urlParams.get('galeria') || false;
          const blogAux = urlParams.get('blog') || false;
          setSectionId(sectionIdAux);
          setServiceId(serviceIdAux);
          setSectionGallery(sectionGalleryAux);
          setImgType(imgType);
          setBlog(blogAux);
        }
        
        const serviceParam = pageCode.includes("service");
        setState({ page: pageCode, serviceParam })
      } catch (err) {
        setState({ page: "home" })
      }
    } 
  }, [])

  /**
   * Handle dynamic inputs in state
   * @param {*} event - Current element clicked
   * @param {*} name - Corresponding name of the current element clicked
   */
   const handleChangeImg = (event) => {
    const files = event?.target?.files || null;
    if (files?.length) {
      getBase64(files[0], setImgData, setImgWidth, setImgHeight);
    }  
  }

  /**
   * Handle dynamic inputs in state
   * @param {*} event - Current element clicked
   * @param {*} name - Corresponding name of the current element clicked
   */
   const handleChange = (event, name) => {
    const stateAux = { ...state };
    stateAux[name] = event.target.value;
    setState({ ...stateAux });
  }

  /**
   * Add new quote into database via php api call
   */
  const addImg = () => {
    const { page, order } = state;
    let addImageMessage, imageErr, orderErr = false;

    if (imgData && order) {
      
      const randomId = sectionGallery || random(5000, 5000000);

      const data = { "page": page, "order": order, "type": imgType || "gallery", "height": imgHeight, width: imgWidth, image_base: imgData, service_id: serviceId, "section_id": parseInt(sectionId), "random_id": randomId };

      setState({ ...state, loading: true });
      
      api.createImageData(data).then(res => {
        if(res?.data) {
          setState({ ...state, loading: false });
          goBack();
        } else {
          addImageMessage = "Não foi possível adicionar imagem.";
          setState({ ...state, addImageMessage, loading: false });
        }
      }).catch(() => {
        addImageMessage = "Não foi possível adicionar imagem.";
        setState({ ...state, addImageMessage, loading: false });
      });      
    } else {
      if (!imgData) { imageErr = "Por favor escolha imagem." }
      if (!order) { orderErr = "Por favor indique a ordem." }
      if (!imgData && !order) { addImageMessage = "Não foi possível adicionar imagem." }
      setState({ ...state, imageErr, orderErr, addImageMessage });
    }
  }

  /**
   * Go back to the previous page (edit service page)
   */
  const goBack = () => {
    if (serviceId) {
      if (page === "service") { window.location.href = "/admin/servico/editar/" + serviceId; }
      if (page === "blog" || blog) { window.location.href = "/admin/blog/editar/" + serviceId; }
    } else {
      window.location.href = "admin/imagens";
    }
    
  }

  const { imageErr, orderErr, loading, addImageMessage, page, order } = state;

  return (
    <div className="fbrito-page fbrito-admin-quotes fbrito-bg-grey">
      <Loader loading={loading} />
      <div className="fbrito-page-title">Adicionar Imagem</div>
      
      <form>
        <div className="row fbrito-block-padding">
          <div className="col-md p-2">
            <label htmlFor="image" className="form-label">Imagem {page ? ` - ${pagesType[page]}` : null} <span className="fbrito-input-error">*</span></label>
            <input type="file" placeholder="Escolher imagem" name="image" id="image" className={getInputClass(imageErr)} onChange={handleChangeImg}  />
            {imageErr ? <span className="fbrito-input-error">{imageErr}</span> : null}
          </div>
          <div className="col-md p-2">
            {imgData ? (<img src={imgData} height="100px" />) : null}
          </div>
        </div>

        <br />
        <div className="row fbrito-block-padding">
          <div className="col-md p-2">
            <label htmlFor="order" className="form-label">Marcar ordem <span className="fbrito-input-error">*</span></label>
            <input type="number" placeholder="Ordem (inteiro)" min="0" name="order" id="order" className={getInputClass(orderErr)} value={order || ''} onChange={(event) => handleChange(event, "order")} />
            {orderErr ? <span className="fbrito-input-error">{orderErr}</span> : null}
          </div>
          <div className="col-md p-2"></div>
        </div>

        <Space number={3} />
        <div className="row fbrito-block-padding">
          <div className="col-sm p-2"><div onClick={goBack} className="fbrito-btn">Voltar</div></div>
          <div className="col-sm p-2">
            <div className="fbrito-btn" onClick={addImg}>Adicionar</div>
            {addImageMessage ? (<div>{addImageMessage}</div>): null}
          </div>
          <div className="col-sm p-2"></div>
        </div>
      </form>

      <Space number={3} />
    </div>
  );

}

export default AdminImageAdd;