export const translationsEN = {
  screens: {
    public: {
      about: {
        title: "About Me",
        contactBtn: "Contact",
        modalTitle: "Francisco Brito",
      },
      activities: {
        title: "Sustainability",
        schedule: "Weekly Schedule",
        contactBtn: "Contact",
        modalTitle: "Francisco Brito",
      },
      contacts: {
        title: "Contacts",
        intro: "Hi! How can I help you?",
        imgAlt: "Photo of Francisco Brito"
      },
      design: {
        title: "UX / Web Design / Branding",
        portfolio: "Portfolio",
        button: "Check Portfolio",
        modalTitle: "Francisco Brito",
      },
      homepage: {
        intro1V1: "My life and work purpose is to contribute to",
        intro1V2: "human flourishing and sustainable development.",
        intro2V1: "My life and work purpose is to",
        intro2V2: "contribute to human flourishing",
        intro2V3: "and sustainable development.",
        intro3V1: "My life and work purpose",
        intro3V2: "is to contribute to",
        intro3V3: "human flourishing and",
        intro3V4: "sustainable development.",
        highlight: "Highlights",
        aboutMe: "About Me",
        moreBtn: "Know more",
        schedule: "Weekly Schedule",
        contactBtn: "Contact",
        blog: "Blog",
        modalTitle: "Francisco Brito",
      },
      schedule: {
        title: "Weekly Schedule",
        button: "Contact",
      },
      service: {
        share: "Share",
        noResult: "No service found...",
        modalTitle: "Francisco Brito",
        gallery: "Gallery of Images",
        slider: "Slider of Images"
      },
      yoga: {
        title: "Yoga / Meditation",
        quotes: "Quotes",
        schedule: "Weekly Schedule",
        button: "Contact",
        modalTitle: "Francisco Brito",
      }
    },
    notFound: {
      title: "Page Not Found",
      description: "But below you can know more about what I do",
      homepageLink: "Homepage"
    }
  },
  components: {
    aboutHeader: {
      imgAlt: "Francisco Brito Photo"
    },
    categoriesBlock: {
      alt1: "Category 1 - Yoga", 
      title1: "Yoga / Meditation",
      title1V1: "Yoga / ",
      title1V2: "Meditation",
      description1: "I give personalized and group classes of yoga and meditation for phisical, mental and spiritual flourishing.",
      alt2: "Category 2 - Life Design", 
      title2: "Life Design",
      title2V1: "",
      title2V2: "",
      description2: "I facilitate design thinking workshops, make logos, websites, wall paintings and other design and art services.",
      alt3: "Category 3 - Sustainability", 
      title3: "Sustainability",
      title3V1: " ",
      title3V2: "",
      description3: "I organize and facilitate personal development and citizenship events that integrate creative and dialogue methods.",
      button: "Know More"
    },
    footer: {
      websiteName: "Francisco Brito",
      rights: "© 2021 Francisco Brito. All rights reserved.",
      emailPlaceholder: "Email",
      subscribe: "Subscribe",
      subscriptionSuccess: "Subscription done! ",
      subscriptionFailed: "Error occurred! Please try again. "
    },
    loader: {
      message: "Loading content...",
    },
    menu: {
      websiteName: "FRANCISCO BRITO",
      open: "Menu",
      close: "close",
      currentLanguage: "EN",
      about: "About",
      schedule: "schedule",
      yoga: "Yoga",
      design: "Design",
      activities: "Sustainability",
      blog: "Blog",
      contacts: "Contacts"
    },
    scheduleCard: {
      noResult: "Schedule to define..."
    },
    serviceCard: {
      noResult: "No services yet..."
    }
  }
}