import React, { useState, useEffect } from 'react';
import api from '../../api';
import Space from '../../components/Space';
import Loader from './../../components/Loader';

const AdminHomepage = ({ adminIsLoggedIn }) => {
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    // Redirect to admin login if admin is not logged in
    if (!adminIsLoggedIn) {
      window.location.href = "/4HY=e5T}_9}UkN";
    } else {
      const username =  localStorage.getItem("username") || "";
      setUsername(username || "");
    }
  }, [])

  /**
   * Logout admin user by calling api
   */
  const logout = () => {
    const data = { "username": username || "" };
    
    setLoading(true);
    api.adminLogout(data).then(res => {
      if (res && res.data) {
        localStorage.removeItem("adminIsLoggedIn");
        window.location.href = "/4HY=e5T}_9}UkN";
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    })
  }

  return (
    <div className="fbrito-page fbrito-bg-grey">
      <Loader loading={loading} />
      <div className="fbrito-page-title">Administrador: {username || "Desconhecido"}</div>
      <div className="row fbrito-block-padding">
        <div className="col-sm-4"></div>
        <div className="col-sm-4">
          <div className="fbrito-center">Clique no botão abaixo para alterar a palavra-passe</div><br/>
          <div className="fbrito-center">
            <a href="/admin/alterar-password" className="fbrito-btn">Alterar</a>
          </div>
          <br />
          <div className="fbrito-center">Clique no botão abaixo para sair de modo administrador</div><br/>
          <div className="fbrito-center">
            <a href="#" className="fbrito-btn fbrito-btn--red" onClick={() => logout()}>Sair</a>
          </div>
        </div>
        <div className="col-sm-4"></div>
      </div>
      <Space number={6} />
    </div>
  );
  
}

export default AdminHomepage;
