import React from 'react';
import { imgSliderNext, imgSliderPrevious } from '../utils/ImagesUtils';

const ImageModal = ({ images, currentImgId, setCurrentImgId, itemImage, type }) => {
  return (
    images.map(item => (
      <div key={item.id} className='modal fade fbrito-modal' id={`modal-${type}-${item.id}`} tabIndex='-1' role='dialog' aria-labelledby='fbritoModalLabel' aria-hidden='true'>
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div  className='modal-content-img'>
              <div className='modal-body'>
                <div className='modal-close'>
                  <button type='button'data-dismiss='modal' aria-label='Close'>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>

                <div className="slider-btns">
                  <div className="slider-btn-previous">
                    {currentImgId !== "1" ? (<i onClick={() => imgSliderPrevious(currentImgId, setCurrentImgId)} className="fas fa-chevron-left"></i>) : null}
                  </div>
                  <div className="slider-btn-next" onClick={() => imgSliderNext(currentImgId, setCurrentImgId, images)}>
                    {currentImgId !== `${images.length}` ? (<i onClick={() => imgSliderNext(currentImgId, setCurrentImgId, images)} className="fas fa-chevron-right"></i>) : null}
                  </div>
                </div>

                <div className="img-display">
                  {itemImage ? (<img src={itemImage.image} />) : (<img src={item.image} />)}
                </div> 
              </div>    
                          
            </div> 

          </div>
        </div>
      </div>))
  );
}

export default ImageModal;
