import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ADMIN_ROUTES, PUBLIC_ROUTES } from '../routes';
import AdminRoute from '../routes/AdminRoute';
import PublicRoute from '../routes/PublicRoute';
import NotFound from '../screens/NotFound';

const Mainpage = () => {
  const adminIsLoggedIn = localStorage.adminIsLoggedIn === "true";

  return (
    <Router>
      <Switch>
        {ADMIN_ROUTES.map(route => (
          <AdminRoute
            key={route.path}
            path={route.path}
            component={route.component}
            pageKey={route.key}
            adminIsLoggedIn={adminIsLoggedIn || false}
            exact
          />
        ))}

        {PUBLIC_ROUTES.map(route => (
          <PublicRoute
            key={route.path}
            path={route.path}
            component={route.component}
            pageKey={route.key}
            exact
          />
        ))}

        <PublicRoute path="*" component={() => <NotFound />} exact />
      </Switch>
    </Router>
  );
}

export default Mainpage;
