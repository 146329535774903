import React, { useEffect, useState } from 'react';
import api from '../../api';
import { getInputClass, random } from '../../utils/StringUtils';
import Loader from './../../components/Loader';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CustomUploadAdapterPlugin } from '../../utils/CKEditorUtils';
import Space from '../../components/Space';

const AdminFreetextEdit = ({ adminIsLoggedIn }) => {
  const [state,setState] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    
    // Redirect to admin login if admin is not logged in
    if (!adminIsLoggedIn) {
      window.location.href = "/4HY=e5T}_9}UkN";
    } else {
			// Get service id if exists
			const urlParams = new URLSearchParams(window.location.search);
			const serviceId = urlParams.get('servico') || false;
      const sectionId = urlParams.get('seccao') || false;
      const blog = urlParams.get('blog') || false;
			setState({ serviceId, sectionId, blog });
    }
  }, [])

  /**
   * Handle dynamic inputs in state
   * @param {*} event - Current element clicked
   * @param {*} name - Corresponding name of the current element clicked
   */
   const handleChange = (event, name) => {
    const stateAux = { ...state };
    stateAux[name] = event.target.value;
    setState({ ...stateAux });
  }

  /**
   * Handle dynamic first editor input in state
   * @param {*} event - Current element clicked
   * @param {*} editor - editor name of the current element clicked
   */
  const handleChangeEditor1 = (event, editor) => {
    if (editor) {
      const data = editor.getData();
      setState({...state, text: data});
    }
  }

  /**
   * Handle dynamic second editor input in state
   * @param {*} event - Current element clicked
   * @param {*} editor - editor name of the current element clicked
   */
  const handleChangeEditor2 = (event, editor) => {
    if (editor) {
      const data = editor.getData();
      setState({...state, text_en: data});
    }
  }

  /**
   * Update quote by calling api
   */
   const addFreetext = () => {
    const { text, text_en, text_order, serviceId, sectionId } = state;
    let addFreetextMessage, textErr, textEnErr, textOrderErr = false;

    if(text && text_en && serviceId) {
      const randomId = random(5000, 5000000);
      const data = { "text": text, "text_en": text_en, "text_order": text_order || 1, "service_id": serviceId, "section_id": parseInt(sectionId), "random_id": randomId };
      setState({ ...state, loading: true });
      api.createFreetext(data).then(res => {
        if(res?.data) {
          setState({ ...state, loading: false });
          goBack();
        } else {
          addFreetextMessage = "Não foi possível criar texto livre.";
          setState({  ...state, addFreetextMessage, textErr, textEnErr, textOrderErr, loading: false });
        }
      }).catch(() => {
        addFreetextMessage =  "Não foi possível criar texto livre.";
        setState({ ...state, addFreetextMessage, textErr, textEnErr, textOrderErr, loading: false });
      });      
    } else {
      if (!text) { textErr = "Por favor indique o texto." }
      if (!text_en) { textEnErr = "Por favor indique o texto em inglês." }
      if (!text_order) { textOrderErr = "Por favor indique a ordem." }
      if (!text && !text_en && !text_order && !serviceId) { addFreetextMessage =  "Não foi possível criar texto livre." }
      setState({ ...state, textErr, textEnErr, textOrderErr, addFreetextMessage });
    }
  }

  /**
   * Go back to the previous page (edit service page)
   */
  const goBack = () => {
    const { serviceId, blog } = state;
    if (serviceId) {
      window.location.href = blog ? ("/admin/blog/editar/" + serviceId) : ("/admin/servico/editar/" + serviceId);
    }
  }

  const { text, text_en, text_order, textErr, textEnErr, textOrderErr, loading, addFreetextMessage, blog } = state;

  return ( 
    <div className="fbrito-page fbrito-admin-services fbrito-bg-grey">
      <Loader loading={loading} />
      <div className="fbrito-page-title">{`${blog ? 'Blog' :'Serviço'} - Adicionar Texto Livre`}</div>
      <form>
        <div className="row fbrito-block-padding">
          <div className="col-md p-2">
            <label className="form-label">Texto livre <span className="fbrito-input-error">*</span></label>
            <CKEditor
              editor={ClassicEditor}
              data={text || ''}
              onChange={handleChangeEditor1}
              config={{extraPlugins: [CustomUploadAdapterPlugin]}} />
            {textErr ? <span className="fbrito-input-error">{textErr}</span> : null}
          </div>
        </div>

        <br />
        <div className="row fbrito-block-padding">
          <div className="col-md p-2">
            <label className="form-label">Texto livre - Inglês <span className="fbrito-input-error">*</span></label>
            <CKEditor
              editor={ClassicEditor}
              data={text_en || ''}
              onChange={handleChangeEditor2}
              config={{extraPlugins: [CustomUploadAdapterPlugin]}} />
            {textEnErr ? <span className="fbrito-input-error">{textEnErr}</span> : null}
          </div>
        </div>

        <br />
        <div className="row fbrito-block-padding">
          <div className="col-md p-2">
            <label htmlFor="text-order" className="form-label">Marcar ordem <span className="fbrito-input-error">*</span></label>
            <input type="number" placeholder="Ordem (inteiro)" min="1" name="text-order" id="text-order" className={getInputClass(textOrderErr)} value={text_order || '1'} onChange={(event) => handleChange(event, "text_order")} />
            {textOrderErr ? <span className="fbrito-input-error">{textOrderErr}</span> : null}
          </div>
          <div className="col-md p-2"></div>
        </div>

        <Space number={3} />
        <div className="row fbrito-block-padding">
				<div className="col-sm p-2"><div onClick={goBack} className="fbrito-btn">Voltar</div></div>
        <div className="col-sm p-2">
            <div className="fbrito-btn" onClick={addFreetext}>Adicionar</div>
            {addFreetextMessage ? (<div>{addFreetextMessage}</div>): null}
        </div>
        <div className="col-sm p-2"></div>
        </div>
    
      </form>
      <Space number={3} />
    </div>
  );
}

export default AdminFreetextEdit;
